import React, {Component} from 'react';
import {toaster as toast} from './toast';
import {Position, Popover, Button, RadioGroup, Radio} from '@blueprintjs/core';
import {DateInput} from '@blueprintjs/datetime';
import moment from 'moment';
import  Async  from 'react-select/async';
import { ssoInstance} from "gg-react-utilities";
import adulticon from '../images/adulticon.svg';
import teenicon from '../images/teenicon.svg';
import infantsicon from '../images/infantsicon.svg';
import Config from '../Config';

export default class TripSearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tripType: 0,
            onwardDate: new Date(),
            returnDate: new Date(),
            Infant: 0,
            Teen: 0,
            Adult: 1,
            passengerInputWrapper: false,
            classType: "0",
            fromText: null,
            toText: null,
            inverseSearchCls:'flight-swipearrow',
            fromLoader:false,
            toLoader:false
        }
    }


    componentDidMount() {

        if (this.props.searchDetails && this.props.searchDetails !== null) {
            let returnDate = new Date(this.props.searchDetails.returnDate);
            if (this.props.searchDetails.returnDate === null) {
                returnDate = new Date(this.props.searchDetails.beginDate);
            }

            this.setState({
                fromText: {
                    id: this.props.searchDetails.from,
                    name: this.props.searchDetails.fromCity,
                    country_code: this.props.searchDetails.fromCountry,
                    value:this.props.searchDetails.from,
                    label:this.props.searchDetails.fromCity
                },
                toText: {
                    id: this.props.searchDetails.to,
                    name: this.props.searchDetails.toCity,
                    value: this.props.searchDetails.to,
                    label: this.props.searchDetails.toCity,
                    country_code: this.props.searchDetails.toCountry
                },
                onwardDate: new Date(this.props.searchDetails.beginDate),
                returnDate: returnDate,
                Adult: parseInt(this.props.searchDetails.adults),
                Teen: parseInt(this.props.searchDetails.childs),
                Infant: parseInt(this.props.searchDetails.infants),
                classType: this.props.searchDetails.cabin,
                tripType: parseInt(this.props.searchDetails.tripType),
            })
        }

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.searchDetails && nextProps.searchDetails !== null) {

            let returnDate = new Date(nextProps.searchDetails.returnDate);
            if (nextProps.searchDetails.returnDate == null) {
                returnDate = new Date(nextProps.searchDetails.beginDate);
            }

            this.setState({
                fromText: {
                    id: nextProps.searchDetails.from,
                    name: nextProps.searchDetails.fromCity,
                    country_code: nextProps.searchDetails.fromCountry,
                    value:nextProps.searchDetails.from,
                    label:nextProps.searchDetails.fromCity
                },
                toText: {
                    id: nextProps.searchDetails.to,
                    name: nextProps.searchDetails.toCity,
                    country_code: nextProps.searchDetails.toCountry,
                    alue: nextProps.searchDetails.to,
                    label: nextProps.searchDetails.toCity,
                },
                onwardDate: new Date(nextProps.searchDetails.beginDate),
                returnDate: returnDate,
                Adult: parseInt(nextProps.searchDetails.adults),
                Teen: parseInt(nextProps.searchDetails.childs),
                Infant: parseInt(nextProps.searchDetails.infants),
                classType: nextProps.searchDetails.cabin,
                tripType: parseInt(nextProps.searchDetails.tripType),
            })
        }
    }

    /* START HERE - FUNCTION FOR INCREASE PASSENGER(ADULT , TEEN , INFANT) COUNT */
    increase = (name, value) => {

        let totalPassengers = this.state.Adult + this.state.Teen + 1;

        if (name === 'Infant') {

            let adultCount = this.state.Adult;

            if (value + 1 <= adultCount) {

                value++;
                this.setState({
                    [name]: value
                })

            }


        } else {

            if ((totalPassengers < 10)) {


                value++;
                this.setState({
                    [name]: value
                })

            }

        }


    };
    /* END HERE - FUNCTION FOR INCREASE PASSENGER(ADULT , TEEN , INFANT) COUNT */

    /* START HERE - FUNCTION FOR DECREASE PASSENGER(ADULT , TEEN , INFANT) COUNT */
    decrease = (name, value) => {

        if (name === 'Adult' && value <= 1) {
            value = 1;
        } else {
            value = value - 1;
            if (value <= 0) {
                value = 0;
            // } else {
            //     value = value;
            }
        }

        this.setState({
            [name]: value
        }, () => {
            let infrantsCount = this.state.Infant;
            if (infrantsCount > this.state.Adult) {
                this.setState({
                    Infant: value
                })
            }
        })
    }
    /* END HERE - FUNCTION FOR DECREASE PASSENGER(ADULT , TEEN , INFANT) COUNT */

    onChangeTripe = (value) => {
        this.setState({
            classType: this.state.classType,
            tripType: value
        })
    }

    handleChange = (keys, newDate) => {
        this.setState({
            [keys]: newDate
        })
    }

    async getOptions(inputValue,type) {

        if (!inputValue) {
            return [];
        }

        if(type=='to'){
            this.setState({toLoader:true});
        }else{
            this.setState({fromLoader:true});
        }

        return ssoInstance.post(Config.FLIGHT_API_URL + '/getAirport', {"searchAirportData": inputValue})
                .then(
                    (res)=> {
                        const response = res.data;
                        if (response.status === true) {
                          response.data.map((resp) => {
                                resp.value = resp.city_code;
                                resp.label = resp.city_name + ',' + resp.country_name + ' (' + resp.city_code + ')';
                                resp.id = resp.city_code;
                                resp.name = resp.city_name + ',' + resp.country_name + ' (' + resp.city_code + ')';
                                return resp;
                          })
                        }
                        if(type=='to'){
                            this.setState({toLoader:false});
                        }else{
                            this.setState({fromLoader:false});
                        }
                        return response.data;
                      }

                ).catch((errors) => {
                    if(type=='to'){
                        this.setState({toLoader:false});
                    }else{
                        this.setState({fromLoader:false});
                    }
                });

    }

    inverseSearch = () => {

        let fromText = this.state.fromText;
        let toText = this.state.toText;
        let inverseSearchCls = (this.state.inverseSearchCls === "flight-swipearrow-active" ? "flight-swipearrow" : "flight-swipearrow-active");

        this.setState({
            fromText: toText,
            toText: fromText,
            inverseSearchCls
        });
    }

    onSearch = () => {
        let searchFlightData = {};

        if (this.state.fromText !== null) {
            searchFlightData.from = this.state.fromText.id;
            searchFlightData.fromCity = this.state.fromText.name;
            searchFlightData.fromCountry = this.state.fromText.country_code;
        } else {
            toast.error("Please Select From !");
            return false;
        }

        if (this.state.toText !== null) {
            searchFlightData.to = this.state.toText.id;
            searchFlightData.toCity = this.state.toText.name;
            searchFlightData.toCountry = this.state.toText.country_code;
        } else {
            toast.error("Please Select To !");
            return false;
        }

        if (this.state.fromText.id === this.state.toText.id) {
            toast.error("From and To Place Can not be Same");

            return false;
        } else {

        }
        let tripType = this.state.tripType;
        if (this.state.tripType === 1) {

            searchFlightData.returnDate = moment(this.state.returnDate).format('MM/DD/YYYY');
        }

        searchFlightData.beginDate = moment(this.state.onwardDate).format('MM/DD/YYYY');

        searchFlightData.cabin = this.state.classType;
        searchFlightData.tripType = tripType;
        searchFlightData.adults = this.state.Adult;
        searchFlightData.childs = this.state.Teen;
        searchFlightData.infants = this.state.Infant;


        let recentSearch = [];
        if (localStorage.getItem('flightRecentSearch') === undefined || localStorage.getItem('flightRecentSearch') === null) {
            searchFlightData.id = 0;
            recentSearch.push(searchFlightData);
        } else {
            recentSearch = JSON.parse(localStorage.getItem('flightRecentSearch'));
            searchFlightData.id = recentSearch[recentSearch.length - 1].id + 1
            recentSearch.push(searchFlightData);
        }
        localStorage.setItem('flightRecentSearch', JSON.stringify(recentSearch));

        // this.props.filterSearchFlight(searchFlightData);
        var str = Object.keys(searchFlightData).map((key) => {
            return key + '=' + searchFlightData[key];
        }).join('&');

        const rand = 1 + Math.random() * (100 - 1);

        str += '&tripId=' + rand;

        this.props.history.push({
            pathname: '/search-flights',
            search: '?' + str
        });
        // window.location.href=Config.APP_URL+'/search-flights?' + str;
    }

    render() {

        let maxDate = new Date();
        let year = maxDate.getFullYear();
        maxDate.setFullYear(year + 1);

        return (
            <div className={this.props.searchType === true ? "search-place-trip" : "search-place"}>
                <div className={"row mb-3"}>
                    <div className="pl-2 ml-2">
                        <label className="bp3-control bp3-radio .modifier">
                            <input type="radio" name="docs-radio-regular" value={0}
                                   checked={this.state.tripType === 0 ? true : false} onChange={() => {
                                this.onChangeTripe(0)
                            }}/>
                            <span className="bp3-control-indicator"></span>One Way
                        </label>
                    </div>
                    <div className="pl-2 ml-2">
                        <label className="bp3-control bp3-radio .modifier">
                            <input type="radio" name="docs-radio-regular" value={1}
                                   checked={this.state.tripType === 1 ? true : false} onChange={() => {
                                this.onChangeTripe(1)
                            }}/>
                            <span className="bp3-control-indicator"></span>Round Trip
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="bp3-input-group form-group col-xl-2 col-lg-2 col-md-4 col-sm-4">
                      <Async

                                  className='strip-select'
                                  classNamePrefix="strip-select"
                                  autoFocus={true}
                                  multi={false}
                                  value={this.state.fromText}
                                  isLoading={this.state.fromLoader}
                                  onChange={(value)=>
                                    {
                                      this.setState({
                                        fromText:value
                                      })
                                    }
                                  }
                                  valueKey="CityID"
                                  labelKey="ItemName"
                                  Key="CountryID"
                                  loadOptions={(e)=>{ return this.getOptions(e,'from')}}
                                  placeholder={'Select Airport'}
                                  searchPromptText={"Please enter 3 or More characters"}
                                  backspaceRemoves={true}
                                  defaultOptions
                              />

                      <img onClick={this.inverseSearch} src={require('../images/swipe.png')} alt='swipe' className={this.state.inverseSearchCls} />
                    </div>
                    {/*<SwapHorizRounded onClick={this.inverseSearch}/>*/}
                    <div className="bp3-input-group col-xl-2 col-lg-2 col-md-4 col-sm-4 mt-s-5">
                      <Async

                                  className='strip-select'
                                  classNamePrefix="strip-select"

                                  multi={false}
                                  value={this.state.toText}
                                  isLoading={this.state.toLoader}
                                  onChange={(value)=>
                                    {
                                      this.setState({
                                        toText:value
                                      })
                                    }
                                  }
                                  valueKey="CityID"
                                  labelKey="ItemName"
                                  Key="CountryID"
                                  loadOptions={(e)=>{ return this.getOptions(e,'to')}}
                                  placeholder={'Select Airport'}
                                  searchPromptText={"Please enter 3 or More characters"}
                                  backspaceRemoves={true}
                                  defaultOptions
                              />

                    </div>

                    <div
                        className="bp3-input-group col-xl-2 col-lg-2 col-md-4 col-sm-4 mt-s-3 mt-sm-0 mt-xl-0 mt-lg-0 pr-sm-2 pr-md-2">
                        <div className="bp3-form-group">
                            <DateInput
                                required={true}
                                closeOnSelection={true}
                                name="onwardDate"
                								popoverProps={{
                									position: Position.BOTTOM
                								}}
                                minDate={new Date()}
                                maxDate={maxDate}
                                formatDate={date => date.toLocaleDateString()}
                                parseDate={str => new Date(str)}
                                onChange={(date) => {
                                    let returnDate = moment(new Date(this.state.returnDate)).format('YYYY-MM-DD');

                                    if (returnDate <= moment(date).format('YYYY-MM-DD')) {
                                        this.setState({
                                            // journeyDate: moment(date).format('YYYY-MM-DD'),
                                            returnDate: date

                                        });
                                    }
                                    this.setState({
                                        onwardDate: new Date(moment(date))
                                    })
                                }}
                                value={this.state.onwardDate}
                                placeholder={"YYYY-MM-DD"}
                                rightElement={<span className="bp3-icon bp3-icon-calendar inputaddon-icon"></span>}
                            />
                        </div>
                    </div>
                    <div
                        className={this.state.tripType === 1 ? "bp3-input-group col-xl-2 col-lg-2 col-md-4 mt-s-2 mt-xl-0 mt-lg-0 col-sm-4" : "bp3-input-group col-xl-2 col-lg-2 col-md-4 mt-s-2 mt-xl-0 mt-lg-0 col-sm-4 arrow-menu-disable"}>
                        <div className="bp3-form-group">
                            <DateInput
                                required={true}
                                name="returnDate"
                                disabled={this.state.tripType === 0 ? true : false}
                                closeOnSelection={true}
                                formatDate={date => date.toLocaleDateString()}
                                parseDate={str => new Date(str)}
                                onChange={(newDate) => this.handleChange("returnDate", newDate)}
                                minDate={this.state.onwardDate}
                                maxDate={maxDate}
                                value={this.state.returnDate}
                                placeholder={"YYYY-MM-DD"}
								popoverProps={{
									position: Position.BOTTOM
								}}
                                rightElement={<span className="bp3-icon bp3-icon-calendar inputaddon-icon"></span>}
                            />

                        </div>
                    </div>

                    <div
                        className="bp3-input-group col-xl-2 col-lg-2 col-md-4 mt-s-2 mt-sm-2 mt-xl-0 mt-lg-0 p-lg-0 p-xl-0 col-sm-4 mt-md-0">
                        <div className="bp3-form-group pt-xl-2 pt-lg-2 pt-md-2" style={{borderBottom: '1px solid #CBCBCB', paddingBottom: '10px', cursor: 'pointer'}}>
                            <Popover content={
                                <div className="passengerInputWrapper">
                                    <ul className="dropdown-menu passenger-dropdown-content dropdown-cart">
                                        <li>
                                            <div className="row ml-0 mr-0 mt-2">
                                                <div className="col-12 p-0 border-bottom py-2 d-flex align-items-center justify-content-between">
                                                    <div className="col-6 float-left d-flex align-items-center justify-content-between">
                          														<div className="pl-1 pr-0 w-25">
                          															<img src={adulticon} className="" alt='adult'/>
                          														</div>
                                                                                  <div className="pl-0 pr-0 w-75">
                          															<p className="mt-0 mb-0 bold bp3-text-medium">Adults</p>
                          															<span className="bp3-text-small">12+ years</span>
                          														</div>
                                                    </div>
                                                    <div className="col-6 float-right">
                                                        <div className="bp3-control-group">
                                                            <Button type={"button"}
                                                                    className="bp3-button bp3-decrease bp3-decrease-small"
                                                                    onClick={() => this.decrease('Adult', this.state.Adult)}>
                                                                <span className="bp3-icon bp3-icon-minus"></span>
                                                            </Button>
                                                            <div className="bp3-input-group bp3-input-counter-group">
                                                                <input type="text"
                                                                       className="bp3-input bp3-input-counter text-center"
                                                                       value={this.state.Adult} readOnly={true}/>
                                                            </div>
                                                            <Button type={"button"}
                                                                    className="bp3-button bp3-increase bp3-increase-small"
                                                                    onClick={() => this.increase('Adult', this.state.Adult)}>
                                                                <span className="bp3-icon bp3-icon-plus"></span>
                                                            </Button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="row ml-0 mr-0">
                                                <div className="col-12 p-0 border-bottom py-2 d-flex align-items-center justify-content-between">
                                                    <div className="col-6 float-left d-flex align-items-center justify-content-between">
                          														<div className="pl-1 pr-0 w-25">
                          															<img src={teenicon} className="" alt='teen'/>
                          														</div>
                          														<div className="pl-0 pr-0 w-75">
                          															<p className="mt-0 mb-0 bold bp3-text-medium">Teens</p>
                          															<span className="bp3-text-small">2-11 years</span>
                          														</div>
                                                    </div>
                                                    <div className="col-6 float-right">
                                                        <div className="bp3-control-group">
                                                            <Button type={"button"}
                                                                    className="bp3-button bp3-decrease bp3-decrease-small"
                                                                    onClick={() => this.decrease('Teen', this.state.Teen)}>
                                                                <span className="bp3-icon bp3-icon-minus"></span>
                                                            </Button>
                                                            <div className="bp3-input-group bp3-input-counter-group">
                                                                <input type="text"
                                                                       className="bp3-input bp3-input-counter text-center"
                                                                       readOnly={true}
                                                                       value={this.state.Teen}/>
                                                            </div>
                                                            <Button type={"button"}
                                                                    className="bp3-button bp3-increase bp3-increase-small"
                                                                    onClick={() => this.increase('Teen', this.state.Teen)}>
                                                                <span className="bp3-icon bp3-icon-plus"></span>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="row ml-0 mr-0">
                                                <div className="col-12 p-0 border-bottom py-2 d-flex align-items-center justify-content-between">
                                                     <div className="col-6 float-left d-flex align-items-center justify-content-between">
                          														<div className="pl-1 pr-0 w-25">
                          															<img src={infantsicon} className=""alt='infant' />
                          														</div>
                          														<div className="pl-0 pr-0 w-75">
                          															<p className="mt-0 mb-0 bp3-text-medium">
                          																<strong>Infants</strong></p>
                          															<span className="bp3-text-small">under 2 years</span>
                          														</div>
                                                    </div>
                                                    <div className="col-6 float-right">
                                                        <div className="bp3-control-group">
                                                            <Button type={"button"}
                                                                    className="bp3-button bp3-decrease bp3-decrease-small"
                                                                    onClick={() => this.decrease('Infant', this.state.Infant)}>
                                                                <span className="bp3-icon bp3-icon-minus"></span>
                                                            </Button>
                                                            <div className="bp3-input-group bp3-input-counter-group">
                                                                <input type="text"
                                                                       className="bp3-input bp3-input-counter text-center"
                                                                       readOnly={true}
                                                                       value={this.state.Infant}/>
                                                            </div>
                                                            <Button type={"button"}
                                                                    className="bp3-button bp3-increase bp3-increase-small"
                                                                    onClick={() => this.increase('Infant', this.state.Infant)}>
                                                                <span className="bp3-icon bp3-icon-plus"></span>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                    <ul className="dropdown-menu passenger-dropdown-footer dropdown-cart">
                                        <li>
                                            <div className="row ml-0 mr-0 mt-2">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12">
                              															<div className="pl-1">
                              																<RadioGroup
                              																	onChange={(event) => {
                              																		this.setState({
                              																			tripType: this.state.tripType,
                              																			classType: event.target.value
                              																		})
                              																	}}
                              																	inline={true}
                              																	selectedValue={this.state.classType}
                              																>
                              																	<Radio label="Economy"
                              																		   className={"col-6 bp3-text-small mb-0"} value={"0"}/>
                              																	<Radio label="Business"
                              																		   className={"col-6 bp3-text-small mb-0"} value={"2"}/>
                              																</RadioGroup>
                              															</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            } position={Position.BOTTOM}>
                                <span
                                    className="dropdown-toggle">{this.state.Adult + " Adults," + this.state.Teen + " Teen," + this.state.Infant + " Infant"}<span
                                    className="bp3-icon bp3-icon-caret-down font-12 ml-2"></span></span>
                            </Popover>
                        </div>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 text-right mt-s-2 mt-sm-2 mt-xl-0 mt-lg-0">
                        <button type="button" className="bp3-button bp3-intent-primary search-btn"
                                onClick={this.onSearch}>Search
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
