import React,{Component} from 'react';

export default class BaggageInfo extends Component{


    render(){

        let cabin='7 KG';
        return(
            <div className="card">
                <div className="card-body">
                    <div className="baggageInfo mt-2">
                        <h6 className="mb-3">{this.props.from} to {this.props.to}</h6>

                        {
                            this.props.dataSource.PaxFares.map((flight,i)=>{
                                return (
                                    <React.Fragment key={i}>
                                      <h3>{flight.PaxType===0?'Adult':(flight.PaxType===1?'Childs':'Infants')}</h3>
                                      <div className="row mt-2" key={i}>
                                          <div className="col-12 col-sm-8">
                                              <div className="row">
                        													<div className="col-sm-6 col-12 d-flex justify-content-between mb-2">
                        														<span className="bagaggeTitleModel">Check in</span>
                        														<span className="bagaggeWeight">{flight.BaggageWeight} {flight.BaggageUnit}</span>
                        													</div>
                        													<div className="col-sm-6 col-12 d-flex justify-content-between mb-2">
                        														<span className="bagaggeTitleModel">Cabin</span>
                        														<span className="bagaggeWeight">{cabin}</span>
                        													</div>
                        													<div className="col-sm-6 col-12 d-flex justify-content-between mb-2">
                        														<span className="bagaggeTitleModel">Check in</span>
                        														<span className="bagaggeWeight">{flight.BaggageWeight} {flight.BaggageUnit}</span>
                        													</div>
                        													<div className="col-sm-6 col-12 d-flex justify-content-between mb-2">
                        														<span className="bagaggeTitleModel">Cabin</span>
                        														<span className="bagaggeWeight">{cabin}</span>
                        													</div>
                                              </div>
                                          </div>

                                          <div className="col-12 col-sm-4 text-right">
                                              <div>{flight.Refundable===true?<span className="badge badge-success">Refundable</span>:<span class="badge badge-danger">Non  Refundable</span>}</div>

                                          </div>
                                      </div>
                                    </React.Fragment>
                                )
                            })
                        }


                    </div>
                </div>
            </div>
        )
    }
}
