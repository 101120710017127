import {connect} from 'react-redux';
import * as actions from '../../../actions';
import { getBookingDetails } from '../../../actions/action';

import BookingHistory from '../component/BookingHistory';

const mapStateToProps = state => {

    return{
        allBookings:state.booking.allBookings,
        cancelBookings:state.booking.cancelBookings,
        upcomingBookings:state.booking.upcomingBookings,
    }
}

const mapDispatchToProps = dispatch => {

    return {
      getBookingHistory:()=>{
          getBookingDetails(1)
              .then((res)=>{
                  const sources = res.data;
                  dispatch(actions.allBookingsDetails(sources.data))
              })
          getBookingDetails(2)
              .then((res)=>{
                  const sources = res.data;
                  dispatch(actions.upcomingDetails(sources.data))
              })
          getBookingDetails(3)
              .then((res)=>{
                  const sources = res.data;
                  dispatch(actions.cancelDetails(sources.data))
              })
      },
      allBookingLoadMore:(type,data)=>{

          let page=parseInt(data.current_page)+1;

          getBookingDetails(type,page)
              .then((res)=>{
                  const sources = res.data;

                  if (sources.status === true) {
                    let newData = sources.data.data;
                    let oldData = data.data;

                    newData.map((obj)=>{
                        oldData.push(obj)
                        return obj;
                    })
                    sources.data.data=oldData;
                    dispatch(actions.allBookingsDetails(sources.data))
                  }
              })
      },
      upcomingBookingsLoadMore:(type,data)=>{

          let page=parseInt(data.current_page)+1;

          getBookingDetails(type,page)
              .then((res)=>{
                  const sources = res.data;

                  if (sources.status === true) {
                    let newData = sources.data.data;
                    let oldData = data.data;

                    newData.map((obj)=>{
                        oldData.push(obj)
                        return obj;
                    })
                    sources.data.data=oldData;
                    dispatch(actions.upcomingDetails(sources.data))
                  }
              })
      },
      cancelBookingsLoadMore:(type,data)=>{

          let page=parseInt(data.current_page)+1;

          getBookingDetails(type,page)
              .then((res)=>{
                  const sources = res.data;

                  if (sources.status === true) {

                    let newData = sources.data.data;
                    let oldData = data.data;

                    newData.map((obj)=>{
                        oldData.push(obj)
                        return obj;
                    })
                    sources.data.data=oldData;
                    dispatch(actions.cancelDetails(sources.data))
                  }
              })
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingHistory);
