import React, {Component} from 'react';
import ActivityList from './activityList';
import TripSearchContainer from '../../../container/TripSearchContainer';
import BookingHistory from '../container/BookingHistory';

export default class HomeComponent extends Component {

    constructor(props){
        super(props);
        this.state={
          tripType:0,
          selectedDate:new Date()
        }
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){
      // console.log(nextProps.suggetionData);

    }

    render() {

      return (

          <div className="top_bar_content">
              <div className="bg-color">
                    <div className="container">
                       <div className="display-container">
                           <div className="display-title">
                             <div className="row d-flex justify-content-end">
                               <a className="main-top-btn-first pt-2" href="#!" onClick={()=>{
                                   this.props.history.push('/booking-history');
                                 }}>Booking History</a>
                             </div>
                             <div className="">
                                 <h1 className=" d-inline-block main-content-title pt-2">Book your flight tickets</h1>
                                 <p className="main-content-title-description pt-2">
                                     Good things come to those who book flights.
                                 </p>
                             </div>
                           </div>
                           <div className="search-container">
                               <div className="search-section">
                                   <TripSearchContainer {...this.props} />
                               </div>
                           </div>
                       </div>
                    </div>
                </div>
                <div className="container">
                   <div className="row">
                       <div className="col-xl-12 col-12">
                           <div className="history">
                               <ActivityList {...this.props}/>
                                <div className="clearfix"></div>
                                <BookingHistory {...this.props}/>
                           </div>
                       </div>
                   </div>
               </div>
               <div className="clearfix"></div>
           </div>

      );
  }
}
