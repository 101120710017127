const config = {
    // .in URL
    FLIGHT_API_URL: process.env.REACT_APP_FLIGHT_API_URL,
    ACCOUNT_API_URL:process.env.REACT_APP_ACCOUNT_API_URL,
    DASHBOARD_URL:process.env.REACT_APP_DASHBOARD_URL,
    USER_PROFILE_URL:process.env.REACT_APP_USER_PROFILE_URL,
    APP_URL:process.env.REACT_APP_APP_URL,
    Extension:process.env.REACT_APP_Extension,
    IMAGE_URL:process.env.REACT_APP_IMAGE_URL,
    GG_CANCELLATION_CHARGE:process.env.REACT_APP_GG_CANCELLATION_CHARGE,
    GG_CCHARGE:process.env.REACT_APP_GG_CCHARGE
};

const packageConfigure={};

if (process.env.REACT_APP_ENV !== 'production'){
    packageConfigure.ACCOUNT_API_URL= process.env.REACT_APP_ACCOUNT_API_URL;
    packageConfigure.STORE_API_URL=process.env.REACT_APP_STORE_API_URL;
    packageConfigure.MEGABRAND_API_URL=process.env.REACT_APP_MEGABRAND_API_URL;
}

export {packageConfigure};



export default config;
