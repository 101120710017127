import React, {Component} from 'react';
import Config from '../../../Config';
import {
    ArrowRightAltRounded,
    ArrowBackRounded
} from '@material-ui/icons';
import {RadioGroup, Radio} from '@blueprintjs/core';
import FlightDetails from "./FlightDetails";

export default class FlightListComponent extends Component {

    /* START HERE -  CLASS CONSTRUCTOR TO GER PROPERTITY FROM PARENT CLASS AND SET STATE OF THIS CLASS */
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            onwardsList: [],
            returnsList: [],
        };
        setTimeout(() => {
            this.setState({
                dataSource: this.props.dataSource,
                onwards: this.props.onwards,
                returns: this.props.returns,
            });
        }, 100);

    }

    componentDidMount() {
    }

    componentWillReceiveProps(nexrProps) {
        if (parseInt(nexrProps.modelId, 10) !== parseInt(this.props.modelId, 10)) {

            this.contentLoaded = 0;
        }
    }

    flightOnClick = (segment, key) => {
        this.props.onClick(segment, key);
    }

    render() {
        if (!this.props.dataSource) {

            return (
                <div>
                    Loading....
                </div>

            );

        } else {


            if (this.props.dataSource.length > 0) {
                return this.props.dataSource.map((flight, key) => {

                    let flightImage = Config.IMAGE_URL + flight.AirlineName + '.png';

                    return <div className="flight-searchlist" key={key}>
                        <div
                            className={this.props.activeOnwardFlightIndex === key ? 'selectedFlightCard px-3 px-md-2' : 'px-3 px-md-2'}
                            onClick={() => this.flightOnClick(flight.segment, key)}>
                            <div className="row align-items-center mt-xl-3 mt-lg-3 mt-md-2">
                                <div className="col-xl-2 col-lg-2 col-md-2 text-left mb-2 pl-md-2 pr-md-0">
                                    <img alt="" src={flightImage} title={flight.AirlineName}
                                         className="flightCompanyLogo rounded-circle m-auto"/>

                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 mb-2 pl-md-3">
                                    <div className="flight-name">
                                        <p className="fName"> {flight.flighttName} </p>
                                        <p className="fnumber">({flight.FlightNumber})</p>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-5 text-left mb-2 px-md-0">
                                    <div className={'row float-left'}>
                                        <div className={'mr-2'}>
                                            <div className="">
                                                <p className="journeyTime m-0">{flight.DepartureTime}</p>
                                                <p className="journeyCity m-0">{flight.Origin}</p>
                                            </div>
                                        </div>
                                        <div className={'mr-2'}>
                                            <div className="">
                                                <ArrowRightAltRounded className="flight"/>
                                            </div>
                                        </div>
                                        <div className={'mr-2'}>
                                            <div>
                                                <p className="journeyTime m-0">{flight.ArrivalTime}</p>
                                                <p className="journeyCity m-0">{flight.Destination}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-2 text-right mb-2 pl-0 mb-md-0">
                                    <div className="flight-ticketchoice">
                                        <RadioGroup
                                            onChange={(event) => {

                                            }}
                                            selectedValue={this.props.activeOnwardFlightIndex}
                                        >
                                            <Radio label="" value={key}/>
                                        </RadioGroup>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-xl-2 col-lg-2 col-md-2"></div>
                                <div className="col-xl-4 col-lg-5 col-md-5 col-md-5 mb-2">
                                    <p className="travelTime mb-2 text-left">{flight.TotalDuration}
                                        <br/>{flight.stop} Stop(s) {flight.onWardsStations!==null?flight.onWardsStations:''} {flight.returnsStations!==null?flight.returnsStations:''}
                                    </p>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 text-left pl-xl-3 pl-0">
                                    <p className="price mb-1">{parseFloat(flight.TotalFare).toLocaleString('en-IN', {
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'INR'
                                    })}</p>
                                    {
                                        flight.refundable === true?
                                            <p className="refundableRules text-success">Refundable</p>
                                            :
                                            <p className="refundableRules text-danger">Non-Refundable</p>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <div className="row ">
                                        <div className="col-md-12 text-left">
                                            <FlightDetails
                                                searchDetails={this.props.searchDetails}
                                                from={flight.Origin}
                                                to={flight.Destination}
                                                dataSource={flight.segment.Fare}
                                                segment={flight.segment}
                                                flightImage={flightImage}
                                                FlightName={flight.FlightName}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                });

            } else {
                return (
                    <div className="text-center">

                        <div className="row">
                            <div className="col-md-12 mt-5">
                                <p>Oops! There is no data for this Flight still you can check other Search Flight.</p>
                            </div>

                            <div className="col-md-12 mt-5">
                                <ArrowBackRounded/>
                            </div>

                            <div className="col-md-12 mt-5">
                                <a href="/" className="btn btn-primary">Back</a>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}
