import React, {Component} from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import FlightInfo from "./FlightDetails/FlightInfo";
import FareDetails from "./FlightDetails/FareDetails";
import BaggageInfo from "./FlightDetails/BaggageInfo";
import CancellationRules from "./FlightDetails/CancellationRules";
import FlightDetailModal from './FlightDetailModal';

export default class FlightDetails extends Component{

    constructor(props){ 
        super(props);

        this.state={
            selectedTabId:1,
            isOpen: false
        }
    }

    handleOpen=()=>{
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    handleTabChange=(value)=>{
        this.setState({
            selectedTabId:value
        })
    }

    componentWillReceiveProps(nextprops){
        if(nextprops.isOpen!==undefined){
            this.setState({
                isOpen: nextprops.isOpen
            });
        }

    }

    render(){
        return(
            <React.Fragment>
                <div className={""}>
                    {
                        this.props.toggle?
                            this.props.toggle:
                            <React.Fragment>
							<div className={"flight-amenities"}>
								<div className={"row"}>
									<div className="col-xl-10 col-lg-10 col-md-11 offset-xl-1 col-sm-12 col-12 offset-lg-1">
										<div className={"row"}>
											<div className={"col-5 pl-md-2 pr-md-0"}>
												<FlightDetailModal
													toggle={false}
													title="Flight Details"
													className="bp3-dialog bp3-dialog-large modal-flightDetails"
													>
													<div className="col-12 border-top justify-content-center d-flex">
														<div className="col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12 py-3">
															<div className="row">
																<Tabs className="w-100" id="TabsExample" onChange={this.handleTabChange} defaultSelectedTabId={1} selectedTabId={this.state.selectedTabId}>
																	<Tab id={1} title="Flight Inforamtion" panel={<FlightInfo {...this.props} />} />
																	<Tab id={2} title="Fare details" panel={<FareDetails {...this.props} />} />
																	<Tab id={3} title="Baggage information" panel={<BaggageInfo {...this.props} />} />
																	<Tab id={4} title="Cancellation rules" panel={<CancellationRules {...this.props} />} />
																	<Tabs.Expander />
																</Tabs>
															</div>
														</div>
													</div>
												</FlightDetailModal>
											</div>
											<div className={"col-7 text-right mt-1 px-md-0"}>
												<p className="flight-meal">{this.props.segment.SSDetails}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
                            </React.Fragment>
                    }


                </div>


            </React.Fragment>
        );
    }

}
