import React, {Component} from 'react';
import Select from 'react-select';
import TopBar from "../../../components/topBar";
import {Auth, Loader,NoLogin} from 'gg-react-utilities';
import Config from '../../../Config';
import _ from 'lodash';
import moment from 'moment';
import {toaster as toast} from '../../../components/toast';
import CancelTicketConfirmModel from './CancelTicketConfirmModel';
import {Checkbox} from "@blueprintjs/core";
import {cancelBooking, cancelBookingRecheck} from "../../../actions/action";

class CancelationTrips extends Component {

    constructor(props){
        super(props);
        this.state={
            cancelationMode:[
                {id:'1',name:'Normal Cancellation',value:'1',label:'Normal Cancellation'},
                {id:'2',name:'Flight Cancel By Airlines', value:'2',label:'Flight Cancel By Airlines'},
                {id:'3',name:'Missing Flight by User', value:'3',label:'Missing Flight by User'},
                {id:'4',name:'Already Cancelled From Airline', value:'4',label:'Already Cancelled From Airline'},
            ],
            cancelationModeType:null,
            cancelProcess:false,
            selectedCancelationMode:null,
            selectedIds:[],
            confirmCancelModelStatus:false,
            booking_id:null,
            traceId:null,
            isLoggedIn:false
        }
    }

    async componentDidMount() {
        let search = this.props.history.location.search.substring(1);
        let data = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
            return key === "" ? value : decodeURIComponent(value)
        })

        this.setState({
            booking_id:data.booking_id,
            traceId:data.traceId,
            isLoggedIn:await Auth.isLoggedInAsync()
        },async ()=>{
            this.props.getBookedTicketDetailInfo(parseInt(this.state.booking_id),this.state.traceId)
                .then((response)=>{

                })
                .catch((error)=>{
                    this.setState({
                        errorMessage:error.message
                    })
                });
        })

    }

    componentWillReceiveProps(nexrProps){
        if(parseInt(nexrProps.modelId,10) !== parseInt(this.props.modelId,10)){

            if(nexrProps.cancellationTicketDetails!==null){

                if (nexrProps.cancellationTicketDetails.passengerDetails){
                    let selectedMode=[];
                    nexrProps.cancellationTicketDetails.passengerDetails.map((passanger)=>{
                        let d = passanger.possibleMode.split('-');
                        d.map((s)=>{
                            selectedMode.push(s);
                        })

                    })
                    let uiqueMode = _.uniq(selectedMode);

                    let filterCancelationMode = this.state.cancelationMode.filter((obj)=>{
                        return uiqueMode.indexOf(obj.id.toString()) > -1;
                    });

                    this.setState({
                        selectedIds:[],
                        cancelProcess:false,
                        confirmCancelModelStatus:false,
                        cancelationMode:filterCancelationMode
                    })
                }
            }
            this.contentLoaded =0;
        }
    }

    cancelTicket(){

        if(this.state.selectedCancelationMode===null){
            toast.error('Select Cancellation Mode!');
            return false;
        }


        if(this.state.selectedIds.length>0){

            let data = this.props.cancellationTicketDetails.passengerDetails;
            let filterData = _.filter(data, (obj,i)=>{
                return this.state.selectedIds.indexOf(i.toString()) > -1;
            });

            let cancelFlightDetail = [];
            filterData.map((obj)=>{

                cancelFlightDetail.push({
                    paxId:obj.paxId,
                    tripType:obj.tripType,
                    firstName:obj.firstName,
                    lastName:obj.lastName,
                    title:obj.title
                })
            })

            let cancelReq = {
                "transactionScreenId":this.props.cancellationTicketDetails.transactionScreenId,
                "isPartialCancel":this.state.cancelationModeType,
                "mode":this.state.selectedCancelationMode.id,
                "cancelFlightDetail":cancelFlightDetail
            };

            this.setState({
                cancelProcess:true,
                confirmCancelModelStatus:true,
            })
            this.cancelBooking(cancelReq)

        }else{
            toast.error('Please Select Passanger')
        }
    }

    onSelectTicket=(e)=>{
        if(e.target.checked===true){

            let selectedIds = this.state.selectedIds;

            selectedIds.push(e.target.value)

            if(selectedIds.length===this.props.cancellationTicketDetails.passengerDetails.length){
                this.setState({
                    confirmCancelModelStatus:false,
                    cancelationModeType:false,
                    lableText:'Cancel Ticket',
                    selectedIds:selectedIds
                })
            }else{
                this.setState({
                    confirmCancelModelStatus:false,
                    cancelationModeType:true,
                    lableText:'Cancel Ticket',
                    selectedIds:selectedIds
                })
            }

        }else{
            let index = this.state.selectedIds.indexOf(e.target.value)

            let selectedIds = this.state.selectedIds;
            selectedIds.splice(index, 1);
            if(selectedIds.length===this.props.cancellationTicketDetails.passengerDetails.length){
                this.setState({
                    confirmCancelModelStatus:false,
                    cancelationModeType:false,
                    lableText:'Cancel Ticket',
                    selectedIds:selectedIds
                })
            }else{
                this.setState({
                    confirmCancelModelStatus:false,
                    cancelationModeType:true,
                    lableText:'Cancel Ticket',
                    selectedIds:selectedIds
                })
            }
        }
    }

    cancelBooking=async (cancelReq)=>{

        let cancelRechaeckData  = this.props.cancellationTicketDetails;
        cancelRechaeckData.departureDate = moment(new Date(this.props.cancellationTicketDetails.bookingDate)).format('MM/DD/YYYY');
        cancelRechaeckData.cancelFlightDetail = cancelReq.cancelFlightDetail;

        if (await Auth.isLoggedInAsync()){
            cancelReq.userId=Auth.user().user_id;
        }
        cancelReq.traceId=this.state.traceId;
        cancelReq.orderId=this.state.booking_id;

       await cancelBookingRecheck(cancelRechaeckData)
            .then((response)=>{
                const source = response.data;
                if(source.status===true){
                    cancelBooking(cancelReq)
                    .then((res)=>{

                        const sources = res.data;
                        if(sources.status===true){
                            toast.success(sources.message);
                            this.props.getBookedTicketDetailInfo(cancelReq.orderId,cancelReq.traceId);
                        }else{
                            toast.error(sources.message)
                        }
                        this.setState({
                            cancelProcess:false,
                            confirmCancelModelStatus:false,
                        })
                    })
                    .catch((error)=>{
                        if(error.response !== undefined){
                            if (401 === error.response.status) {

                            } else {
                                toast.error(error.response.data.message)
                            }
                        }
                        this.setState({
                            cancelProcess:false,
                            confirmCancelModelStatus:true,
                        })
                    });
                }else{
                    this.setState({
                        cancelProcess:false,
                        confirmCancelModelStatus:true,
                    })
                    toast.error(source.message)
                }
            })
            .catch((error)=>{
                if(error.response !== undefined){

                    if (401 === error.response.status) {

                    } else {
                        toast.error(error.response.data.message)
                    }
                }
                this.setState({
                    cancelProcess:false,
                    confirmCancelModelStatus:true,
                })
            });

    }

    render() {

        if (!this.state.isLoggedIn){

            return(
                <React.Fragment>
                    <TopBar {...this.props} title={"Cancellation Trips"}/>
                    <div className="row cancelTripWrapper justify-content-center mb-5 mt-3">
                        <NoLogin/>
                    </div>
                </React.Fragment>
            )
        }

        if (this.props.cancellationTicketDetails === null){

            return(<Loader/>)
        }



        return(
            <React.Fragment>
                <TopBar {...this.props} title={"Cancellation Trips"}/>
                <div className="row cancelTripWrapper mb-5 mt-3">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-12 no-gutter p-0">

                        {
                            this.props.cancellationTicketDetails!==null && this.props.cancellationTicketDetails.passengerDetails?
                                this.props.cancellationTicketDetails.passengerDetails.map((passanger,i)=>{

                                    if(passanger.bookingAmt===null || passanger.cancellationCharge==='NA' || passanger.cancellationCharge==='NF' || passanger.cancellationCharge===null){
                                        passanger.refundAmount='NA';
                                    }else{

                                        passanger.refundAmount=passanger.bookingAmt-(passanger.cancellationCharge==='NA' || passanger.cancellationCharge==='NF'?0.00:parseFloat(passanger.cancellationCharge))-(passanger.emtFee?parseFloat(passanger.emtFee):0.00)-parseFloat(Config.GG_CCHARGE);
                                    }

                                    return <div className="card mb-3 mr-3 ml-3" key={i} title="1=Normal Cancellation, 2=Flight Cancel By Airlines, 3=Missing Flight by User, 4=Direct Cancelation by Airline">
                                        <div className="card-body">
                                            <div className="cancelTrip mb-2 mt-2">
                                                <div className="row">
                                                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 text-left pr-0 mr-3 mb-s-1">
                                                        {
                                                            passanger.status==="Confirmed"?
                                                                <div className="row custom-checkbox">
                                                                    <Checkbox onChange={(e)=>{
                                                                        this.onSelectTicket(e)
                                                                    }}
                                                                              id={"customControlInline"+i}
                                                                              value={i}
                                                                    />
                                                                </div>
                                                                :
                                                                ''
                                                        }

                                                    </div>
                                                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12">
                                                        <div className="row">
                                                            <div className="col-6 col-sm-6 col-md-4 mb-3">
                                                                <p className="ticketLabel mb-3">Passenger</p>
                                                                <h6 className="ticketValue">{passanger.title} {passanger.firstName} {passanger.lastName}</h6>
                                                            </div>
                                                            <div className="col-6 col-sm-6 col-md-4 mb-3">
                                                                <p className="ticketLabel mb-3">Journey</p>
                                                                <h6 className="ticketValue">{passanger.origin} to {passanger.destination}</h6>
                                                            </div>
                                                            <div className="col-6 col-sm-6 col-md-4 mb-3">
                                                                <p className="ticketLabel mb-3">Departure</p>
                                                                <h6 className="ticketValue">{moment(new Date(passanger.departureDate)).format('DD MMM YYYY,ddd')}</h6>
                                                            </div>
                                                            <div className="col-6 col-sm-6 col-md-4 mb-3">
                                                                <p className="ticketLabel mb-3">Date change charge</p>

                                                                {
                                                                    passanger.dateChangeCharge==='NA' || passanger.dateChangeCharge==='NF'?
                                                                        <h6 className="ticketValue text-danger">please call to Customer Care</h6>
                                                                        :(passanger.dateChangeCharge!==null?
                                                                            <h6 className="ticketValue">
                                                                                {parseFloat(passanger.dateChangeCharge).toLocaleString('en-IN', {
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'INR'
                                                                                })}</h6>
                                                                            :
                                                                            <h6 className="ticketValue text-danger">please call to Customer Care</h6>
                                                                        )
                                                                }
                                                            </div>
                                                            <div className="col-6 col-sm-6 col-md-4 mb-3">
                                                                <p className="ticketLabel mb-3">Refund amount</p>
                                                                {
                                                                    passanger.refundAmount==='NA' || passanger.refundAmount==='NF'?
                                                                        <h6 className="ticketValue text-danger">please call to Customer Care</h6>:
                                                                        (passanger.refundAmount!==null?
                                                                            <h6 className="ticketValue">
                                                                                {parseFloat(passanger.refundAmount)>0?
                                                                                    parseFloat(passanger.refundAmount).toLocaleString('en-IN', {
                                                                                                maximumFractionDigits: 2,
                                                                                                style: 'currency',
                                                                                                currency: 'INR'
                                                                                            })
                                                                                    :parseFloat(0).toLocaleString('en-IN', {
                                                                                                maximumFractionDigits: 2,
                                                                                                style: 'currency',
                                                                                                currency: 'INR'
                                                                                            })
                                                                                }</h6>
                                                                            :
                                                                            <h6 className="ticketValue text-danger">please call to Customer Care</h6>)
                                                                }

                                                            </div>

                                                            <div className="col-6 col-sm-6 col-md-4 mb-3">
                                                                <p className="ticketLabel mb-3">Refund Status</p>
                                                                {
                                                                    passanger.isRefundable===true?
                                                                        <h6 className="ticketValue text-success">Refundable</h6>
                                                                        :
                                                                        <h6 className="ticketValue text-danger">Non Refundable</h6>
                                                                }
                                                            </div>

                                                            <div className="col-6 col-sm-6 col-md-4 mb-3">
                                                                <p className="ticketLabel mb-3">GG Cancellation Fees</p>
                                                                <h6 className="ticketValue">{
                                                                    passanger.emtFee?
                                                                        parseFloat(parseFloat(passanger.emtFee)+parseFloat(Config.GG_CCHARGE)).toLocaleString('en-IN', {
                                                                            maximumFractionDigits: 2,
                                                                            style: 'currency',
                                                                            currency: 'INR'
                                                                        }):'-'
                                                                }</h6>
                                                            </div>

                                                            <div className="col-6 col-sm-6 col-md-4" >
                                                                <p className="ticketLabel mb-3">Passanger Number</p>
                                                                <h6 className="ticketValue">{passanger.paxId }</h6>
                                                            </div>

                                                            <div className="col-6 col-sm-6 col-md-4">
                                                                <p className="ticketLabel mb-3">Cancellation Charge</p>
                                                                {
                                                                    passanger.cancellationCharge==='NA' || passanger.cancellationCharge==='NF'?
                                                                        <h6 className="ticketValue text-danger">please call to Customer Care</h6>:
                                                                        (passanger.cancellationCharge!==null?
                                                                            <h6 className="ticketValue">  {parseFloat(passanger.cancellationCharge).toLocaleString('en-IN', {
                                                                                maximumFractionDigits: 2,
                                                                                style: 'currency',
                                                                                currency: 'INR'
                                                                            })}</h6>:<h6 className="ticketValue text-danger">please call to Customer Care</h6>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-2 col-lg-1 col-md-1 text-left pr-0 pt-1"></div>
                                                    <div className="col-12">
                                                        {
                                                            passanger.status==="Confirmed"?
                                                                <p className="text-success mt-2 mb-0 d-block text-center-xs">{passanger.status}</p>
                                                                :
                                                                <p className="text-danger mt-2 mb-0 d-block text-center-xs">{passanger.status}</p>
                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                                :
                                ''
                        }
                    </div>

                    {
                        this.state.selectedIds.length>0?
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12">
                                <div className="modeSelectionWrapper pt-3">
									<div className="col-12">
										<div className="row mb-4 mt-3">
											<div className="col-12 pl-0">
												<h3 className="ticketLabel">Cancel Ticket</h3>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-12 p-0">
												<Select
													multi={false}
													className={"modeSelectDropdown mb-2"}
													clearable={false}
													searchable={false}
													value={this.state.selectedCancelationMode}
													onChange={(value)=>{
														this.setState({
															selectedCancelationMode:value
														})
													}}
													valueKey="id"
													labelKey="name"
													options={this.state.cancelationMode}
													placeholder={'Select CancelationMode'}
													backspaceRemoves={true}
												/>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col pl-0">
												<small className="form-text text-muted">Partial cancellation is not allowed for International Round-trip fares.</small>
											</div>
										</div>
										<div className="row mb-2 mt-4">
											<div className="col-12 pl-0">
												<CancelTicketConfirmModel
													cancelProcess={this.state.cancelProcess}
													confirmCancelModelStatus={this.state.confirmCancelModelStatus}
													onCancelTicket={(e)=>{this.cancelTicket()}}
												>
													<p align="center">Are you sure you want Cancel this Flight?</p>
												</CancelTicketConfirmModel>
											</div>
										</div>
									</div>
                                </div>
                            </div>
                            :
                            ''
                    }

                </div>
            </React.Fragment>
        )

    }
}

export default CancelationTrips;
