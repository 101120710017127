import { Toaster, Position, Intent } from '@blueprintjs/core';

export const toaster = {
    success:(message,toasterState={})=>{

        const AppToaster = Toaster.create({
            className: "recipe-toaster",

        });

        let positionState={
            position:Position.LEFT_TOP,
            intent:Intent.SUCCESS,
            message:message
        };

        AppToaster.show({...positionState,toasterState})
    },
    error:(message,toasterState={})=>{
        const AppToaster = Toaster.create({
            className: "recipe-toaster",

        });
        let positionState={
            position:Position.LEFT_TOP,
            intent:Intent.DANGER,
            message:message
        };

        AppToaster.show({...positionState,toasterState})
    },
    warning:(message,toasterState={})=>{
        const AppToaster = Toaster.create({
            className: "recipe-toaster",

        });

        let positionState={
            position:Position.LEFT_TOP,
            intent:Intent.WARNING,
            message:message
        };

        AppToaster.show({...positionState,toasterState})
    }
}