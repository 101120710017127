import React, {Component} from 'react';
import {Button} from "@blueprintjs/core";
import Config from "../../../Config";

class CancellationBooking extends Component {

    handleScroll = () => {

        var cancelBooking = document.getElementById('cancelBooking');

        var cancelBookingHeight = (cancelBooking.scrollHeight - cancelBooking.offsetHeight) - cancelBooking.scrollTop;

        if (parseInt(cancelBookingHeight) === 0) {
            this.loadMore()
        }
    }

    loadMore = () => {
        this.props.onLoadMore()
    }

    onView = (flights) => {
        let ticketFlightData = {};
        ticketFlightData.booking_id = flights.bookingId;
        ticketFlightData.traceId = flights.traceId;
        var str = Object.keys(ticketFlightData).map((key) => {
            return key + '=' + ticketFlightData[key];
        }).join('&');

        this.props.history.push({
            pathname: '/ticket-details',
            search: '?' + str
        });
    }

    onCancel = (flights) => {
        let ticketFlightData = {};
        ticketFlightData.booking_id = flights.bookingId;
        ticketFlightData.traceId = flights.traceId;
        var str = Object.keys(ticketFlightData).map((key) => {
            return key + '=' + ticketFlightData[key];
        }).join('&');

        this.props.history.push({
            pathname: '/cancel-ticket-details',
            search: '?' + str
        });
    }

    render() {

        if ((this.props.bookingHistory === null || this.props.bookingHistory === undefined) || this.props.bookingHistory.data.length === 0) {
            return (
                <div className="no-record">No booking found</div>
            );
        }

        return (
            <React.Fragment>
                <div className={"booking-container"} id={"cancelBooking"} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderless">
                        <tbody>
                        {
                            this.props.bookingHistory.data.map((flights, i) => {

                                return (
                                    <React.Fragment key={i}>
                                        {
                                            flights.segment.map((flight, key) => {

                                                let AirlineName = flight.originFlightLogo;
                                                let FlightName = flight.originFlightName;
                                                let FlightNumber = flight.originFlightNumber;
                                                let filghtImage = 'https://s3.ap-south-1.amazonaws.com/gg-flight/logo/' + AirlineName + '.png';

                                                return (
                                                    <React.Fragment key={i}>
                                                        <tr>
                                                            <td>
                                                                <div className="mr-1">
                                                                    #{flights.bookingId}

                                                                </div>
                                                            </td>
                                                            <td className="hidden-xs">
                                                                <div className="border-rounded">
                                                                    <img
                                                                        src={filghtImage}
                                                                        alt="" className="rounded-circle" />
                                                                </div>
                                                            </td>
                                                            <td className="hidden-xs">
                                                                <div className="mr-1">
                                                                    {FlightName}<br/>
                                                                    <span className="oh-sub"> {FlightNumber}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div
                                                                    className="mr-1"> {flight.origin} to {flight.destination}
                                                                    <span
                                                                        className="oh-sub"> ( {flights.trip_type === '0' ? 'Oneway' : 'Rounway'} trip)</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="mr-1"> {flights.created_at}</div>
                                                            </td>
                                                            <td>
                                                                <div
                                                                    className="mr-1 oh-bold">{parseFloat(flights.amount).toLocaleString('en-IN', {
                                                                    maximumFractionDigits: 2,
                                                                    style: 'currency',
                                                                    currency: 'INR'
                                                                })}</div>
                                                            </td>
                                                            <td>
                                                                <p className="flightConfirmedText mt-2 mb-0"
                                                                   style={{color: flights.order_status_color}}>{flights.order_status_text}</p>
                                                                <div className="mr-1 oh-status"></div>
                                                            </td>
                                                            <td>
                                                                <div className="row d-flex align-items-center flex-nowrap">
                                                                    <a className="btn-view mr-2" title="view ticket"
                                                                       onClick={() => {
                                                                           this.onView(flights)
                                                                       }}><span className="bp3-icon bp3-icon-eye-open"></span> </a>
                                                                    {
                                                                        flights.order_status === '7' || flights.order_status === '10' || flights.order_status === '11' || flights.order_status === '15' || flights.order_status === '18' ?
                                                                            <a className="btn-danger mr-2"
                                                                               title="Cancel Ticket" onClick={() => {
                                                                                this.onCancel(flights)
                                                                            }}><span className="bp3-icon bp3-icon-cross"></span> </a>
                                                                            :
                                                                            ''
                                                                    }
                                                                    {
                                                                        parseInt(flights.order_status) >= 7 && parseInt(flights.order_status) <= 20 ?
                                                                            <a className="btn-view mr-2"
                                                                               title="Download"
                                                                               href={Config.FLIGHT_API_URL + "/downloadticket/" + flights.bookingId}><span className="bp3-icon bp3-icon-import" style={{fontSize: '18px'}}></span> </a>
                                                                            : ''
                                                                    }


                                                                </div>

                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                {
                    this.props.bookingHistory.total !== this.props.bookingHistory.data.length ?
                        <Button onClick={this.loadMore} className={"bp3-intent-primary loadmore"}>Load More</Button>
                        :
                        ''
                }
            </React.Fragment>
        );
    }
}

export default CancellationBooking;
