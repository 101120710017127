let initialState = {
    allBookings: null,
    cancelBookings: null,
    upcomingBookings: null,
    cancellationTicketDetails: null
};
const booking = (state = initialState, action) => {

    switch (action.type) {

        case 'ALL_BOOKINGS':
            return {...state, allBookings: action.allBookings}
        case 'CANCEL_BOOKINGS':
            return {...state, cancelBookings: action.cancelBookings}
        case 'UPCOMING_BOOKINGS':
            return {...state, upcomingBookings: action.upcomingBookings}
        case 'CANCELLATION_TICKET_DETAILS':
            return {...state, cancellationTicketDetails: action.cancellationTicketDetails}
        default:
            return state
    }
}

export default booking
