import React from 'react';
import {Auth} from "gg-react-utilities";
import moment from "moment";
import {Button} from "@blueprintjs/core";

export default class BookingHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            max_count: 5
        }
    }

    async componentDidMount() {
        if (await Auth.isLoggedInAsync() === true) {
            this.props.getBookingHistory();
        }
    }

    onView=(flights)=>{
        let ticketFlightData={};
        ticketFlightData.booking_id=flights.bookingId;
        ticketFlightData.traceId=flights.traceId;
        var str = Object.keys(ticketFlightData).map((key)=> {
            return key + '=' + ticketFlightData[key];
        }).join('&');

        this.props.history.push({
            pathname: '/ticket-details',
            search: '?' + str
        });
    }

    onCancel=(flights)=>{
        let ticketFlightData={};
        ticketFlightData.booking_id=flights.bookingId;
        ticketFlightData.traceId=flights.traceId;
        var str = Object.keys(ticketFlightData).map((key)=> {
            return key + '=' + ticketFlightData[key];
        }).join('&');

        this.props.history.push({
            pathname: '/cancel-ticket-details',
            search: '?' + str
        });
    }

    render() {

        if ((this.props.bookingHistory === null || this.props.bookingHistory === undefined) || this.props.bookingHistory.data.length===0){

            return (null)
        }

        let count = parseInt(0);
        return (
            <div className="container booking-history mt-4">
                <div className="col-xl-12 col-12">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <div className="row">
                                <h3 className="bp3-text-xlarge heading mb-5">Booking History</h3>
                            </div>
                        </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="row">
                                    <table className="bp3-html-table table-borderless">
                                        <tbody>
                                        {
                                            this.props.bookingHistory.data.map((flights, i) => {

                                                return (
                                                    <React.Fragment key={i}>
                                                        {
                                                            flights.segment.map((flight, key) => {
                                                                let AirlineName = flight.originFlightLogo;
                                                                let FlightName = flight.originFlightName;
                                                                let FlightNumber = flight.originFlightNumber;
                                                                let filghtImage = 'https://s3.ap-south-1.amazonaws.com/gg-flight/logo/' + AirlineName + '.png';
                                                                count++;
                                                                if (count <= 2){
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>
                                                                                <div className="mr-1">
                                                                                    #{flights.bookingId}

                                                                                </div>
                                                                            </td>
                                                                            <td className="hidden-xs hidden-sm hidden-md visible-lg visible-xl">
                                                                                <div className="border-rounded">
                                                                                    <img src={filghtImage}
                                                                                         alt=""/>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {FlightName}<br/><span
                                                                                className="oh-sup"> {FlightNumber}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span>{flight.origin} to {flight.destination}</span><br/>
                                                                                <span
                                                                                    className="oh-sub">({flights.trip_type === '0' ? 'Oneway' : 'Rounway'} trip)</span>
                                                                            </td>
                                                                            <td>
                                                                                <div className="date">
                                                                                    {moment(flights.created_at).format('DD MMM,Y')}
                                                                                </div>
                                                                                <span className="time">
                                                                                            {moment(flights.created_at).format('hh:mm:ss A ')}
                                                                                        </span>
                                                                            </td>
                                                                            <td>
                                                                                <div className="oh-bold">
                                                                                    {parseFloat(flights.amount).toLocaleString('en-IN', {
                                                                                        maximumFractionDigits: 2,
                                                                                        style: 'currency',
                                                                                        currency: 'INR'
                                                                                    })}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="oh-bold">
                                                                                    <p className="flightConfirmedText mt-2 mb-0 text-uppercase"
                                                                                       style={{color: flights.order_status_color}}>{flights.order_status_text}</p>
                                                                                </div>
                                                                                <div
                                                                                    className="oh-status"></div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="row bookingHistoryBtnWrapper">
                                                                                <div className="col-12">
                                                                                    <Button small={true}
                                                                                            intent="primary"
                                                                                            text="View"
                                                                                            className="btn-view mr-2"
                                                                                            onClick={()=>this.onView(flights)}
                                                                                    />
                                                                                    {
                                                                                        flights.order_status === '7' || flights.order_status === '10' || flights.order_status === '11' || flights.order_status === '15' || flights.order_status === '18' ?
                                                                                            <Button small={true}
                                                                                                    intent="danger"
                                                                                                    text="Cancel"
                                                                                                    className="btn-view mr-2"
                                                                                                    onClick={()=>this.onCancel(flights)}
                                                                                            />
                                                                                            :
                                                                                            ''
                                                                                    }
                                                                                </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                    </div>
                    <div className="clearfix"></div>

                    <div className="col-12 mt-4 text-center">
                        <a className="view-all" href={'/booking-history'} tabIndex={0}>View All</a>
                    </div>
                </div>
            </div>
        );
    }
}
