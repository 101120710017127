import React, {Component} from 'react';
import Config from '../../../Config';

import {
  ArrowRightAltRounded,
  ArrowBackRounded
} from '@material-ui/icons';
import {Radio, RadioGroup} from "@blueprintjs/core";
import _ from 'lodash';
import FlightDetails from "./FlightDetails";
import Seprator from '../../../images/Seprator.svg';

export default class InternationalFlightListview extends Component{

    /* START HERE -  CLASS CONSTRUCTOR TO GER PROPERTITY FROM PARENT CLASS AND SET STATE OF THIS CLASS */
    constructor(props) {
        super(props);
        this.state = {
            dataSource:[],
            onwardsList:[],
            returnsList:[],
        };
        setTimeout(()=>{
            this.setState({
                dataSource:this.props.dataSource,
                onwards:this.props.onwards,
                returns:this.props.returns,
            });
        },100);

    }

    componentDidMount() {
    }

    componentWillReceiveProps(nexrProps){

    }

    flightOnClick=(segment,key)=>{
        this.props.onClick(segment,key);
    }

    render(){
        if (!this.props.dataSource) {

            return (
                <div>
                    Loading....
                </div>

            );

        }else {


            if(this.props.dataSource.length>0){
                return this.props.dataSource.map((flights,key)=>{
                    let intent=[];
                    // console.log(flights)

                    return <div className="col-12" key={key}>
                  						<div className={"flight-searchlist"}  >
                  							<div className={this.props.activeOnwardFlightIndex===key?'selectedFlightCard':''} >
                  								{
                  									flights.segment.Bonds.map((flight,i)=> {

                  										let flightImage = Config.IMAGE_URL + flight.Legs[0].AirlineName + '.png';
                                      let onWardsStations = [];
                                      let returnsStations = [];
                                      if (flight.BoundType === "OutBound") {
                                        onWardsStations = _.map(flight.Legs, (legs, i) => {

                                            return legs.Origin;
                                        });
                                        onWardsStations.push(flight.Legs[flight.Legs.length - 1].Destination);
                                      }else {
                                        returnsStations = _.map(flight.Legs, (legs, i) => {

                                            return legs.Origin;
                                        });
                                        returnsStations.push(flight.Legs[flight.Legs.length - 1].Destination);
                                      }



                  										flight.BoundType === "OutBound" ?
                  											intent.push(
																<React.Fragment>
																	<div key={i} className={"p-xl-3 p-lg-3 p-md-3 py-s-2 py-sm-2 col-xl-11 col-lg-11 col-md-11 offset-xl-1 col-sm-12 col-12 offset-lg-1"} onClick={()=> this.flightOnClick(flights.segment,key) }>
																		<div className={'row'}>
																			<div className={"col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12"}>
																				<div className={'row pt-s-3 mt-s-4 mt-sm-4 pt-sm-3 mt-md-0 pt-md-0'}>
																					<div className={'col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5'}>
																						<div className={'row'}>
																							<div className={'col-4 pl-2 pr-0'}>
																								<div className="">
																									<img alt="" src={flightImage} title={flight.Legs[0].AirlineName} className="flightCompanyLogo rounded-circle m-auto pr-3" />
																								</div>
																							</div>
																							<div className={'col-8 pr-s-0 pr-sm-0 pl-sm-1'}>
																								<div className="flight-name">
																									<p className="fName"> {flight.Legs[0].FlightName} </p>
																									<p className="fnumber">({flight.Legs[0].FlightNumber})</p>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className={'col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4 px-s-1 px-sm-1'}>
																						<div className={'row text-center pr-s-1 pr-sm-0'}>
																							<div className={'mr-2'}>
																								<div className="">
																									<p className="journeyTime m-0">{flight.Legs[0].DepartureTime}<br/><span>{flight.Legs[0].Origin}</span></p>
																								</div>
																							</div>
																							<div className={'mr-2'}>
																								<div className="">
																									<ArrowRightAltRounded className="flight" />
																								</div>
																							</div>
																							<div className={'mr-2'}>
																								<div>
																									<p className="journeyTime m-0">{flight.Legs[0].ArrivalTime}</p>
																									<p className="journeyCity m-0">{flight.Legs[0].Destination}</p>
																								</div>
																							</div>
																						</div>
																					</div>

																					<div className={"col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 mt-xl-0 mt-lg-0 px-s-1 px-sm-1 px-md-2"}>
																						<div className="text-left">
																							<p className="travelTime mb-2">{ flights.TotalDuration} <br/><span>{flights.stop} Stop(s) {flights.onWardsStations!==null?flights.onWardsStations:''} </span></p>
																						</div>
																					</div>

																					<div className={this.props.flightLength>1?"col-xl-2 col-lg-2 col-md-3 col-sm-6 col-6 mt-s-2 mt-sm-2 mt-md-0 pr-sm-2 mt-xl-0 mt-lg-0 pl-lg-0 round-ticket-price":"col-xl-2 col-lg-2 col-md-3 col-sm-6 col-6 mt-s-2 mt-sm-2 mt-md-0 pr-sm-2 mt-xl-0 mt-lg-0 pl-lg-0 ticket-price"}>
																						<div className="text-center">
																							<p className="price m-0 d-inline">{parseFloat(flights.TotalFare).toLocaleString('en-IN', {
																								maximumFractionDigits: 2,
																								style: 'currency',
																								currency: 'INR'
																							})}</p>
																							<br/>
                                                                                            {
                                                                                                flights.refundable === true?
                                                                                                    <p className="refundableRules m-0 d-inline text-success"> Refundable</p>
                                                                                                    :
                                                                                                    <p className="refundableRules m-0 d-inline text-danger"> Non-Refundable</p>
                                                                                            }
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className={this.props.flightLength>1?"col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 round-flight-select text-center mt-xl-3 mt-lg-3 flight-selection":"col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 flight-select text-center mt-xl-3 mt-lg-3 flight-selection"}>
																				<div className="">
																					<RadioGroup
																						onChange={(event)=>{

																						}}
																						selectedValue={this.props.activeOnwardFlightIndex}
																					>
																						<Radio label="" value={key} />
																					</RadioGroup>
																				</div>
																			</div>
																		</div>
																	</div>
                                                                    {
                                                                        this.props.flightLength>1?
                                                                            <div className={"right-arrow hidden-xs hidden-sm visible-md visible-lg visible-xl"}>
                                                                                <img alt="" src={Seprator} title={flight.Legs[0].AirlineName} className="" />
                                                                            </div>
                                                                            :
                                                                            ""
                                                                    }

																</React.Fragment>
                  											)
                  											:
                  											intent.push(
                  												<div key={i} className={"p-3 py-s-2 py-sm-2 col-xl-11 col-lg-11 col-md-11 offset-xl-1 col-sm-12 col-12 offset-lg-1"} onClick={()=> this.flightOnClick(flights.segment,key) }>
                  													<div className={'row'}>
                  														<div className={"col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12"}>
                  															<div className={'row mt-md-0'}>
                  																<div className={'col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5'}>
                  																	<div className={'row'}>
                  																		<div className={'col-4 pl-2 pr-0'}>
                  																			<div className="">
                  																				<img alt="" src={flightImage} title={flight.Legs[0].AirlineName} className="flightCompanyLogo rounded-circle m-auto pr-3" />
                  																			</div>
                  																		</div>
                  																		<div className={'col-8 pr-s-0 pr-sm-0 pl-sm-1'}>
                  																			<div className="flight-name">
                  																				<p className="fName"> {flight.Legs[0].FlightName} </p>
                  																				<p className="fnumber">({flight.Legs[0].FlightNumber})</p>
                  																			</div>
                  																		</div>
                  																	</div>
                  																</div>
                  																<div className={'col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4 px-s-1 px-sm-1'}>
                  																	<div className={'row text-center pr-s-1 pr-sm-0'}>
                  																		<div className={'mr-2'}>
                  																			<div className="">
                  																				<p className="journeyTime m-0">{flight.Legs[0].DepartureTime}<br/><span>{flight.Legs[0].Origin}</span></p>
                  																			</div>
                  																		</div>
                  																		<div className={'mr-2'}>
                  																			<div className="">
                  																				<ArrowRightAltRounded className="flight" />
                  																			</div>
                  																		</div>
                  																		<div className={'mr-2'}>
                  																			<div>
                  																				<p className="journeyTime m-0">{flight.Legs[0].ArrivalTime}</p>
                  																				<p className="journeyCity m-0">{flight.Legs[0].Destination}</p>
                  																			</div>
                  																		</div>
                  																	</div>
                  																</div>

                  																<div className={"col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 mt-xl-0 mt-lg-0 px-s-1 px-sm-1 px-md-2"}>
                  																	<div className="text-left">
                  																		<p className="travelTime mb-2">{ flights.TotalDuration} <br/><span>{flights.stop} Stop(s) {flights.returnsStations!==null?flights.returnsStations:''}</span></p>
                  																	</div>
                  																</div>

                  																<div className={"col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6 mt-s-2 mt-sm-2 mt-md-0 pr-sm-2 mt-xl-0 mt-lg-0 pl-lg-0 ticket-price"}>
                  																	<div className=" text-left">
                  																	</div>
                  																</div>
                  															</div>
                  														</div>

                  														<div className={"col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 flight-select text-center mt-xl-3 mt-lg-3 flight-selection"}>
                  															<div className="">
                  															</div>
                  														</div>
                  													</div>
                  												</div>
                  											)
                  									})
                  								}
                  								{
                  									intent
                  								}
                  								<FlightDetails
                                    searchDetails={this.props.searchDetails}
                                    from={flights.Origin}
                                    to={flights.Destination}
                                    dataSource={flights.segment.Fare}
                                    segment={flights.segment}
                                    FlightName={flights.FlightName}
                  								/>

                  							</div>
                  						</div>
                  					</div>
                          });
            }else{
                return (
                    <div className="text-center">

                        <div className="row">
                            <div className="col-md-12 mt-5">
                                <p>Oops! There is no data for this Flight still you can check other Search Flight.</p>
                            </div>

                            <div className="col-md-12 mt-5">
                                <ArrowBackRounded />
                            </div>

                            <div className="col-md-12 mt-5">
                                <a href="/" className="btn btn-primary">Back</a>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}
