import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import '../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
// import "./style/gg-styles.css";

import reducers from './reducers';
import Home from './pages/Home';
import BookingHostory from './pages/BookingHostory';
import SearchFlights from './pages/SearchFlights';
import FlightFare from './pages/FlightFare';
import Tickets from './pages/Tickets';
import CancelBooking from './pages/CancelBooking';
import App from './App';
import {Notfound,Maintance,ScrollTop,GgUtils} from 'gg-react-utilities';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'

const customMiddleWare = store => next => action => {
    next(action)
}

// Auth.logout()
const createStoreWithMiddleware = applyMiddleware(customMiddleWare)(createStore);

if (process.env.REACT_APP_ENV === 'PRODUCTION'){

    GgUtils.sentryScripts(process.env.REACT_APP_SENTRY_URL);

}

if (process.env.REACT_APP_ENV === 'PRODUCTION'){
    GgUtils.googleAnalyticalScripts(process.env.REACT_APP_GOOGLE_AUTHENTICATE_CODE);
}

ReactDOM.render(
    <React.Fragment>

        <Provider store={createStoreWithMiddleware(reducers)}>
            <BrowserRouter>
              <App>
                  <div className="flight">
                      {
                        process.env.REACT_APP_IS_MAINTENANCE_MODE===true || process.env.REACT_APP_IS_MAINTENANCE_MODE==="true"?
                        <Switch>
                          <Route path="*" component={Maintance}/>
                        </Switch>
                        :
                        <Switch>
                          <Route exact path='/' component={Home}/>
                          <Route path='/booking-history' component={BookingHostory}/>
                          <Route path='/search-flights' component={SearchFlights}/>
                          <Route path='/flight-fare' component={FlightFare}/>
                          <Route path='/ticket-details' component={Tickets}/>
                          <Route path='/cancel-ticket-details' component={CancelBooking}/>
                          <Route path="*" component={Notfound}/>
                        </Switch>
                      }
                  </div>
                  <ScrollTop/>
                </App>
            </BrowserRouter>
        </Provider>
    </React.Fragment>,
    document.querySelector('#root'));
    unregisterServiceWorker();
