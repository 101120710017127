// import React, {Component} from 'react';
import {connect} from 'react-redux';

import HomeComponent from '../component/HomeComponent';

const mapStateToProps = state => {

    return{
        // homeData:state.Home.homeData,
        // suggetionData:state.Home.suggetionData
    }
}

const mapDispatchToProps = dispatch => {

    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
