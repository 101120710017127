import React,{ Component } from 'react';
import BookingHistoryContainer from './container/BookingHistory';

export default class BookingHistory extends Component {
  constructor(props) {
    super(props);
    this.state={

    }
  }

  render(){

    return(
      <div className="container mb-5 booking-history">
        <BookingHistoryContainer {...this.props} />
      </div>
    )
  }
}
