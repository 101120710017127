import {connect} from 'react-redux';
import * as actions from '../../../actions';
import { getBookingDetails } from '../../../actions/action';

import BookingHistory from '../component/BookingHistory';

const mapStateToProps = state => {

    return{
      bookingHistory:state.booking.allBookings
    }
}

const mapDispatchToProps = dispatch => {

    return {
      getBookingHistory:()=>{
        getBookingDetails(1)
        .then((response)=>{
          const sources = response.data;
          dispatch(actions.allBookingsDetails(sources.data))

        })
        .catch((error)=>{
          // console.log(error.response);
        });
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingHistory);
