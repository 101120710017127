
let initialState = {
    getAirport: []
};
const renderGetCity = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_SOURCES':
            // console.log(action.getAirport)
            return {...state, getAirport: action.getAirport}
        default:
            return state
    }
}

export default renderGetCity
