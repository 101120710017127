import React, {Component} from 'react';
import { Intent, Button} from '@blueprintjs/core';
import {
    KeyboardArrowLeftRounded,
    KeyboardArrowRightRounded,
    ArrowDownwardRounded
} from '@material-ui/icons';
import TripSearchContainer from '../../../container/TripSearchContainer';
import _ from 'lodash';
import FilterComponent from './FilterComponent';
import MobileFilterComponent from './MobileFilterComponent';
import Drawer from '@material-ui/core/Drawer';
import FlightListView from './FlightListComponent';
import RoundTripFlightListComponent from './RoundTripFlightListComponent';
import MobileRoundTripFlightListComponent from './MobileRoundTripFlightListComponent';
import InternationalFlightListview from './InternationalFlightListview';
import moment from 'moment';
import Config from '../../../Config';
import {Auth} from 'gg-react-utilities';
import TopBar from '../../../components/topBar';
import LoaderImg from '../../../images/100x100-Loader.gif';

export default class TripList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tripId: null,
            isFilterDrawer: false,
            prices: {
                min: 0,
                max: 100000,
            },
            airportList: [],
            onwardDate: new Date(),
            onWardsFlightsFilter: null,
            returnsFlightsFilter: [],
            searchDetails: null,
            activeOnwardFlightIndex: null,
            activeRoundFlightIndex: null,
            sortByFields: 'Fare',
            sortByFieldsRound: 'FareRound',
            Fare: 'ASC',
            FareRound: 'ASC',
            Departure: 'ASC',
            DepartureRound: 'ASC',
            filterDialog: false,
            isLoggedIn:null
        }
    }

     componentDidMount() {
        this.checkAuth();
        let searchObj = this.urlToDecodeParams(this.props.history.location.search)

        this.setState({
            tripId: searchObj.tripId,
            searchDetails: searchObj
        })

        let userId = 0;
        if (this.state.isLoggedIn === true) {
            let users = Auth.user();
            if (users) {
                userId = users.user_id
            }
        }

        this.props.filterSearchFlight(searchObj, userId);
    }

    async checkAuth(){
      setTimeout(()=>{
        Auth.isLoggedInAsync().then((res) => {
          this.setState({isLoggedIn:res})
        });
      },1)
    }

    componentWillReceiveProps(nextProps) {

        let searchObj = this.urlToDecodeParams(nextProps.history.location.search);
        if (searchObj.tripId !== this.state.tripId) {
            this.setState({
                tripId: searchObj.tripId,
                searchDetails: searchObj
            })
            let userId = 0;
            if (this.state.isLoggedIn === true) {
                let users = Auth.user();
                if (users) {
                    userId = users.user_id
                }
            }
            this.props.filterSearchFlight(searchObj, userId);
        }
        // Check International or Not
        let airpot_type = this.checkInternationalOrNot(nextProps.searchDetails.fromCountry,nextProps.searchDetails.toCountry);

        let trip_type = 1;
        if (nextProps.searchDetails.tripType === 1 || nextProps.searchDetails.tripType === "1") {
            trip_type = 2;
        }


        let searchFlightData = this.setSearchFlightData(nextProps.searchFlightData);

        let max = _.max(searchFlightData.prices, (o) => {
            return o;
        });
        let min = _.min(searchFlightData.prices, (o) => {
            return o;
        });
        // let air=_.chain(airlineList).map('key').uniq().value();
        let airname = _.uniqBy(searchFlightData.airlineList, function (e) {
            return e.value;
        });

        this.setState({
            price_max_value: parseInt(max),
            price_min_value: parseInt(min),
            prices: {
                min: parseInt(min),
                max: parseInt(max),
            },
            airportList: airname,
            onWardsFlights: searchFlightData.onWards,
            beginDate: nextProps.searchDetails.beginDate,
            returnDate: nextProps.searchDetails.returnDate,
            fromCity: nextProps.searchDetails.fromCity,
            toCity: nextProps.searchDetails.toCity,
            onWardsFlightData: searchFlightData.onWards,
            returnsFlights: searchFlightData.returns,
            returnsFlightData: searchFlightData.returns,
            airpot_type: airpot_type,
            // flightLength:nextProps.searchFlightData.Journeys.length - 1,
            flightLength: trip_type,
            modifySearch: false,
            onWardsFlightsFilter: searchFlightData.onWards,
            returnsFlightsFilter: searchFlightData.returns,
            selectedReturnsFlights: '',
            selectedOnWardsFlights: '',
            selectedItemOnwardIndex: '',
            selectedItemReturnIndex: '',
            searchDetails: nextProps.searchDetails,
            activeOnwardFlightIndex: null,
            activeRoundFlightIndex: null,
        }, () => {
            this.sortBy('onWard', 'ASC', 'Fare')
            this.sortBy('Round', 'ASC', 'FareRound')
        });

    }

    checkInternationalOrNot=(fromCountry,toCountry)=>{
        // Check International or Not
        let airpot_type = true;

        if (fromCountry === 'IN' && toCountry === 'IN') {
            airpot_type = false;
        }
        return airpot_type;
    }

    urlToDecodeParams=(search)=>{
        search = search.substring(1);
        let searchObj = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
            return key === "" ? value : decodeURIComponent(value)
        })

        return searchObj;
    }

    setSearchFlightData=(searchFlightData)=>{

        let prices = [];
        let airlineList = [];
        let onWards = [];
        let returns = [];

        if (searchFlightData != null) {
            if (searchFlightData.Journeys) {
                searchFlightData.Journeys.map((flight, key) => {
                    _.map(flight.Segments, (segment, i) => {
                        let refundable = [];
                        _.map(segment.Fare.PaxFares, (obj)=> {
                            refundable.push(obj.Refundable);
                            return obj;
                        });

                        segment.Bonds.map((bonds, i) => {

                            let onWardsStations = [];
                            let returnsStations = [];
                            const stop = bonds.Legs.length - 1;
                            const TotalFare = segment.Fare.TotalFareWithOutMarkUp;
                            let flighttName = bonds.Legs[0].FlightName;
                            let AirlineName = bonds.Legs[0].AirlineName;
                            let FlightNumber = bonds.Legs[0].FlightNumber;
                            let DepartureTime = bonds.Legs[0].DepartureTime;
                            let DepartureDate = bonds.Legs[0].DepartureDate;
                            // let ArrivalTime = segment.Bonds[0].Legs[0].ArrivalTime;
                            let TotalDuration = bonds.JourneyTime;
                            let Destination = bonds.Legs[bonds.Legs.length - 1].Destination;
                            let ArrivalTime = bonds.Legs[bonds.Legs.length - 1].ArrivalTime;
                            let ArrivalDate = bonds.Legs[bonds.Legs.length - 1].ArrivalDate;
                            let Origin = bonds.Legs[0].Origin;
                            let AvailableSeat = bonds.Legs[0].AvailableSeat;
//
                            prices.push(TotalFare);
                            airlineList.push({label: flighttName, value: AirlineName});


                            if (bonds.BoundType === "OutBound") {

                                onWardsStations = _.map(bonds.Legs, (legs, i) => {

                                    return legs.Origin;
                                });
                                onWardsStations.push(bonds.Legs[bonds.Legs.length - 1].Destination);
                                onWardsStations = onWardsStations.slice(1,-1);

                                onWards.push({
                                    FlightNumber: FlightNumber,
                                    DepartureTime: DepartureTime,
                                    DepartureDate: DepartureDate,
                                    ArrivalTime: ArrivalTime,
                                    ArrivalDate: ArrivalDate,
                                    stop: stop,
                                    TotalDuration: TotalDuration,
                                    TotalFare: TotalFare,
                                    AirlineName: AirlineName,
                                    flighttName: flighttName,
                                    AllBonds: bonds,
                                    Destination: Destination,
                                    Fare: segment.Fare,
                                    Origin: Origin,
                                    onWardsStations: onWardsStations.length>0?'via '+onWardsStations.join(','):null,
                                    returnsStations: returnsStations.length>0?'via '+returnsStations.join(','):null,
                                    AvailableSeat: AvailableSeat,
                                    refundable: refundable.indexOf(true)>-1?true:false,
                                    segment: segment,
                                });
                            } else {
                                returnsStations = _.map(bonds.Legs, (legs, i) => {

                                    return legs.Origin;
                                });
                                returnsStations.push(bonds.Legs[bonds.Legs.length - 1].Destination);
                                returnsStations = returnsStations.slice(1,-1);

                                returns.push({
                                    FlightNumber: FlightNumber,
                                    DepartureTime: DepartureTime,
                                    DepartureDate: DepartureDate,
                                    ArrivalTime: ArrivalTime,
                                    stop: stop,
                                    TotalDuration: TotalDuration,
                                    TotalFare: TotalFare,
                                    AirlineName: AirlineName,
                                    flighttName: flighttName,
                                    AllBonds: bonds,
                                    Destination: Destination,
                                    Fare: segment.Fare,
                                    Origin: Origin,
                                    onWardsStations: onWardsStations.length>0?'via '+onWardsStations.join(','):null,
                                    returnsStations: returnsStations.length>0?'via '+returnsStations.join(','):null,
                                    AvailableSeat: AvailableSeat,
                                    refundable: refundable.indexOf(true)>-1?true:false,
                                    segment: segment,
                                });
                            }
                        });
                    });
                });
            }
        }

        return {prices:prices,airlineList:airlineList,onWards:onWards,returns:returns};
    }

    finalTab = (segment, key) => {

        let flightSelectedClass = 'doubleFlightSelectedFilter';
        if (this.state.airpot_type === false) {
            this.setState({
                flightSelectedClass: flightSelectedClass,
                selectedReturnsFlights: segment,
                activeRoundFlightIndex: key
            });
        } else {
            let onWards = '';
            let returns = '';
            segment.Bonds.map((bonds, i) => {
                if (bonds.BoundType === "OutBound") {
                    onWards = segment;
                } else {
                    returns = segment;
                }
            });
            this.setState({
                flightSelectedClass: flightSelectedClass,
                selectedOnWardsFlights: onWards,
                selectedReturnsFlights: returns,
                activeOnwardFlightIndex: key
            });
        }

    }

    nextTab = (segment, key) => {

        let flightSelectedClass = this.state.flightSelectedClass;
        if (this.state.flightSelectedClass === null) {
            flightSelectedClass = 'singleFlightSelectedFilter';

        }
        if (this.state.airpot_type === false) {

            this.setState({
                flightSelectedClass: flightSelectedClass,
                selectedOnWardsFlights: segment,
                activeOnwardFlightIndex: key
            });
        } else {

            let onWards = '';
            let returns = '';
            segment.Bonds.map((bonds, i) => {
                if (bonds.BoundType === "OutBound") {
                    onWards = segment;
                } else {
                    returns = segment;
                }
            });
            if (returns) {
                flightSelectedClass = 'doubleFlightSelectedFilter';
            }

            this.setState({
                flightSelectedClass: flightSelectedClass,
                selectedOnWardsFlights: onWards,
                selectedReturnsFlights: returns,
                activeOnwardFlightIndex: key
            });

        }

    }


    nextPage = async () => {

        let flightDetails = {
            onWards: this.state.selectedOnWardsFlights
        };
        if (this.state.airpot_type === false){
            flightDetails.return=this.state.selectedReturnsFlights;
        }
        flightDetails.TraceId = this.props.searchFlightData.TraceId;

        let searchDetails = this.props.searchDetails;

        let nextPageRes = {
            flightDetails: flightDetails,
            airpot_type: this.state.airpot_type,
            searchDetails: searchDetails
        };
        this.props.history.push('/flight-fare', nextPageRes);
    }

    selectedFlightListing() {
        if (this.state.selectedOnWardsFlights) {
            let stop;
            let TotalDuration;
            let from;
            let to;
            let flighttName;
            let AirlineName;
            let FlightNumber;
            let DepartureTime;
            let ArrivalTime;
            let totalAmount = 0.00;

            totalAmount += parseFloat(this.state.selectedOnWardsFlights.Fare.TotalFareWithOutMarkUp);

            if (this.state.selectedReturnsFlights && this.state.airpot_type === false) {
                totalAmount += parseFloat(this.state.selectedReturnsFlights.Fare.TotalFareWithOutMarkUp);
            }

            return <React.Fragment>
                {
                    this.state.selectedOnWardsFlights.Bonds.map((flight, i) => {

                        stop = flight.Legs.length - 1;
                        from = flight.Legs[0].Origin;
                        to = 0;
                        flighttName = flight.Legs[0].FlightName;
                        AirlineName = flight.Legs[0].AirlineName;
                        FlightNumber = flight.Legs[0].FlightNumber;
                        DepartureTime = flight.Legs[0].DepartureTime;
                        ArrivalTime = flight.Legs[flight.Legs.length - 1].ArrivalTime;
                        TotalDuration = flight.JourneyTime;
                        to = flight.Legs[stop].Destination;

                        if (this.state.airpot_type === true) {
                            if (flight.BoundType === "OutBound") {

                                return (
                                    <div
                                        className={this.state.selectedReturnsFlights ? 'col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-2' : 'col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-2'}
                                        key={i}>
                                        <div className="row align-items-center selectedFlightTop">
                                            <div className="col text-left mb-2 mb-md-0 pr-md-0 pr-sm-2">
                                                <img alt="" src={Config.IMAGE_URL + AirlineName + '.png'}
                                                     title={flighttName} className="flightCompanyLogo rounded-circle" /><br/>
                                                <p className="flightbookname">{AirlineName}-{FlightNumber}</p>
                                            </div>
                                            <div className="col text-left mb-2 mb-md-2 pl-md-0 pr-md-0">
                                                <p className="journeyCity m-0 text-white">{from}</p>
                                                <p className="journeyTime m-0 text-white">{DepartureTime}</p>
                                            </div>
                                            <div className="col text-center mb-2 mb-md-0 p-md-0">
                                                <div className="flightIconWrapper d-inline">
                                                    <span className="bp3-icon bp3-icon-arrow-right"></span>
                                                    <p className="travelTime mb-1">{TotalDuration}, {stop} stop</p>
                                                </div>
                                            </div>
                                            <div className="col text-right mb-2 mb-md-2 pl-md-0">
                                                <p className="journeyCity m-0 text-white">{to}</p>
                                                <p className="journeyTime m-0 text-white">{ArrivalTime}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        } else {


                            return (
                                <div
                                    className={this.state.selectedReturnsFlights ? 'col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-2' : 'col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-2'}
                                    key={i}>
                                    <div className="row align-items-center selectedFlightTop">
                                        <div className="col text-left mb-2 mb-md-0 pr-md-0  pr-sm-0">
                                            <img alt="" src={Config.IMAGE_URL + AirlineName + '.png'}
                                                 title={flighttName} className="flightCompanyLogo rounded-circle" /><br/>
                                            <p className="flightbookname">{AirlineName}-{FlightNumber}</p>
                                        </div>
                                        <div className="col text-left mb-2 mb-md-2 pl-md-0 pr-md-0">
                                            <p className="journeyCity m-0 text-white">{from}</p>
                                            <p className="journeyTime m-0 text-white">{DepartureTime}</p>
                                        </div>
                                        <div className="col text-center mb-2 mb-md-0 p-md-0">
                                            <div className="flightIconWrapper d-inline">
                                                <span className="bp3-icon bp3-icon-arrow-right"></span>
                                                <p className="travelTime mb-1">{TotalDuration}, {stop} stop</p>
                                            </div>
                                        </div>
                                        <div className="col text-right mb-2 mb-md-2 pl-md-0">
                                            <p className="journeyCity m-0 text-white">{to}</p>
                                            <p className="journeyTime m-0 text-white">{ArrivalTime}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    })
                }

                {
                    this.state.selectedReturnsFlights ?
                        this.state.selectedReturnsFlights.Bonds.map((flight, i) => {

                            stop = flight.Legs.length - 1;
                            from = flight.Legs[0].Origin;
                            to = 0;
                            flighttName = flight.Legs[0].FlightName;
                            AirlineName = flight.Legs[0].AirlineName;
                            FlightNumber = flight.Legs[0].FlightNumber;
                            DepartureTime = flight.Legs[0].DepartureTime;
                            ArrivalTime = flight.Legs[flight.Legs.length - 1].ArrivalTime;
                            TotalDuration = flight.JourneyTime;
                            to = flight.Legs[stop].Destination;

                            if (this.state.airpot_type === true) {
                                if (flight.BoundType === "InBound") {
                                    return (
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-2" key={i}>
                                            <div className="row align-items-center border-left selectedFlightTop">
                                                <div className="col text-left mb-2 mb-md-0 pr-md-0 pr-sm-2">
                                                    <img alt="" src={Config.IMAGE_URL + AirlineName + '.png'}
                                                         className="flightCompanyLogo rounded-circle" /><br/>
                                                    <p className="flightbookname">{AirlineName}-{FlightNumber}</p>
                                                </div>
                                                <div className="col text-left mb-2 pr-md-0 pr-sm-2">
                                                    <p className="journeyCity m-0 text-white">{from}</p>
                                                    <p className="journeyTime m-0 text-white">{DepartureTime}</p>
                                                </div>
                                                <div className="col text-center p-md-0 pr-sm-2">
                                                    <div className="flightIconWrapper d-inline">
                                                        <span className="bp3-icon bp3-icon-arrow-right"></span>
                                                        <p className="travelTime mb-1 text-white">{TotalDuration}, {stop} stop</p>
                                                    </div>
                                                </div>
                                                <div className="col text-right mb-2 pl-md-0">
                                                    <p className="journeyCity m-0 text-white">{to}</p>
                                                    <p className="journeyTime m-0 text-white">{ArrivalTime}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-2" key={i}>
                                        <div className="row align-items-center border-left selectedFlightTop">
                                            <div className="col text-left mb-2 mb-md-0 pr-md-0 pr-sm-2">
                                                <img alt="" src={Config.IMAGE_URL + AirlineName + '.png'}
                                                     className="flightCompanyLogo rounded-circle" /><br/>
                                                <p className="flightbookname">{AirlineName}-{FlightNumber}</p>
                                            </div>
                                            <div className="col text-left mb-2 pr-md-0 pr-sm-2">
                                                <p className="journeyCity m-0 text-white">{from}</p>
                                                <p className="journeyTime m-0 text-white">{DepartureTime}</p>
                                            </div>
                                            <div className="col text-center p-md-0 pr-sm-2">
                                                <div className="flightIconWrapper d-inline">
                                                    <span className="bp3-icon bp3-icon-arrow-right"></span>
                                                    <p className="travelTime mb-1 text-white">{TotalDuration}, {stop} stop</p>
                                                </div>
                                            </div>
                                            <div className="col text-right mb-2 pl-md-0">
                                                <p className="journeyCity m-0 text-white">{to}</p>
                                                <p className="journeyTime m-0 text-white">{ArrivalTime}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        })
                        :
                        ''
                }

                {
                    this.state.selectedOnWardsFlights ?
                        <React.Fragment>


                            {this.state.flightLength > 1 ?
                                this.state.selectedOnWardsFlights && this.state.selectedReturnsFlights ?
                                    <React.Fragment>
                                        <div
                                            className="col-xl-4 col-lg-4 col-md-4 mt-md-2 mt-md-1 mb-md-2 px-md-2 px-lg-3 pt-s-3 border-top-xs border-left-lg">
                                            <div className="row align-items-center">
                                                <div className="col text-right mb-2 mb-md-0 mt-md-0 mt-md-1">
                                                    <p className="totalFareText m-0 mr-2">Total Fare: </p>
                                                    <p className="totalFare m-0 pb-2">{totalAmount.toLocaleString('en-IN', {
                                                        maximumFractionDigits: 2,
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}</p>
                                                </div>
                                                <div className="col text-right mb-2 mb-md-0 mt-md-0 mt-md-1">
                                                    <div className="selectedFlightButton">
                                                        <button className="bp3-button btn-flightbooking"
                                                                intent={Intent.PRIMARY} onClick={this.nextPage}>Book Now
                                                        </button>
                                                        <p className="flightcancelpolicy">Cancellation available</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>

                                    :
                                    ''

                                :
                                this.state.selectedOnWardsFlights ?
                                    <React.Fragment>
                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 mt-3">
                                            <div className="row">
                                                <div
                                                    className="col-xl-9 col-lg-9 col-md-8 col-sm-6 col-6 text-right mb-2 mb-md-0 mt-md-0 mt-md-1">
                                                    <div className="col text-center mb-2">
                                                        <p className="totalFareText m-0 mr-2">Total Fare </p>
                                                        <p className="totalFare m-0">{totalAmount.toLocaleString('en-IN', {
                                                            maximumFractionDigits: 2,
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}</p>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 text-right mb-2 mb-md-0 mt-md-0 mt-md-0">
                                                    <div className="text-right selectedFlightButton">
                                                        <button className="bp3-button btn-flightbooking"
                                                                intent={Intent.PRIMARY} onClick={this.nextPage}>Book Now
                                                        </button>
                                                        <p className="flightcancelpolicy">Cancellation available</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    ''
                            }


                        </React.Fragment>
                        :
                        ''
                }

            </React.Fragment>

        }
    }

    selectedOnwardsFlightListing() {
        if (this.state.selectedOnWardsFlights) {
            let stop;
            let TotalDuration;
            let from;
            let to;
            let flighttName;
            let AirlineName;
            let FlightNumber;
            let DepartureTime;
            let ArrivalTime;
            let totalAmount = 0.00;

            totalAmount += parseFloat(this.state.selectedOnWardsFlights.Fare.TotalFareWithOutMarkUp);

            if (this.state.selectedReturnsFlights && this.state.airpot_type === false) {
                totalAmount += parseFloat(this.state.selectedReturnsFlights.Fare.TotalFareWithOutMarkUp);
            }

            return <React.Fragment>
                {
                    this.state.selectedOnWardsFlights.Bonds.map((flight, i) => {

                        stop = flight.Legs.length - 1;
                        from = flight.Legs[0].Origin;
                        to = 0;
                        flighttName = flight.Legs[0].FlightName;
                        AirlineName = flight.Legs[0].AirlineName;
                        FlightNumber = flight.Legs[0].FlightNumber;
                        DepartureTime = flight.Legs[0].DepartureTime;
                        ArrivalTime = flight.Legs[flight.Legs.length - 1].ArrivalTime;
                        TotalDuration = flight.JourneyTime;
                        to = flight.Legs[stop].Destination;

                        if (this.state.airpot_type === true) {
                            if (flight.BoundType === "OutBound") {

                                return (
                                    <div
                                        className={this.state.selectedReturnsFlights ? 'col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-2' : 'col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-2'}
                                        key={i}>
                                        <div className="row align-items-center selectedFlightTop-domestic">
                                            <div className="col text-left mb-2 mb-md-0 pr-md-0 pr-sm-2">
                                                <img alt="" src={Config.IMAGE_URL + AirlineName + '.png'}
                                                     title={flighttName} className="flightCompanyLogo rounded-circle" /><br/>
                                                <p className="flightbookname">{AirlineName}-{FlightNumber}</p>
                                            </div>
                                            <div className="col text-left mb-2 mb-md-2 pl-md-0 pr-md-0">
                                                <p className="journeyCity m-0 text-white">{from}</p>
                                                <p className="journeyTime m-0 text-white">{DepartureTime}</p>
                                            </div>
                                            <div className="col text-center mb-2 mb-md-0 p-md-0">
                                                <div className="flightIconWrapper d-inline">
                                                    <span className="bp3-icon bp3-icon-arrow-right"></span>
                                                    <p className="travelTime mb-1">{TotalDuration}, {stop} stop</p>
                                                </div>
                                            </div>
                                            <div className="col text-right mb-2 mb-md-2 pl-md-0">
                                                <p className="journeyCity m-0 text-white">{to}</p>
                                                <p className="journeyTime m-0 text-white">{ArrivalTime}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        } else {


                            return (
                                <div
                                    className={this.state.selectedReturnsFlights ? 'col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-2' : 'col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-2'}
                                    key={i}>
                                    <div className="row align-items-center selectedFlightTop-domestic">
                                        <div className="col text-left mb-2 mb-md-0 pr-md-0 pr-sm-2">
                                            <img alt="" src={Config.IMAGE_URL + AirlineName + '.png'}
                                                 title={flighttName} className="flightCompanyLogo rounded-circle" /><br/>
                                            <p className="flightbookname">{AirlineName}-{FlightNumber}</p>
                                        </div>
                                        <div className="col text-left mb-2 mb-md-2 pl-md-0 pr-md-0">
                                            <p className="journeyCity m-0 text-white">{from}</p>
                                            <p className="journeyTime m-0 text-white">{DepartureTime}</p>
                                        </div>
                                        <div className="col text-center mb-2 mb-md-0 p-md-0">
                                            <div className="flightIconWrapper d-inline">
                                                <span className="bp3-icon bp3-icon-arrow-right"></span>
                                                <p className="travelTime mb-1">{TotalDuration}, {stop} stop</p>
                                            </div>
                                        </div>
                                        <div className="col text-right mb-2 mb-md-2 pl-md-0">
                                            <p className="journeyCity m-0 text-white">{to}</p>
                                            <p className="journeyTime m-0 text-white">{ArrivalTime}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    })
                }

                {
                    this.state.selectedOnWardsFlights ?
                        <React.Fragment>
                            <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6 mt-3 mt-sm-0 mt-md-3">
                                <div className="row">
                                    <div
                                        className="col-xl-9 col-lg-9 col-md-8 col-sm-6 col-6 text-right mb-2 mb-md-0 mt-md-0 mt-md-1">
                                        <div className="col text-center mb-2">
                                            <p className="totalFareText m-0 mr-2">Total Fare </p>
                                            <p className="totalFare m-0">{totalAmount.toLocaleString('en-IN', {
                                                maximumFractionDigits: 2,
                                                style: 'currency',
                                                currency: 'INR'
                                            })}</p>
                                        </div>
                                    </div>
                                    <div
                                        className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 text-right mb-2 mb-md-0 mt-md-0 mt-md-0">
                                        <div className="text-right selectedFlightButton">
                                            <button className="bp3-button btn-flightbooking" intent={Intent.PRIMARY}
                                                    onClick={this.nextPage}>Book Now
                                            </button>
                                            <p className="flightcancelpolicy">Cancellation available</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        ''
                }

            </React.Fragment>

        }
    }

    sortBy = (type, filterType = null, sort_type = 'Fare') => {

        let sortByDepartureActive = false;
        let sortByFareActive = false;
        if (type === "onWard") {

            let onwards = this.state.onWardsFlightsFilter.sort((obj1, obj2) => {
                // Ascending: first age less than the previous i.Fare.TotalFareWithOutMarkUp
                let Departure1 = new Date(obj1.DepartureDate + ' ' + obj1.DepartureTime);
                let Departure2 = new Date(obj2.DepartureDate + ' ' + obj2.DepartureTime);

                let Arrival1 = new Date(obj1.ArrivalDate + ' ' + obj1.ArrivalTime);
                let Arrival2 = new Date(obj2.ArrivalDate + ' ' + obj2.ArrivalTime);

                let TotalDurationRemoveh1 = obj1.TotalDuration.replace('h ', '.');
                let TotalDuration1 = TotalDurationRemoveh1.replace('m', '');

                let TotalDurationRemoveh2 = obj2.TotalDuration.replace('h ', '.');
                let TotalDuration2 = TotalDurationRemoveh2.replace('m', '');

                if (filterType != null) {

                    if (filterType === 'ASC') {
                        if (sort_type === 'Fare') {
                            sortByFareActive = true;
                            return obj1.Fare.TotalFareWithOutMarkUp - obj2.Fare.TotalFareWithOutMarkUp;
                        } else if (sort_type === 'Departure') {
                            sortByDepartureActive = true;
                            return Departure1 - Departure2;
                        } else if (sort_type === 'Arrival') {
                            return Arrival1 - Arrival2;
                        } else if (sort_type === 'Durations') {
                            return TotalDuration1 - TotalDuration2;
                        } else {
                            return obj1.Fare.TotalFareWithOutMarkUp - obj2.Fare.TotalFareWithOutMarkUp;
                        }
                    } else {
                        if (sort_type === 'Fare') {
                            return obj2.Fare.TotalFareWithOutMarkUp - obj1.Fare.TotalFareWithOutMarkUp;
                        } else if (sort_type === 'Departure') {
                            return Departure2 - Departure1;
                        } else if (sort_type === 'Arrival') {
                            return Arrival2 - Arrival1;
                        } else if (sort_type === 'Durations') {
                            return TotalDuration2 - TotalDuration1;
                        } else {
                            return obj2.Fare.TotalFareWithOutMarkUp - obj1.Fare.TotalFareWithOutMarkUp;
                        }
                    }
                } else {
                    return obj2.Fare.TotalFareWithOutMarkUp - obj1.Fare.TotalFareWithOutMarkUp;
                }
            });
            this.setState({
                onWardsFlightsFilter: onwards,
                sortByFields: sort_type,
                [sort_type]: filterType === 'ASC' ? 'DESC' : 'ASC'
            })
        } else {
            let returns = this.state.returnsFlightsFilter.sort((obj1, obj2) => {
                // Ascending: first age less than the previous i.Fare.TotalFareWithOutMarkUp
                let Departure1 = new Date(obj1.DepartureDate + ' ' + obj1.DepartureTime);
                let Departure2 = new Date(obj2.DepartureDate + ' ' + obj2.DepartureTime);

                let Arrival1 = new Date(obj1.ArrivalDate + ' ' + obj1.ArrivalTime);
                let Arrival2 = new Date(obj2.ArrivalDate + ' ' + obj2.ArrivalTime);

                let TotalDurationRemoveh1 = obj1.TotalDuration.replace('h ', '.');
                let TotalDuration1 = TotalDurationRemoveh1.replace('m', '');

                let TotalDurationRemoveh2 = obj2.TotalDuration.replace('h ', '.');
                let TotalDuration2 = TotalDurationRemoveh2.replace('m', '');

                if (filterType != null) {

                    if (filterType === 'ASC') {
                        if (sort_type === 'Fare') {
                            sortByFareActive = true;
                            return obj1.Fare.TotalFareWithOutMarkUp - obj2.Fare.TotalFareWithOutMarkUp;
                        } else if (sort_type === 'Departure') {
                            sortByDepartureActive = true;
                            return Departure1 - Departure2;
                        } else if (sort_type === 'Arrival') {
                            return Arrival1 - Arrival2;
                        } else if (sort_type === 'Durations') {
                            return TotalDuration1 - TotalDuration2;
                        } else {
                            return obj1.Fare.TotalFareWithOutMarkUp - obj2.Fare.TotalFareWithOutMarkUp;
                        }
                    } else {
                        if (sort_type === 'Fare') {
                            sortByFareActive = true;
                            return obj2.Fare.TotalFareWithOutMarkUp - obj1.Fare.TotalFareWithOutMarkUp;
                        } else if (sort_type === 'Departure') {
                            sortByDepartureActive = true;
                            return Departure2 - Departure1;
                        } else if (sort_type === 'Arrival') {
                            return Arrival2 - Arrival1;
                        } else if (sort_type === 'Durations') {
                            return TotalDuration2 - TotalDuration1;
                        } else {
                            return obj2.Fare.TotalFareWithOutMarkUp - obj1.Fare.TotalFareWithOutMarkUp;
                        }
                    }
                } else {
                    return obj2.Fare.TotalFareWithOutMarkUp - obj1.Fare.TotalFareWithOutMarkUp;
                }
            });
            this.setState({
                returnsFlightsFilter: returns,
                sortByFieldsRound: sort_type,
                [sort_type]: filterType === 'ASC' ? 'DESC' : 'ASC'
            })
        }
    }


    applyFilter = (filter, type = 'web') => {

        let stops = [];
        let airlines = [];
        let prices = [];

        filter.stops.filter((res) => {
            stops.push(parseInt(res.value));
            return res;
        })
        filter.airlines.filter((res) => {
            airlines.push(res.value);
            return res;
        })

        let refund = true;
        if (filter.refundable === '0' || filter.refundable === 0) {
            refund = false;
        }

        prices = filter.prices;

        let filterData = this.state.onWardsFlightData;

        let onWardsFlightsFilter = _.filter(filterData, (i) => {


            let refundable = [];
            _.map(i.Fare.PaxFares, function (obj) {
                refundable.push(obj.Refundable);
            });

            if (filter.refundable !== '2' || filter.refundable !== 2) {

                if (filter.onWardDeptTime === "24" && filter.onWardDeptTime === "24") {
                    return stops.indexOf(i.stop) > -1 &&
                        (airlines.indexOf(i.AirlineName) > -1) &&
                        (prices.min <= i.Fare.TotalFareWithOutMarkUp && prices.max >= i.Fare.TotalFareWithOutMarkUp) && (refundable.indexOf(refund) > -1);
                } else {

                    return stops.indexOf(i.stop) > -1 &&
                        (airlines.indexOf(i.AirlineName) > -1) &&
                        (prices.min <= i.Fare.TotalFareWithOutMarkUp && prices.max >= i.Fare.TotalFareWithOutMarkUp) &&
                        (refundable.indexOf(refund) > -1) &&
                        (parseInt(filter.onWardDeptTime) <= parseInt(i.DepartureTime.split(':')[0]) && (parseInt(filter.onWardDeptTime) + 6) >= parseInt(i.DepartureTime.split(':')[0]));
                    //         // (parseInt(filter.returnDeptTime) < parseInt(i.ArrivalTime.split(':')[0]) && (parseInt(filter.returnDeptTime)+6) > parseInt(i.ArrivalTime.split(':')[0]));
                }
            } else {

                if (filter.onWardDeptTime === "24" && filter.onWardDeptTime === "24") {

                    return stops.indexOf(i.stop) > -1 &&
                        (airlines.indexOf(i.AirlineName) > -1) &&
                        (prices.min <= i.Fare.TotalFareWithOutMarkUp && prices.max >= i.Fare.TotalFareWithOutMarkUp);
                } else {
                    return stops.indexOf(i.stop) > -1 &&
                        (airlines.indexOf(i.AirlineName) > -1) &&
                        (prices.min <= i.Fare.TotalFareWithOutMarkUp && prices.max >= i.Fare.TotalFareWithOutMarkUp) &&
                        (parseInt(filter.onWardDeptTime) <= parseInt(i.DepartureTime.split(':')[0]) && (parseInt(filter.onWardDeptTime) + 6) >= parseInt(i.DepartureTime.split(':')[0]));
                    //         // (parseInt(filter.returnDeptTime) < parseInt(i.ArrivalTime.split(':')[0]) && (parseInt(filter.returnDeptTime)+6) > parseInt(i.ArrivalTime.split(':')[0]));
                }
            }
            // (filter.onWardDeptTime < i.DepartureTime && (parseInt(filter.onWardDeptTime)+6) > i.DepartureTime );
        });

        let returnsFlightsFilter = this.state.returnsFlightData;
        let returnsFlightsFilterData = _.filter(returnsFlightsFilter, (i) => {

            let refundable = [];
            _.map(i.Fare.PaxFares, function (obj) {
                refundable.push(obj.Refundable);
            });

            if (filter.refundable !== '2' || filter.refundable !== 2) {
                if (filter.returnDeptTime === "24" && filter.returnDeptTime === "24") {
                    return stops.indexOf(i.stop) > -1 &&
                        (airlines.indexOf(i.AirlineName) > -1) &&
                        (refundable.indexOf(refund) > -1) &&
                        (prices.min <= i.Fare.TotalFareWithOutMarkUp && prices.max >= i.Fare.TotalFareWithOutMarkUp);
                } else {
                    return stops.indexOf(i.stop) > -1 &&
                        (airlines.indexOf(i.AirlineName) > -1) &&
                        (refundable.indexOf(refund) > -1) &&
                        (prices.min <= i.Fare.TotalFareWithOutMarkUp && prices.max >= i.Fare.TotalFareWithOutMarkUp) &&
                        (parseInt(filter.returnDeptTime) <= parseInt(i.DepartureTime.split(':')[0]) && (parseInt(filter.returnDeptTime) + 6) >= parseInt(i.DepartureTime.split(':')[0]));
                    //         // (parseInt(filter.returnDeptTime) < parseInt(i.ArrivalTime.split(':')[0]) && (parseInt(filter.returnDeptTime)+6) > parseInt(i.ArrivalTime.split(':')[0]));
                }
            } else {
                if (filter.returnDeptTime === "24" && filter.returnDeptTime === "24") {
                    return stops.indexOf(i.stop) > -1 &&
                        (airlines.indexOf(i.AirlineName) > -1) &&
                        (prices.min <= i.Fare.TotalFareWithOutMarkUp && prices.max >= i.Fare.TotalFareWithOutMarkUp);
                } else {
                    return stops.indexOf(i.stop) > -1 &&
                        (airlines.indexOf(i.AirlineName) > -1) &&
                        (prices.min <= i.Fare.TotalFareWithOutMarkUp && prices.max >= i.Fare.TotalFareWithOutMarkUp) &&
                        (parseInt(filter.returnDeptTime) <= parseInt(i.DepartureTime.split(':')[0]) && (parseInt(filter.returnDeptTime) + 6) >= parseInt(i.DepartureTime.split(':')[0]));
                    //         // (parseInt(filter.returnDeptTime) < parseInt(i.ArrivalTime.split(':')[0]) && (parseInt(filter.returnDeptTime)+6) > parseInt(i.ArrivalTime.split(':')[0]));
                }
            }
        });

        this.setState({
            prices: prices,
            onWardsFlightsFilter: onWardsFlightsFilter,
            returnsFlightsFilter: returnsFlightsFilterData,
            ...filter
        });


    }

    internationalFlightRender = () => {


        return (
            <div className="d-flex justify-content-start flightListWrapper row col-12 p-0 m-0">

                <InternationalFlightListview
                    flightType={0}
                    activeOnwardFlightIndex={this.state.activeOnwardFlightIndex}
                    flightLength={this.state.flightLength}
                    dataSource={this.state.onWardsFlightsFilter}
                    searchDetails={this.props.searchDetails}
                    onwards={this.state.onWardsFlightsFilter}
                    returns={this.state.returnsFlightsFilter}
                    onClick={(segment, key) => {
                        this.nextTab(segment, key);
                    }}
                />
            </div>
        )
    }

    domesticsFlightRender = () => {

        return (
            <div className="d-flex flightListWrapper row p-0 m-0 mt-3">
                {this.state.flightLength > 1 ?
                    <React.Fragment>
                        <div className="col-xl-12 col-12">
                            <div className="row">
                                {
                                    window.screen.width >= 320 && window.screen.width <= 768 ?
                                        <React.Fragment>
                                            <div className="col-6 px-s-1 px-sm-1">
                                                <MobileRoundTripFlightListComponent
                                                    flightType={0}
                                                    dataSource={this.state.onWardsFlightsFilter}
                                                    searchDetails={this.props.searchDetails}
                                                    onwards={this.state.onWardsFlightsFilter}
                                                    returns={this.state.returnsFlightsFilter}
                                                    activeOnwardFlightIndex={this.state.activeOnwardFlightIndex}
                                                    onClick={(segment, key) => {

                                                        if (this.props.flightLength > 1) {

                                                            this.nextTab(segment, key);
                                                            // let a = ReactDom.findDOMNode(this.refs.return);
                                                            // a.click();
                                                        } else {
                                                            this.nextTab(segment, key);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6 px-s-1 px-sm-1">
                                                <MobileRoundTripFlightListComponent
                                                    flightType={0}
                                                    dataSource={this.state.returnsFlightsFilter}
                                                    searchDetails={this.props.searchDetails}
                                                    onwards={this.state.onWardsFlightsFilter}
                                                    returns={this.state.returnsFlightsFilter}
                                                    activeOnwardFlightIndex={this.state.activeRoundFlightIndex}
                                                    onClick={(segment, key) => {
                                                        this.finalTab(segment, key);
                                                    }}
                                                />
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="col-xl-6 col-lg-6 col-md-6 px-xl-3 px-lg-3 px-md-2">
                                                <RoundTripFlightListComponent
                                                    flightType={0}
                                                    dataSource={this.state.onWardsFlightsFilter}
                                                    searchDetails={this.props.searchDetails}
                                                    onwards={this.state.onWardsFlightsFilter}
                                                    returns={this.state.returnsFlightsFilter}
                                                    activeOnwardFlightIndex={this.state.activeOnwardFlightIndex}
                                                    onClick={(segment, key) => {

                                                        if (this.props.flightLength > 1) {

                                                            this.nextTab(segment, key);
                                                            // let a = ReactDom.findDOMNode(this.refs.return);
                                                            // a.click();
                                                        } else {
                                                            this.nextTab(segment, key);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 px-xl-3 px-lg-3 px-md-2">
                                                <RoundTripFlightListComponent
                                                    flightType={0}
                                                    dataSource={this.state.returnsFlightsFilter}
                                                    searchDetails={this.props.searchDetails}
                                                    onwards={this.state.onWardsFlightsFilter}
                                                    returns={this.state.returnsFlightsFilter}
                                                    activeOnwardFlightIndex={this.state.activeRoundFlightIndex}
                                                    onClick={(segment, key) => {
                                                        this.finalTab(segment, key);
                                                    }}
                                                />

                                            </div>
                                        </React.Fragment>
                                }
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="col-xl-12 col-12">

                            <FlightListView
                                flightType={0}
                                dataSource={this.state.onWardsFlightsFilter}
                                searchDetails={this.props.searchDetails}
                                onwards={this.state.onWardsFlightsFilter}
                                returns={this.state.returnsFlightsFilter}
                                activeOnwardFlightIndex={this.state.activeOnwardFlightIndex}
                                onClick={(segment, key) => {

                                    if (this.props.flightLength > 1) {

                                        this.nextTab(segment, key);
                                        // let a = ReactDom.findDOMNode(this.refs.return);
                                        // a.click();
                                    } else {
                                        this.nextTab(segment, key);
                                    }
                                }}
                            />
                        </div>
                    </React.Fragment>
                }


            </div>
        )
    }

    nextPreviousDayOnwardSearch = (type) => {

        if (this.state.searchDetails !== null) {
            let searchDetails = this.state.searchDetails;
            if (type === "Next") {

                searchDetails.beginDate = moment(searchDetails.beginDate).add(1, 'days').format('MM/DD/YYYY');
                let returnDate = moment(new Date(searchDetails.returnDate)).format('MM/DD/YYYY');

                if (returnDate <= moment(searchDetails.beginDate).format('MM/DD/YYYY')) {
                    returnDate = searchDetails.beginDate;
                }
                searchDetails.returnDate = returnDate;
                // searchDetails.beginDate = moment(searchDetails.beginDate).add(1, 'days').format('MM/DD/YYYY');
            } else {

                let previousDate = moment(searchDetails.beginDate).add(-1, 'days').format('MM/DD/YYYY');
                if (moment(searchDetails.beginDate).add(-1, 'days').format('MM/DD/YYYY') >= moment().format('MM/DD/YYYY')) {
                    searchDetails.beginDate = previousDate;
                }
            }
            let userId = 0;
            if (this.state.isLoggedIn === true) {
                let users = Auth.user();
                if (users) {
                    userId = users.user_id
                }
            }

            this.props.filterSearchFlight(searchDetails, userId);
        }

    }

    nextPreviousDayRoundSearch = (type) => {
        if (this.state.searchDetails !== null) {
            let searchDetails = this.state.searchDetails;
            if (type === "Next") {
                searchDetails.returnDate = moment(searchDetails.returnDate).add(1, 'days').format('MM/DD/YYYY');
            } else {

                let previousDate = moment(searchDetails.returnDate).add(-1, 'days').format('MM/DD/YYYY');

                if (previousDate <= moment(searchDetails.beginDate).format('MM/DD/YYYY')) {
                    previousDate = searchDetails.beginDate;
                }

                // searchDetails.returnDate = previousDate;

                if (previousDate >= moment().format('MM/DD/YYYY')) {
                    searchDetails.returnDate = previousDate;
                }

            }
            let userId = 0;
            if (this.state.isLoggedIn === true) {
                let users = Auth.user();
                if (users) {
                    userId = users.user_id
                }
            }

            this.props.filterSearchFlight(searchDetails, userId);
        }
    }

    handleOpen = () => {
        this.setState({
            isFilterDrawer: true
        })
    }

    render() {

        if (this.state.onWardsFlightsFilter === null) {

            return (
                <div className="container">
                    <TopBar {...this.props} title={"Searching..."}/>
                    <div className="row">
                        <div className="col-12 text-center">
                            <img src={LoaderImg} className="img-fluid mt-5" alt="" />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <TopBar {...this.props}
                        title={this.state.searchDetails !== null ? this.state.searchDetails.from + "-" + this.state.searchDetails.to + " Booking" : "Searching..."}/>
                <div>
                    <Button
                        className="floating-menu bp3-button bp3-small hidden-xl hidden-lg hidden-md hidden-sm visible-xs"
                        intent={Intent.PRIMARY}
                        onClick={() => this.handleOpen()}
                    >
                        <span className="bp3-icon bp3-icon-filter"></span>
                    </Button>

                    <div className="filterSearchFlightWrapper mb-4 pt-3 pb-2 pl-3 pr-3">
                        {
                            this.state.searchDetails !== null ?
                                <TripSearchContainer
                                    {...this.props}
                                    searchType={true}
                                    searchDetails={this.state.searchDetails}
                                    onSearch={(searchData) => {
                                        this.props.filterSearchFlight(searchData, 9001);
                                    }}
                                />
                                :
                                ''
                        }

                    </div>
                    <div className="row flight-list mb-5">
                        <Drawer className="flight-list" open={this.state.isFilterDrawer} onClose={() => {
                            this.setState({
                                isFilterDrawer: false
                            })
                        }} style={{width: '500px'}}>
                            <MobileFilterComponent
                                prices={this.state.prices}
                                airportList={this.state.airportList}
                                price_max_value={this.state.price_max_value}
                                price_min_value={this.state.price_min_value}
                                searchDetails={this.state.searchDetails}
                                onFilter={(filter) => {
                                    this.setState({
                                        isFilterDrawer: false
                                    })
                                    this.applyFilter(filter, 'mobile');
                                }}
                            />
                        </Drawer>
                        <FilterComponent
                            prices={this.state.prices}
                            airportList={this.state.airportList}
                            price_max_value={this.state.price_max_value}
                            price_min_value={this.state.price_min_value}
                            searchDetails={this.state.searchDetails}
                            onFilter={(filter) => {
                                this.applyFilter(filter);
                            }}
                        />

                        {
                            this.props.searchFlightData !== null ?
                                <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12 no-gutter p-0">
                                    {this.state.flightLength > 1 && !this.state.airpot_type ?
										<React.Fragment>
											<div className={"col-12 flight-list-roundtrip"}>
												<div className={"row"}>
													<div
														className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 px-lg-3 px-xl-3 px-md-2">
														<div className="flightListHeader mb-3">
															<div className="row align-items-center d-flex justify-content-center">
																{
																	moment(this.state.searchDetails.beginDate).add(-1, 'days').format('MM/DD/YYYY') >= moment().format('MM/DD/YYYY') ?
																		<div
																			className="col-xl-4 col-4 text-left"
																			onClick={() => this.nextPreviousDayOnwardSearch('Previous')}>
																			<span><KeyboardArrowLeftRounded/></span>
																		</div>
																		:
																		<div
																			className="col-xl-4 col-4 text-left arrow-menu-disable"
																			onClick={() => this.nextPreviousDayOnwardSearch('Previous')}>
																			<span><KeyboardArrowLeftRounded/></span>
																		</div>
																}
																<div
																	className="col-xl-4 col-4 text-center text-nowrap p-s-0  p-sm-0">
																	<span>{moment(this.state.beginDate).format('DD MMM,ddd')}</span>
																</div>
																<div
																	className="col-xl-4 col-4 text-right"
																	onClick={() => this.nextPreviousDayOnwardSearch('Next')}>
																	<span><KeyboardArrowRightRounded
																		transform="rotate(360)"/></span>
																</div>
															</div>
														</div>
													</div>
													<div
														className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 px-xl-3 px-lg-3 px-md-3">
														<div className="flightListHeader mb-3">
															<div className="row align-items-center d-flex justify-content-center">
																{
																	moment(this.state.searchDetails.returnDate).add(-1, 'days').format('MM/DD/YYYY') >= moment().format('MM/DD/YYYY') ?
																		<div
																			className="col-xl-4 col-4 text-left"
																			onClick={() => this.nextPreviousDayRoundSearch('Previous')}>
																			<span><KeyboardArrowLeftRounded/></span>
																		</div>
																		:
																		<div
																			className="col-xl-4 col-4 text-left arrow-menu-disable"
																			onClick={() => this.nextPreviousDayRoundSearch('Previous')}>
																			<span><KeyboardArrowLeftRounded/></span>
																		</div>
																}

																<div
																	className="col-xl-4 col-4 text-center text-nowrap p-s-0  p-sm-0">
																	<span>{moment(this.state.returnDate).format('DD MMM,ddd')}</span>
																</div>
																<div
																	className="col-xl-4 col-4 text-right"
																	onClick={() => this.nextPreviousDayRoundSearch('Next')}>
																	<span><KeyboardArrowRightRounded
																		transform="rotate(360)"/></span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 flight-list-roundtrip">
												<div className={"row"}>
													<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
														<div className="row flightListSort  mb-3">
															{
																this.state.Departure === 'ASC' ?
																	<div
																		className={this.state.sortByFields === 'Departure' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('onWard', 'ASC', 'Departure')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Departure
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	<ArrowDownwardRounded
																		className={"text-right"}
																		transform="rotate(180)"/>
																</span>

																	</div>
																	:
																	<div
																		className={this.state.sortByFields === 'Departure' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('onWard', 'DESC', 'Departure')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Departure
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	<ArrowDownwardRounded
																		className={"text-right"}/>
																</span>

																	</div>
															}
															{
																this.state.Arrival === 'ASC' ?
																	<div
																		className={this.state.sortByFields === 'Arrival' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('onWard', 'ASC', 'Arrival')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Arrival
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	<ArrowDownwardRounded
																		transform="rotate(180)"/>
																</span>

																	</div>
																	:
																	<div
																		className={this.state.sortByFields === 'Arrival' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('onWard', 'DESC', 'Arrival')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Arrival
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	<ArrowDownwardRounded/>
																</span>

																	</div>
															}
															{
																this.state.Durations === 'ASC' ?
																	<div
																		className={this.state.sortByFields === 'Durations' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('onWard', 'ASC', 'Durations')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Durations
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	 <ArrowDownwardRounded
																		 transform="rotate(180)"/>
																</span>

																	</div>
																	:
																	<div
																		className={this.state.sortByFields === 'Durations' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('onWard', 'DESC', 'Durations')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Durations
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	 <ArrowDownwardRounded/>
																</span>

																	</div>
															}

															{
																this.state.Fare.trim() === "ASC" ?
																	<div
																		className={this.state.sortByFields === 'Fare' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('onWard', 'ASC', 'Fare')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Price
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	 <ArrowDownwardRounded
																		 transform="rotate(180)"/>
																</span>

																	</div>
																	:
																	<div
																		className={this.state.sortByFields === 'Fare' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('onWard', 'DESC', 'Fare')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Price
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	 <ArrowDownwardRounded/>
																</span>

																	</div>
															}
														</div>
													</div>
													<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
														<div className="row flightListSort  mb-3">
															{
																this.state.DepartureRound === 'ASC' ?
																	<div
																		className={this.state.sortByFieldsRound === 'DepartureRound' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('Round', 'ASC', 'DepartureRound')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Departure
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	<ArrowDownwardRounded
																		className={"text-right"}
																		transform="rotate(180)"/>
																</span>

																	</div>
																	:
																	<div
																		className={this.state.sortByFieldsRound === 'DepartureRound' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('Round', 'DESC', 'DepartureRound')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Departure
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	<ArrowDownwardRounded
																		className={"text-right"}/>
																</span>

																	</div>
															}
															{
																this.state.ArrivalRound === 'ASC' ?
																	<div
																		className={this.state.sortByFieldsRound === 'ArrivalRound' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('Round', 'ASC', 'ArrivalRound')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Arrival
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	<ArrowDownwardRounded
																		transform="rotate(180)"/>
																</span>

																	</div>
																	:
																	<div
																		className={this.state.sortByFieldsRound === 'ArrivalRound' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('Round', 'DESC', 'ArrivalRound')}
																	>

															   <span
																   className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Arrival
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	<ArrowDownwardRounded/>
																</span>

																	</div>
															}
															{
																this.state.DurationsRound === 'ASC' ?
																	<div
																		className={this.state.sortByFieldsRound === 'DurationsRound' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('Round', 'ASC', 'DurationsRound')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Durations
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	 <ArrowDownwardRounded
																		 transform="rotate(180)"/>
																</span>

																	</div>
																	:
																	<div
																		className={this.state.sortByFieldsRound === 'DurationsRound' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('Round', 'DESC', 'DurationsRound')}
																	>

																<span
																	className="pull-left p-0 mb-2 font-12-sm text-dark">
																  Durations
																</span>
																			<span
																				className="pull-right p-0  mb-2">
																	 <ArrowDownwardRounded/>
																</span>

																	</div>
															}

															{
																this.state.FareRound === "ASC" ?
																	<div
																		className={this.state.sortByFieldsRound === 'FareRound' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('Round', 'ASC', 'FareRound')}
																	>

															<span
																className="pull-left p-0 mb-2 font-12-sm text-dark">
															  Price
															</span>
																			<span
																				className="pull-right p-0  mb-2">
																 <ArrowDownwardRounded
																	 transform="rotate(180)"/>
															</span>

																	</div>
																	:
																	<div
																		className={this.state.sortByFieldsRound === 'FareRound' ? "col mt-1 active " : "col mt-1"}
																		onClick={() => this.sortBy('Round', 'DESC', 'FareRound')}
																	>

															<span
																className="pull-left p-0 mb-2 font-12-sm text-dark">
															  Price
															</span>
																			<span
																				className="pull-right p-0  mb-2">
																 <ArrowDownwardRounded/>
															</span>

																	</div>
															}
														</div>
													</div>
												</div>
											</div>
										</React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="col-xl-12 col-12 py-s-3">
                                                <div className="flightListHeader col-xl-12 col-12 mt-0 mb-1">
                                                    <div className="row align-items-center d-flex justify-content-center">
                                                        <div className="col-xl-4 col-3 text-left"
                                                             onClick={() => this.nextPreviousDayOnwardSearch("Previous")}>
                                                            <span><KeyboardArrowLeftRounded
                                                                transform="rotate(360)"/></span>
                                                        </div>
                                                        <div className="col-xl-4 col-6 text-center">
                                                            <span>{moment(this.state.beginDate).format('DD MMM,ddd')}</span>
                                                        </div>
                                                        <div className="col-xl-4 col-3 text-right"
                                                             onClick={() => this.nextPreviousDayOnwardSearch("Next")}>
                                                            <span><KeyboardArrowRightRounded/></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-12 mt-xl-0 mt-lg-2 mt-md-2 mt-sm-2">
                                                <div className="row flightListSort  mb-3">
                                                    {
                                                        this.state.Departure === 'ASC' ?
                                                            <div
                                                                className={this.state.sortByFields === 'Departure' ? "col active mt-1 " : "col mt-1"}
                                                                onClick={() => this.sortBy('onWard', 'ASC', 'Departure')}
                                                            >
                                                                <p
                                                                    className="justify-content-center pull-left p-0 mb-2 mt-xl-2 mt-xl-2 pl-xl-4"

                                                                >
                                                                    Departure

                                                                </p>
                                                                <span
                                                                    className="justify-content-center p-0 mb-2 pull-right">
                                                                    <ArrowDownwardRounded className={"text-right"}
                                                                                          transform="rotate(180)"/>
                                                                </span>
                                                            </div>
                                                            :
                                                            <div
                                                                className={this.state.sortByFields === 'Departure' ? "col active mt-1 " : "mt-1"}
                                                                onClick={() => this.sortBy('onWard', 'DESC', 'Departure')}
                                                            >
                                                                <p
                                                                    className="justify-content-center pull-left p-0 mb-2 mt-xl-2 mt-xl-2 pl-xl-4"
                                                                >
                                                                    Departure


                                                                </p>
                                                                <span
                                                                    className="justify-content-center p-0 mb-2 pull-right">
                                                                    <ArrowDownwardRounded/>
                                                                </span>
                                                            </div>
                                                    }
                                                    {
                                                        this.state.Arrival === 'ASC' ?
                                                            <div
                                                                className={this.state.sortByFields === 'Arrival' ? "col active mt-1 " : "col mt-1"}
                                                                onClick={() => this.sortBy('onWard', 'ASC', 'Arrival')}
                                                            >
                                                                <p
                                                                    className="justify-content-center pull-left p-0 mb-2 mt-xl-2 mt-xl-2 pl-xl-4"
                                                                >
                                                                    Arrival

                                                                </p>
                                                                <span
                                                                    className="justify-content-center p-0 mb-2 pull-right">
                                                                    <ArrowDownwardRounded transform="rotate(180)"/>
                                                                </span>
                                                            </div>
                                                            :
                                                            <div
                                                                className={this.state.sortByFields === 'Arrival' ? "col active mt-1 " : "col mt-1"}
                                                                onClick={() => this.sortBy('onWard', 'DESC', 'Arrival')}
                                                            >
                                                                <p
                                                                    className="justify-content-center pull-left p-0 mb-2 mt-xl-2 mt-xl-2 pl-xl-4"

                                                                >
                                                                    Arrival


                                                                </p>
                                                                <span
                                                                    className="justify-content-center p-0 mb-2 pull-right">
                                                                     <ArrowDownwardRounded/>
                                                                </span>
                                                            </div>
                                                    }
                                                    {
                                                        this.state.Durations === 'ASC' ?
                                                            <div
                                                                className={this.state.sortByFields === 'Durations' ? "col active mt-1 " : "col mt-1"}
                                                                onClick={() => this.sortBy('onWard', 'ASC', 'Durations')}
                                                            >
                                                                <p
                                                                    className="justify-content-center pull-left p-0 mb-2 mt-xl-2 mt-xl-2 pl-xl-4"

                                                                >
                                                                    Durations

                                                                </p>
                                                                <span
                                                                    className="justify-content-center p-0 mb-2 pull-right">
                                                                     <ArrowDownwardRounded transform="rotate(180)"/>
                                                                </span>
                                                            </div>
                                                            :
                                                            <div
                                                                className={this.state.sortByFields === 'Durations' ? "col active mt-1 " : "col mt-1"}
                                                                onClick={() => this.sortBy('onWard', 'DESC', 'Durations')}
                                                            >
                                                                <p
                                                                    className="justify-content-center pull-left p-0 mb-2 mt-xl-2 mt-xl-2 pl-xl-4"
                                                                >
                                                                    Durations


                                                                </p>
                                                                <span
                                                                    className="justify-content-center p-0 mb-2 pull-right">
                                                                    <ArrowDownwardRounded
                                                                    />
                                                                </span>
                                                            </div>
                                                    }

                                                    {
                                                        this.state.Fare.trim() === "ASC" ?
                                                            <div
                                                                className={this.state.sortByFields === 'Fare' ? "col active mt-1 " : "col mt-1"}
                                                                onClick={() => this.sortBy('onWard', 'ASC', 'Fare')}
                                                            >
                                                                <p
                                                                    className="justify-content-center pull-left p-0 mb-2 mt-xl-2 mt-xl-2 pl-xl-4"

                                                                >
                                                                    Price

                                                                </p>
                                                                <span
                                                                    className="justify-content-center p-0 mb-2 pull-right">
                                                                    <ArrowDownwardRounded
                                                                        transform="rotate(180)"

                                                                    />
                                                                </span>
                                                            </div>
                                                            :
                                                            <div
                                                                className={this.state.sortByFields === 'Fare' ? "col active mt-1 " : "col mt-1"}
                                                                onClick={() => this.sortBy('onWard', 'DESC', 'Fare')}
                                                            >
                                                                <p className="justify-content-center pull-left p-0 mb-2 mt-xl-2 mt-xl-2 pl-xl-4">
                                                                    Price
                                                                </p>
                                                                <span
                                                                    className="justify-content-center p-0 mb-2 pull-right mt-xl-2 mt-xl-2">
                                                                     <ArrowDownwardRounded

                                                                     />
                                                                </span>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {
                                        this.props.searchFlightData !== null && this.props.searchFlightData.length !== 0 ?
                                            <React.Fragment>
                                                {
                                                    this.state.airpot_type === true ?
                                                        this.internationalFlightRender()
                                                        :
                                                        this.domesticsFlightRender()
                                                }
                                                <div
                                                    className="d-flex justify-content-start trip-sort-div row col-12 p-0 m-0">
                                                    <div className="selectedFlightWrapper">
                                                        <div
                                                            className={this.state.selectedReturnsFlights ? 'selectedFlight p-0 px-s-3 p-0' : 'selectedFlight px-s-3 p-0'}>
                                                            <div className="container">
                                                                <div className="row justify-content-center align-items-center px-md-0">
                                                                    {
                                                                        this.state.flightLength > 1 ?
                                                                            this.selectedFlightListing()
                                                                            :
                                                                            this.selectedOnwardsFlightListing()
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            :
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <img src={LoaderImg} className="img-fluid mt-5" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                                :
                                <div className=" col-xl-9 col-lg-9 col-md-8 col-sm-7 col-12 no-gutter p-0">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <span>NO Data Found</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            </React.Fragment>
        );
    }
}
