let initialState = {
    ticketDetails: null,
    bookingDetails: null
};
const flightPayment = (state = initialState, action) => {

    switch (action.type) {
         case 'TICKET_DETAILS':
            return {...state, ticketDetails: action.ticketDetails}
         case 'BOOKING_DETAILS':
            return {...state, bookingDetails: action.bookingDetails}
        default:
            return state
    }
}

export default flightPayment