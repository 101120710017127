import React, {Component} from 'react';
import CancelBookingContainer from './container/CancelBookingContainer';

class CancelBooking extends Component {

    componentDidMount(){
        setTimeout(()=>{
            window.scrollTo(0,0)
        },3000)
    }

    render() {
        return (
            <div className="container">
                <CancelBookingContainer {...this.props} />
            </div>
        );
    }
}

export default CancelBooking;