import React,{ Component } from 'react';
import FlightFareContainer from './container/FlightFareContainer';

export default class FlightFare extends Component {
  constructor(props) {
    super(props);
    this.state={

    }
  }

  componentDidMount(){
    setTimeout(()=>{
      window.scrollTo(0,0)
    },3000)
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">

          <FlightFareContainer {...this.props} />
        </div>
      </React.Fragment>
    );
  }
}
