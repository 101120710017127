import React, {Component} from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import FlightInfo from "./FlightDetails/FlightInfo";
import FareDetails from "./FlightDetails/FareDetails";
import BaggageInfo from "./FlightDetails/BaggageInfo";
import CancellationRules from "./FlightDetails/CancellationRules";
import FlightDetailModal from './FlightDetailModal';

export default class FlightDetails extends Component{

    constructor(props){
        super(props);

        this.state={
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true,
        }
    }

    handleClose=()=>{
        this.setState({
            isOpen: false
        })
    }

    handleOpen=()=>{
        this.setState({
            isOpen: true
        })
    }

    componentWillReceiveProps(nextprops){
        if(nextprops.isOpen!==undefined){
            this.setState({
                isOpen: nextprops.isOpen
            });
        }

    }

    render(){
        return(
            <React.Fragment>
                {/*{*/}
                    {/*this.props.toggle?*/}
                        {/*this.props.toggle:*/}
                        {/*<p className="flight-details" onClick={() => this.handleOpen()}>Flight Details</p>*/}
                {/*}*/}
                <FlightDetailModal
                    toggle={<p className="flight-details" onClick={() => this.handleOpen()}>Flight Details</p>}
                    title="Flight Details"
                    isOpen={this.state.isOpen}
                    onClose={()=>{
                        this.setState({
                            isOpen:false
                        })
                    }}
                    className="bp3-dialog bp3-dialog-large modal-flightDetails"
                >
                    <div className="col-12 border-top justify-content-center d-flex">
                        <div className="col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12 py-3">
                            <div className="row">
                                <Tabs className="w-100" id="TabsExample" onChange={this.handleTabChange} defaultSelectedTabId={1} selectedTabId={this.state.selectedTabId}>
                                    <Tab id={1} title="Flight Inforamtion" panel={<FlightInfo {...this.props} />} />
                                    <Tab id={2} title="Fare details" panel={<FareDetails {...this.props} />} />
                                    <Tab id={3} title="Baggage information" panel={<BaggageInfo {...this.props} />} />
                                    <Tab id={4} title="Cancellation rules" panel={<CancellationRules {...this.props} />} />
                                    <Tabs.Expander />
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </FlightDetailModal>
            </React.Fragment>
        );
    }

}
