
let initialState = {
    SEARCH_DETAILS: null,
    SEARCH_FLIGHT: null,
    FARE_DETAILS: [],
    FARE_PAYMENT_DETAILS: [],
    RECHECK_PRICE: '',
    ALL_TAXES: null,
};
const searchFlightData = (state = initialState, action) => {

    switch (action.type) {
        case 'SEARCH_DETAILS':
            // console.log(action.getAirport)
            return {...state, SEARCH_DETAILS: action.SEARCH_DETAILS}
        case 'SEARCH_FLIGHT':
            // console.log(action.getAirport)
            return {...state, SEARCH_FLIGHT: action.SEARCH_FLIGHT}
        case 'FARE_DETAILS':
            // console.log(action.getAirport)
            return {...state, FARE_DETAILS: action.FARE_DETAILS}
        case 'FARE_PAYMENT_DETAILS':
            // console.log(action.getAirport)
            return {...state, FARE_PAYMENT_DETAILS: action.FARE_PAYMENT_DETAILS}
        case 'RECHECK_PRICE':
            return {...state, RECHECK_PRICE: action.RECHECK_PRICE}
        case 'ALL_TAXES':
            return {...state, ALL_TAXES: action.ALL_TAXES}
        default:
            return state
    }
}

export default searchFlightData