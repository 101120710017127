import { ssoInstance,Auth } from 'gg-react-utilities';
import Config from '../Config';
import _ from 'lodash';

export const bookingBeforePayment = (bookingData) => {

    let segment = _.filter(bookingData.Segment,(sub)=> {
        return !_.isEmpty(sub);
    });
    bookingData.Segment=segment;
    bookingData.customer_type='2';

    return ssoInstance.post(Config.FLIGHT_API_URL + '/bookingBeforePayment',bookingData);
}

export const bookingAfterPayment = (data) => {

    return ssoInstance.post(Config.FLIGHT_API_URL + '/bookingAfterPayment',data);
}

export const allTaxes = (amount,gg_coin,id) => {
    console.log("issss",id)
    return ssoInstance.post(Config.FLIGHT_API_URL + '/getTaxesList',{
        "amount":amount,
        "ggCoin":gg_coin,
        "AppliedVoucheIds":id
    });
}

export const reCheckPrice = (postdata) => {

    return ssoInstance.post(Config.FLIGHT_API_URL + '/flightRecheck',postdata);
}


export const searchHint = (search) => {

    return ssoInstance.post(Config.FLIGHT_API_URL + '/getAirport',
        {
            "searchAirportData" : search
        }
    )
}

export const getSignature = (amount,orderId) => {

    return ssoInstance.post(Config.FLIGHT_API_URL + '/createSignature',
        {
            "amount" : amount,
            "orderId" : orderId
        }
    )
}

export const getBookingDetails = async (type,page=null) => {

    let userId = 0;
    let url=Config.FLIGHT_API_URL + '/bookingHistoryData';
    if(await Auth.isLoggedInAsync() === true){
        userId = Auth.user().user_id;

    }

    if (page !== null){
        url=Config.FLIGHT_API_URL + '/bookingHistoryData?page='+page;
    }

    // return ssoInstance.get('https://beta-admin.globalgarner.in/api/get-active-module');
    return ssoInstance.post(url,
        {
            "booking_type" : type,
            "sso_user_id" : userId
        }
    )
}

export const bookedTicketDetail = async (orderId,traceId) => {


    return ssoInstance.get(Config.FLIGHT_API_URL + '/orderDetail/'+orderId+'/'+traceId)
}

export const cancelTicketDetail = async (orderId,traceId) => {

    let userId = 0;
    if(await Auth.isLoggedInAsync() != null){
        userId = Auth.user().user_id;
    }

    return ssoInstance.post(Config.FLIGHT_API_URL + '/bookedTicketDetail',
        {
            "userId" : userId,
            "orderId" : orderId,
            "traceId" : traceId
        }
    )
}

export const cancelBooking = (cancelReq) => {

    return ssoInstance.post(Config.FLIGHT_API_URL + '/cancelBooking',cancelReq)
}

export const cancelBookingRecheck = (cancelReq) => {

    return ssoInstance.post(Config.FLIGHT_API_URL + '/cancelPrecheck',cancelReq)
}

export const ticketDownload = (orderId,traceId) => {

    return ssoInstance.post(Config.FLIGHT_API_URL + '/ticketDownload',{
                            traceId:traceId,
                            orderId:orderId
                        })
}

export const searchFlightsList = (searchFlight) =>{
    return ssoInstance.post(Config.FLIGHT_API_URL + '/flightSearch', {
        adults:searchFlight['adults'],
        beginDate:searchFlight['beginDate'],
        cabin:searchFlight['cabin'],
        childs:searchFlight['childs'],
        infants:searchFlight['infants'],
        returnDate:searchFlight['returnDate'],
        tripType:searchFlight['tripType'],
        from:searchFlight['from'],
        to:searchFlight['to'],
    });
}
