import React from 'react';

import SearchFlightContainer from './container/SearchFlightContainer';


export default class SearchFlights extends React.Component {
  constructor(props) { 
    super(props);
    this.state={
      isHeader:'web'
    }
  }

  componentDidMount(){
    setTimeout(()=>{
      window.scrollTo(0,0)
    },3000)
  }

  componentWillReceiveProps(nextProps, nextContext) {

  }


  render() {


    return (
      <React.Fragment>
        <div className="container">


          <SearchFlightContainer {...this.props} />

        </div>
      </React.Fragment>
    );
  }
}
