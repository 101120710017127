import React,{Component} from 'react';
import Config from '../../../../Config';

export default class CancellationRules extends Component{

    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount(){
        this.setState({
            OnWardsBeggageInfo:[this.props.segment.Fare.PaxFares[0]],
            OwnwardRemark:this.props.segment.Remark,
            OnwardsCancellationRule:this.props.segment.FareRule,
        })
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            OnWardsBeggageInfo:[nextProps.segment.Fare.PaxFares[0]],
            OwnwardRemark:nextProps.segment.Remark,
            OnwardsCancellationRule:nextProps.segment.FareRule,
        })
    }

    render(){ 
        return(
            <div className="card border border-white">
                <div className="card-body py-0 px-2">
                    <div className="baggageInfo mt-2">
                        <h4 className="title mb-4">Cancellation/Changes Policy</h4>

                        {
                            
                            (typeof(this.state.OnwardsCancellationRule) !== "undefined")?
                                ( this.state.OnwardsCancellationRule !== null ) ?
                                    <div className="row">
                                        <div className="col-12 cancel-policy">
                                            <h6 className="mb-2">{this.props.from} to {this.props.to}</h6>
                                            <div className="col-12">
                                                {
                                                    ((this.state.OnwardsCancellationRule.split('|').length > 1) && (this.state.OnwardsCancellationRule.split('|')[0].split('CAN-BEF')[1].split(':')[1] > 0))?
                                                        <p className="mb-1">Booking cancellation before
                                                            {this.state.OnwardsCancellationRule.split('|')[0].split('CAN-BEF')[1].split(':')[0].split('_')[0]+':'+this.state.OnwardsCancellationRule.split('|')[0].split('CAN-BEF')[1].split(':')[0].split('_')[1]}
                                                            {

                                                                ' hours '+parseFloat(this.state.OnwardsCancellationRule.split('|')[0].split('CAN-BEF')[1].split(':')[1]).toLocaleString('en-IN', {
                                                                    maximumFractionDigits: 2,
                                                                    style: 'currency',
                                                                    currency: 'INR'
                                                                })}*</p>
                                                        :''
                                                }

                                            </div>
                                            <div className="col-12">
                                                {
                                                    (this.state.OnwardsCancellationRule.split('|').length > 1)?
                                                        <p className="mb-2"> Booking changes before
                                                            {this.state.OnwardsCancellationRule.split('|')[1].split('CHG-BEF')[1].split(':')[0].split('_')[0]+':'+this.state.OnwardsCancellationRule.split('|')[1].split('CHG-BEF')[1].split(':')[0].split('_')[1]}
                                                            {' hours  '+parseFloat(this.state.OnwardsCancellationRule.split('|')[1].split('CHG-BEF')[1].split(':')[1]).toLocaleString('en-IN', {
                                                                maximumFractionDigits: 2,
                                                                style: 'currency',
                                                                currency: 'INR'
                                                            })}*</p>
                                                        :''
                                                }
                                            </div>
                                            <div className="col-12">
                                                {(this.state.OwnwardRemark !== "")?<p className="notes">{this.state.OwnwardRemark} </p>:''}
                                            </div>
                                        </div>
                                    </div> 
                                : ""
                            :""
                          
                        }

                        <div className="row mt-2">
                            <div className="col-12 policy">
                                <h4><strong>Note* :</strong></h4>
                                <p>i). Cancellation charges may vary at the time of cancellation.</p>
                                <p>ii). Mentioned above charges per traveller.</p>
                                <p className="mt-2"><strong>Global Garner cancellation/change charges  will be {parseFloat(Config.GG_CANCELLATION_CHARGE).toLocaleString('en-IN', {
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'INR'
                                })} per traveller.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
