import React, {Component} from 'react';
import {Button, Classes, Dialog, Intent} from "@blueprintjs/core";
import {Loader} from "gg-react-utilities";

class CancelTicketConfirmModel extends Component {

    constructor(props){
        super(props);

        this.state={
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true,
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            cancelProcess:nextProps.cancelProcess,
            confirmCancelModelStatus:nextProps.confirmCancelModelStatus
        })
    }

    handleClose=()=>{
        this.setState({
            confirmCancelModelStatus: false
        })
    }

    handleOpen=()=>{
        this.setState({
            confirmCancelModelStatus: true
        })
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.toggle?
                        this.props.toggle:
                        <Button small={true}
                                intent="danger"
                                text="Cancel booking"
                                onClick={()=>{
                                    this.setState({
                                        confirmCancelModelStatus:!this.state.confirmCancelModelStatus
                                    })
                                }}
                                className="btn-danger cancel-btn"
                        />
                }
                <Dialog
                    isOpen={this.state.confirmCancelModelStatus}
                    title="Cancel Confirmation"
                    lazy={false}
                    onClose={this.handleClose}
                >
                    <div className={Classes.DIALOG_BODY+' p-1 m-0 py-3'} >
                        {this.props.children}
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <div className="row py-2">
                                <div className="col-md-12 text-right">
                                    {
                                        this.props.cancelProcess===true?
                                            <React.Fragment>
                                                <Loader/>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <Button intent={Intent.PRIMARY}
                                                        onClick={(e)=>{this.props.onCancelTicket()}}
                                                >Yes</Button>
                                                <Button intent={Intent.SECONDARY}
                                                        onClick={(e)=>{
                                                            this.setState({
                                                                confirmCancelModelStatus:!this.state.confirmCancelModelStatus
                                                            })
                                                        }}
                                                >No</Button>
                                            </React.Fragment>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default CancelTicketConfirmModel;