import React, {Component} from 'react';
import Config from '../../../Config';

import {
  ArrowRightAltRounded,
  ArrowBackRounded
} from '@material-ui/icons';
import {RadioGroup, Radio} from '@blueprintjs/core';
import FlightDetails from "./FlightDetails";
import _ from 'lodash';

export default class FlightListComponent extends Component {

    /* START HERE -  CLASS CONSTRUCTOR TO GER PROPERTITY FROM PARENT CLASS AND SET STATE OF THIS CLASS */
    constructor(props) {
        super(props);
        this.state = {
            dataSource:[],
            onwardsList:[],
            returnsList:[],
        };
        setTimeout(()=>{
            this.setState({
                dataSource:this.props.dataSource,
                onwards:this.props.onwards,
                returns:this.props.returns,
            });
        },100);

    }

    componentDidMount() {
    }

    componentWillReceiveProps(nexrProps){
        if(parseInt(nexrProps.modelId,10) !== parseInt(this.props.modelId,10)){

            this.contentLoaded =0;
        }
    }

    flightOnClick=(segment,key)=>{
        this.props.onClick(segment,key);
    }

    render(){
        if (!this.props.dataSource) {

            return (
                <div>
                    Loading....
                </div>

            );

        }else {


            if(this.props.dataSource.length>0){
                    return this.props.dataSource.map((flight,key)=>{

                            let flightImage = Config.IMAGE_URL+flight.AirlineName+'.png';

                            let refundable = false;
                            _.map(flight.Fare.PaxFares, function (obj) {
                                if (obj.Refundable === true) {
                                  refundable = true;
                                }
                            });

                            return <div className={"flight-searchlist"}  key={key}>
										<div className={this.props.activeOnwardFlightIndex===key?'selectedFlightCard':''} >
											<div className={"p-xl-3 p-lg-3 p-md-3 py-s-2 py-sm-2 col-xl-11 col-lg-11 col-md-11 offset-xl-1 col-sm-12 col-12 offset-lg-1"} onClick={()=> this.flightOnClick(flight.segment,key) }>
												<div className={'row'}>
													<div className={"col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12"}>
														<div className={'row mt-s-5 mt-sm-5 mt-md-0'}>
															<div className={'col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5'}>
																<div className={'row'}>
																	<div className={'col-4 pl-2 pr-0'}>
																		<div className="">
																			<img alt="" src={flightImage} title={flight.AirlineName} className="flightCompanyLogo rounded-circle m-auto pr-3" />
																		</div>
																	</div>
																	<div className={'col-8 pl-s-1 pl-sm-2 pr-s-0 pr-sm-0 pl-md-2'}>
																		<div className="flight-name">
																			<p className="fName"> {flight.flighttName} </p>
																			<p className="fnumber">({flight.FlightNumber})</p>
																		</div>
																	</div>
																</div>
															</div>
															<div className={'col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4 px-s-1 px-sm-1'}>
																<div className={'row text-center pr-s-1 pr-sm-1 pr-sm-0'}>
																	<div className={'mr-2'}>
																		<div className="">
																			<p className="journeyTime m-0">{flight.DepartureTime}<br/><span>{flight.Origin}</span></p>
																		</div>
																	</div>
																	<div className={'mr-2'}>
																		<div className="">
																			<ArrowRightAltRounded className="flight" />
																		</div>
																	</div>
																	<div className={'mr-2'}>
																		<div>
																			<p className="journeyTime m-0">{flight.ArrivalTime}</p>
																			<p className="journeyCity m-0">{flight.Destination}</p>
																		</div>
																	</div>
																</div>
															</div>

															<div className={"col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 mt-xl-0 mt-lg-0 px-s-1 px-sm-1 px-md-2"}>
																<div className="  text-left">
																	<p className="travelTime mb-2">{ flight.TotalDuration} <br/><span>{flight.stop} Stop(s) {flight.onWardsStations!==null?flight.onWardsStations:''} {flight.returnsStations!==null?flight.returnsStations:''}</span></p>
																</div>
															</div>

															<div className={"col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6 mt-s-2 mt-sm-2 mt-md-0 pr-sm-2 mt-xl-0 mt-lg-0 pl-lg-0 ticket-price"}>
																<div className=" text-left">
																	<p className="price m-0 d-inline">{parseFloat(flight.TotalFare).toLocaleString('en-IN', {
																		maximumFractionDigits: 2,
																		style: 'currency',
																		currency: 'INR'
																	})}</p>
																	<br/>
																	{refundable===true?
																	  <p className="refundableRules m-0 d-inline ">Refundable</p>
																	  :
																	  <p className="refundableRules m-0 d-inline text-danger">Non-Refundable</p>
																	}

																</div>
															</div>
														</div>
													</div>

													<div className={"col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 flight-select text-center mt-xl-3 mt-lg-3"}>
														<div className="">
															<RadioGroup
																onChange={(event)=>{

																}}
																selectedValue={this.props.activeOnwardFlightIndex}
															>
																<Radio label="" value={key} />
															</RadioGroup>
														</div>
													</div>
												</div>
											</div>
											<FlightDetails
											  searchDetails={this.props.searchDetails}
											  from={flight.Origin}
											  to={flight.Destination}
											  dataSource={flight.segment.Fare}
											  segment={flight.segment}
											  flightImage={flightImage}
											  FlightName={flight.FlightName}
											/>
                                       </div>
                                  </div>
                        });

            }else{
                return (
                    <div className="text-center">

                        <div className="row">
                            <div className="col-md-12 mt-5">
                                <p>Oops! There is no data for this Flight still you can check other Search Flight.</p>
                            </div>

                            <div className="col-md-12 mt-5">
                                <ArrowBackRounded />
                            </div>

                            <div className="col-md-12 mt-5">
                                <a href="/" className="btn btn-primary">Back</a>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}
