
export const searchFlight = (sources) => {

    return {
        type: 'SEARCH_FLIGHT',
        SEARCH_FLIGHT:sources
    }
}

export const searchDetails = (sources) => {

    return {
        type: 'SEARCH_DETAILS',
        SEARCH_DETAILS:sources
    }
}

export const searchFlightFareDetails = (sources)=>{
    return {
        type: 'FARE_DETAILS',
        FARE_DETAILS:sources
    }
}


export const allTaxes = (sources)=>{
    return {
        type: 'ALL_TAXES',
        ALL_TAXES:sources
    }
}

export const ticketDetails=(sources)=>{
    return {
        type: 'TICKET_DETAILS',
        ticketDetails:sources
    }
}

export const bookingDetails=(sources)=>{
    return {
        type: 'BOOKING_DETAILS',
        bookingDetails:sources
    }
}

export const allBookingsDetails = (sources)=>{
    return {
        type: 'ALL_BOOKINGS',
        allBookings:sources
    }
}

export const cancelDetails = (sources)=>{
    return {
        type: 'CANCEL_BOOKINGS',
        cancelBookings:sources
    }
}

export const upcomingDetails = (sources)=>{
    return {
        type: 'UPCOMING_BOOKINGS',
        upcomingBookings:sources
    }
}

export const cancellationTicketDetails = (sources)=>{
    return {
        type: 'CANCELLATION_TICKET_DETAILS',
        cancellationTicketDetails:sources
    }
}