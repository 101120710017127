import {connect} from 'react-redux';
import CancelationTrips from '../component/CancelationTrips';
import {cancelTicketDetail} from '../../../actions/action';
import {cancellationTicketDetails} from '../../../actions';
import {toaster as toast} from '../../../components/toast';

const mapStateToProps = state => {


    return {
        cancellationTicketDetails: state.booking.cancellationTicketDetails
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getBookedTicketDetailInfo: (orderId, traceId) => {
            dispatch(cancellationTicketDetails(null))
            return cancelTicketDetail(orderId,traceId)
                .then((response)=>{

                    const sources = response.data;

                    if(sources.status===true){
                        dispatch(cancellationTicketDetails(sources.data))
                        return new Promise((resolve,reject)=>{
                            resolve(sources.data)
                        });
                    }else{
                        toast.error(sources.message);
                        dispatch(cancellationTicketDetails({}));

                        return new Promise((resolve,reject)=>{
                            reject(sources)
                        });
                    }

                })
                .catch((error)=>{
                    if(error.response !== undefined){
                        const sources = error.response.data;
                        dispatch(cancellationTicketDetails({}));
                        toast.error(sources.message);
                        return new Promise((resolve,reject)=>{
                            reject(sources)
                        });
                    }

                    if (error){
                        return new Promise((resolve,reject)=>{
                            reject(error)
                        });
                    }

                    return new Promise((resolve,reject)=>{
                        reject({message:"Server Error!"})
                    });
                })

        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelationTrips);
