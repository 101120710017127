import React from 'react';
import {Checkbox, Classes, Dialog, Button,Toaster, Position, Intent} from "@blueprintjs/core";
import {ssoInstance} from "gg-react-utilities";
import { allTaxes} from '../../../actions/action';
import Config from "../../../Config";


export default class RedeemVoucher extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true,
            voucherIds: [],
            btnLoading:false,
            isApply:false
        }
       
   
    }



    handleClose = () => {
        this.setState({isOpen: false})
    }
    handleOpen = () => {

        let voucherIds=[];
        this.props.userGGVouchers.map((voucher, key) => {
            if (voucher.selected) {
                voucherIds.push(+voucher.id);    
            }
        });


        this.setState({isOpen: true,voucherIds: voucherIds})
    }
    handleRemove = () => {
       
        this.setState({ isOpen: false, isApply: !this.state.isApply})


        this.props.getAllTaxes(this.props.amount, this.props.coin)
            .then(res => {
              
                Toaster.create({
                    position: Position.TOP,
                }).show({ message: 'Appllied Voucher Remove Successfully!', intent: Intent.DANGER  });

             
            }).catch((error) => {
                Toaster.create({
                    position: Position.TOP,
                }).show({ message: 'Someting went wrong!', intent: Intent.DANGER });
            });

    }
    onCheckBoxChange(e) { 
        const options = this.state.voucherIds;

        let index
        if (e.target.checked) {
          options.push(+e.target.value)
        } else {
          index = options.indexOf(+e.target.value)
          options.splice(index, 1)
        }

        this.setState({ voucherIds: options })
      }

    redeemVoucher = () => {

        if(this.state.voucherIds.length > 0) {
            
            var x = this.state.voucherIds.toString();
            
        } else {
           
            var x = 0;
        }
       
        this.props.getAllTaxes(this.props.amount, this.props.coin, x)
       .then(res => {
           this.setState({
               isApply: true
           })
                    Toaster.create({
                        position: Position.TOP,
                    }).show({ message: 'Apply Voucher Successfully!', intent: Intent.SUCCESS });
                   
                    this.handleClose()
               
            }).catch((error) => {
                Toaster.create({
                    position: Position.TOP,
                }).show({ message: 'Someting went wrong!', intent: Intent.DANGER });
            });
  

      
    }
    render() {
        return (
            <React.Fragment>
                <div
                    className="pay-btn row"
                    style={{
                    borderBottom: '2px solid #CBCBCB'
                }}>
                    <div
                        className="col-sm-8 col-8 text-left bp3-text-xlarge heading mb-3 mt-2">
                        Apply GG Voucher
                    </div>
                    
                    <div className="col-sm-4 col-4 mt-1 text-right">
                  
                        {this.state.isApply ?
                         <button
                                    type="button"
                                className="bp3-button bp3-button-sm bp3-intent-danger"
                                    style={{
                                        minWidth: '70px',
                                        minHeight: '27px',
                                        padding: '2px 8px',
                                        fontSize: '13px'
                                    }}
                                onClick={() => this.handleRemove()}>Remove</button> : <button
                                    type="button"
                                    className="bp3-button bp3-button-sm bp3-intent-primary"
                                    style={{
                                        minWidth: '70px',
                                        minHeight: '27px',
                                        padding: '2px 8px',
                                        fontSize: '13px'
                                    }}
                                    onClick={() => this.handleOpen()}>Apply</button> }
                    </div>
                </div>
                <Dialog

                    isOpen={this.state.isOpen}
                    title={this.props.title}
                    className={this.props.className ? this.props.className : ''}
                    icon={this.props.icon ? this.props.icon : "info-sign"}
                    onClose={this.handleClose}
                    {...this.props}>
                    <h3 className="bp3-text-xlarge heading ml-5 mt-4">Apply GG Voucher</h3>
                    <div className={Classes.DIALOG_BODY + ' voucher-list-model'} style={{overflowX:'hidden',overflowY:'scroll',height:'500px'}}>
                        {
                            this.props.userGGVouchers && this.props.userGGVouchers.length > 0 ?
                                <React.Fragment>
                                    {

                                        this.props.userGGVouchers.map((voucher, key) => {
                                           

                                            return (
                                                <div className="pay-btn row" key={key}>
                                                    <div className="col-md-8 col-sm-8 col-8 text-left bp3-text-xlarge heading mb-3 mt-2">
                                                        <p>₹ {voucher.current_balance}</p>
                                                        <p>#{voucher.id} - Valid Till {voucher.valid_till}</p>
                                                    </div>
                                                    <div className="col-md-4 col-sm-8 col-8 text-right">
                                                        <Checkbox 
                                                            checked={this.state.voucherIds.includes(voucher.id)} 
                                                            //checked={voucher.selected} 
                                                            value={voucher.id} 
                                                            className="sb-chk-text" 
                                                            onChange={this.onCheckBoxChange.bind(this)}
                                                        >
                                                        </Checkbox>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </React.Fragment>
                            :
                                'You have not any voucher'
                        }
                    </div>
                    {
                        this.props.userGGVouchers && this.props.userGGVouchers.length > 0 ?
                            <React.Fragment>
                                <div className="pay-btn row mt-2 mr-4">
                                    <div className="col-sm-12 col-12 text-right">
                                        <Button intent={Intent.WARNING} onClick={() => this.handleClose()}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button loading={this.state.btnLoading} intent={Intent.PRIMARY} onClick={() => this.redeemVoucher()}>Apply</Button>
                                    </div>
                                </div>
                            </React.Fragment>
                        :
                            'You have not any voucher'
                    }
                    {this.props.footer
                        ? <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    {this.props.footer}
                                </div>
                            </div>
                        : ''
}
                </Dialog>
            </React.Fragment>
        )
    }
}