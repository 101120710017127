import React from 'react';
import {Checkbox, Button} from '@blueprintjs/core';
import {BrightnessLowRounded, BrightnessMediumRounded, Brightness3Rounded, Brightness4Rounded} from '@material-ui/icons';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import _ from 'lodash';

export default class MobileFilterComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            prices:{
                min: 100,
                max: 1000,
            },
            price_max_value:1000,
            price_min_value:100,
            airportList:[

            ],
            airlines:[],
            stopsList:[
                { value: '0', label: '0' },
                { value: '1', label: '1' },
                { value: '2', label: '2+' },
            ],
            stops:[],
            onWardDeptTime:5,
            returnDeptTime:5
        }
    }

    componentDidMount(){

        this.setState({
            airportList:this.props.airportList,
            price_max_value:this.props.price_max_value,
            price_min_value:this.props.price_min_value,
            prices:{
                min: this.props.price_min_value,
                max: this.props.price_max_value,
            },
        });
    }

    componentWillReceiveProps(nextProps){

        this.setState({
            airportList:nextProps.airportList,
            price_max_value:nextProps.price_max_value,
            price_min_value:nextProps.price_min_value,
            prices:{
                min: nextProps.prices.min,
                max: nextProps.prices.max,
            },
        });
    }

    handleStopsChange=(obj)=>{

        let stops = this.state.stops;
        let stopsList = this.state.stopsList;

        let checkStops = _.findIndex(stops,{value:obj.value});

        if (checkStops>-1) {
            _.remove(stops, function (e) {
                return e.value === obj.value;
            });
        }else{
            stops.push(obj);
        }
        // let final_stops = this.state.stops.push(obj);

        stopsList =  stopsList.map((stopsListObj)=>{
            let checkStopsList = _.findIndex(stops,{value:stopsListObj.value});
            if (checkStopsList>-1) {
                stopsListObj.checked=true;
            }else{
                stopsListObj.checked=false;
            }
            return stopsListObj;
        })

        this.setState({
            stops:stops,
            stopsList:stopsList
        },()=>{
           // this.applyFilter()
        })
    }

    handleEnabledChange=(checked,obj,index)=>{
        let airlines=this.state.airlines;
        if (checked===true) {
            airlines.push(obj);
        }else{
            _.remove(airlines, function (e) {
                return e.value === obj.value;
            });
        }
        this.setState({
            airlines:airlines
        },()=>{
           // this.applyFilter()
        })
    }

    handleonWardDeptTime=(index)=>{

        this.setState({
            onWardDeptTime:index
        },()=>{
          //  this.applyFilter()
        })
    }

    handleReturnDeptTime=(index)=>{

        this.setState({
            returnDeptTime:index
        },()=>{
         //   this.applyFilter()
        })
    }

    applyFilter=()=>{
        let filter={};
        if(this.state.airlines.length>0){
            filter.airlines=this.state.airlines;
        }else{
            filter.airlines=this.state.airportList;
        }

        if(this.state.stops.length>0){
            filter.stops=this.state.stops;
        }else{
            filter.stops=[
                { value: '0', label: 'No-Stop' },
                { value: '1', label: '1 Stops' },
                { value: '2', label: '2+ Stops' },
            ];
        }

        filter.prices=this.state.prices;

        if(this.state.onWardDeptTime===1){
            filter.onWardDeptTime='06';
        }else if(this.state.onWardDeptTime===2){
            filter.onWardDeptTime='12';
        }else if(this.state.onWardDeptTime===3){
            filter.onWardDeptTime='18';
        }else if(this.state.onWardDeptTime===4){
            filter.onWardDeptTime='00';
        }else{
            filter.onWardDeptTime='24';
        }

        if(this.state.returnDeptTime===1){
            filter.returnDeptTime='06';
        }else if(this.state.returnDeptTime===2){
            filter.returnDeptTime='12';
        }else if(this.state.returnDeptTime===3){
            filter.returnDeptTime='18';
        }else if(this.state.returnDeptTime===4){
            filter.returnDeptTime='00';
        }else{
            filter.returnDeptTime='24';
        }

        this.props.onFilter(filter);
    }

    resetFilter=()=>{

        this.setState({
            airportList: this.props.airportList,
            price_max_value: this.props.price_max_value,
            price_min_value: this.props.price_min_value,
            prices: {
                min: 0,
                max: this.props.price_max_value,
            },
            airlines:[],
            stopsList:[
                { value: '0', label: '0' },
                { value: '1', label: '1' },
                { value: '2', label: '2+' },
            ],
            stops:[],
            onWardDeptTime:5,
            returnDeptTime:5
        },()=>{
            this.applyFilter()
        })

    }

    onFilterDialog = () =>{

        this.setState({
            filterStatus:!this.state.filterStatus
        })
    }

    render() {

        return (
            <div className="col-sm-4 col-12 visible-sm visible-md visible-lg visible-xl floating-sidebar my-3 px-s-2">
                <div className="flight-sidebar320px">
                    <div className="row text-center mb-3">
                        <div className="col-6">
                            <button type="button"
                                    className="bp3-button bp3-intent-secondary bp3-button bp3-small filter-btn"
                                    onClick={() => {
                                        this.applyFilter()
                                    }}
                            >
                                <span className="bp3-button-text">Apply</span>
                            </button>
                        </div>
                        <div className="col-6 pl-sm-2">
                            <button type="button"
                                    className="text-primary bp3-button bp3-intent-primary bp3-button bp3-small filter-btn"
                                    onClick={() => {
                                        this.resetFilter()
                                    }}
                            >
                                <span className="bp3-button-text">Reset all</span>
                            </button>
                        </div>
                    </div>
                    <div className='col-12 filterSearchFlightWrapper'>
                        <div className="">
                            <div className="row d-flex align-items-center">
                                <div className="col-md-10 col-sm-9 col-9">
                                    <h3 className="sidebar-title mt-3">Comfort Fare </h3>
                                </div>
                                <div className="col-md-2 col-sm-3 col-3">
                                    <span className="bp3-icon bp3-icon-caret-down font-12 ml-2 pull-right"></span>
                                </div>
                                <div className="col-md-10 mt-4 mx-3" style={{margin:'0 auto'}}>
                                    <div className="range-one">
                                        <InputRange
                                            minValue={this.state.price_min_value}
                                            maxValue={this.state.price_max_value}
                                            formatLabel={prices => `${prices.toLocaleString('en-IN', {
                                                maximumFractionDigits: 2,
                                                style: 'currency',
                                                currency: 'INR'
                                            })}`}
                                            value={this.state.prices}
                                            onChange={prices => this.setState({prices: prices})}
                                            onChangeComplete={prices => {
                                                this.setState({
                                                    prices: prices
                                                },()=>{
                                                    //this.applyFilter()
                                                })
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix mt-5"></div>
                            <hr/>

                            <div className="modalTimeFilterWrapper">
                                <div className="row modalTimeFilterTitle">
                                    <div className="col-md-10 col-sm-9 col-9">
                                        <h3 className="sidebar-title">Deprature from {this.props.searchDetails.to} </h3>
                                    </div>
                                    <div className="col-md-2 col-sm-3 col-3">
                                        <span className="bp3-icon bp3-icon-caret-down font-12 ml-2 pull-right"></span>
                                    </div>
                                </div>
                                <ul className="m-0 p-0">
                                    <li className={this.state.onWardDeptTime === 1 ? 'row modalTimeFilter  p-0 m-0   active' : 'modalTimeFilter'}
                                        onClick={() => this.handleonWardDeptTime(1)}>
                                        <div className="row">
                                            <div className={"col-12"}>
                                                <div className="d-flex py-s-2 py-sm-1 ml-2">
                                                    <BrightnessMediumRounded
                                                        transform="rotate(270)"
                                                    />
                                                    <p className="ml-s-3 ml-sm-2 modalTimeFilterText">6AM -12PM</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="modalTimeFilter p-0 m-0"
                                        onClick={() => this.handleonWardDeptTime(2)}>
                                        <div className={this.state.onWardDeptTime === 2 ? 'row active' : 'row'}>
                                            <div className={"col-12"}>
                                                <div className="d-flex py-s-2 py-sm-1 ml-2">
                                                    <BrightnessLowRounded
                                                        transform="rotate(270)"
                                                    />
                                                    <p className="ml-s-3 ml-sm-2 modalTimeFilterText">12PM - 6PM</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="modalTimeFilter p-0 m-0"
                                        onClick={() => this.handleonWardDeptTime(3)}>
                                        <div
                                            className={this.state.onWardDeptTime === 3 ? 'col-12 row active pr-sm-0' : 'col-12 row pr-sm-0'}>
                                            <div className="d-flex py-s-2 py-sm-1 ml-2">
                                                <Brightness4Rounded />
                                                <p className="ml-s-3 ml-sm-2 modalTimeFilterText">6PM - 12AM</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="modalTimeFilter p-0 m-0"
                                        onClick={() => this.handleonWardDeptTime(4)}>
                                        <div
                                            className={this.state.onWardDeptTime === 4 ? 'col-12 row active pr-sm-0' : 'col-12 row pr-sm-0'}>
                                            <div className="d-flex py-s-2 py-sm-1 ml-2">
                                                <Brightness3Rounded />
                                                <p className="ml-s-3 ml-sm-2 modalTimeFilterText">12AM - 6AM</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <hr/>

                            <div className="modalTimeFilterWrapper">
                                <div className="row modalTimeFilterTitle">
                                    <div className="col-md-10 col-sm-9 col-9">
                                        <h3 className="sidebar-title">Arival at {this.props.searchDetails.from} </h3>
                                    </div>
                                    <div className="col-md-2 col-sm-3 col-3">
                                        <span className="bp3-icon bp3-icon-caret-down font-12 ml-2 pull-right"></span>
                                    </div>
                                </div>
                                <ul className="m-0 p-0">
                                    <li className="modalTimeFilter p-0 m-0"
                                        onClick={() => this.handleReturnDeptTime(1)}>
                                        <div
                                            className={this.state.returnDeptTime === 1 ? 'col-12 row active pr-sm-0' : 'col-12 row pr-sm-0'}>
                                            <div className="d-flex py-s-2 py-sm-1 ml-2">
                                                <BrightnessMediumRounded
                                                    transform="rotate(270)"
                                                />
                                                <p className="ml-s-3 ml-sm-2 modalTimeFilterText">6AM - 12PM</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="modalTimeFilter p-0 m-0"
                                        onClick={() => this.handleReturnDeptTime(2)}>
                                        <div
                                            className={this.state.returnDeptTime === 2 ? 'col-12 row active pr-sm-0' : 'col-12 row pr-sm-0'}>
                                            <div className="d-flex py-s-2 py-sm-1 ml-2">
                                                <BrightnessLowRounded
                                                    transform="rotate(270)"
                                                />
                                                <p className="ml-s-3 ml-sm-2 modalTimeFilterText">12PM - 6PM</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="modalTimeFilter p-0 m-0"
                                        onClick={() => this.handleReturnDeptTime(3)}>
                                        <div
                                            className={this.state.returnDeptTime === 3 ? 'col-12 row active pr-sm-0' : 'col-12 row pr-sm-0'}>
                                            <div className="d-flex py-s-2 py-sm-1 ml-2">
                                                <Brightness4Rounded />
                                                <p className="ml-s-3 ml-sm-2 modalTimeFilterText">6PM - 12AM</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="modalTimeFilter p-0 m-0"
                                        onClick={() => this.handleReturnDeptTime(4)}>
                                        <div
                                            className={this.state.returnDeptTime === 4 ? 'col-12 row active pr-sm-0' : 'col-12 row pr-sm-0'}>
                                            <div className="d-flex py-s-2 py-sm-1 ml-2">
                                                <Brightness3Rounded />
                                                <p className="ml-s-3 ml-sm-2 modalTimeFilterText">12AM - 6AM</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <hr/>

                            <div className="modalTimeFilterWrapper">
                                <div className="row modalTimeFilterTitle">
                                    <div className="col-md-10 col-sm-9 col-9">
                                        <h3 className="sidebar-title">Flight Stop </h3>
                                    </div>
                                    <div className="col-md-2 col-sm-3 col-3">
                                        <span className="bp3-icon bp3-icon-caret-down font-12 ml-2  pull-right"></span>
                                    </div>
                                </div>
                                <div className={"flightstops"}>
                                    <div className="modalTimeFilter mt-3">
                                        {
                                            this.state.stopsList.map((stops, index) => {
                                                return (
                                                    <Button
                                                        key={index}
                                                        className={stops.checked === true ? 'bp3-button bp3-button fligthstop-btn active' : 'bp3-button bp3-button fligthstop-btn'}
                                                        onClick={() => {
                                                            this.handleStopsChange(stops)
                                                        }}
                                                    >
                                            <span className="bp3-button-text stop-btn-text">
                                                {stops.label}
                                            </span>
                                                    </Button>

                                                )
                                            })
                                        }
                                    </div>
                                 </div>
                            </div>
                            <hr/>

                            <div className="modalTimeFilterWrapper">
                                <div className="row modalTimeFilterTitle">
                                    <div className="col-md-10 col-sm-9 col-9">
                                        <h3 className="sidebar-title">Airlines </h3>
                                    </div>
                                    <div className="col-md-2 col-sm-3 col-3">
                                        <span className="bp3-icon bp3-icon-caret-down font-12 ml-2  pull-right"></span>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    {
                                        this.state.airportList.map((obj, index) => {

                                            return (
                                                <Checkbox key={index} checked={obj.checked} label={obj.label}
                                                          onChange={(e) => {
                                                              this.handleEnabledChange(e.target.checked, obj, index)
                                                          }}>

                                                </Checkbox>
                                            )
                                        })
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
