import {connect} from 'react-redux';
import { toaster as toast} from '../../../components/toast';
import {bookedTicketDetail} from '../../../actions/action';
import * as actions from '../../../actions';
import TicketsComponent from '../component/TicketsComponent';

const mapStateToProps = state => {

    return{
        bookingDetails:state.flightPayment.bookingDetails,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        bookedTicketDetail:(orderId,traceId)=>{
        return  bookedTicketDetail(orderId,traceId)
          .then((response)=>{

              const sources = response.data;

              if(sources.status===true){
                  dispatch(actions.bookingDetails(sources.data))
                  return new Promise((resolve,reject)=>{
                    resolve(sources.data)
                  });
              }else{
                  toast.error(sources.message);
                  dispatch(actions.bookingDetails(sources.data));
                  return new Promise((resolve,reject)=>{
                    reject(response)
                  });
              }

          })
            .catch((error)=>{
              if(error.response !== undefined){
                  const sources = error.response.data;
                  dispatch(actions.bookingDetails(sources.data));
                  toast.error(sources.message);
                  return new Promise((resolve,reject)=>{
                      reject(error.response.data)
                  });
              }

              if (error){
                  return new Promise((resolve,reject)=>{
                      reject(error.data)
                  });
              }

                return new Promise((resolve,reject)=>{
                    reject({message:"Server Error!"})
                });
          })
      }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketsComponent);
