import React, {Component} from 'react';
import Config from '../../../../Config';
import {
  ArrowRightAltRounded
} from '@material-ui/icons';

class FlightInfo extends Component {
    render() {
        return (
            <div className="reviewItinery">
              <div className="flight-bookingdetails bg-white">
                {
                  this.props.segment.Bonds.map((bonds,i)=>{ 

                    let refund='Refundable';

                    this.props.segment.Fare.PaxFares.map((paxFares,j)=>{
                      
                      if(!paxFares.Refundable){ refund='Non Refundable'; }
                        
                    });

                    
                      return (
                        <React.Fragment>
                          <div className={bonds.BoundType === "OutBound"?'OutBound':'InBound'}>
                            {
                              bonds.Legs.map((legs,li)=>{
                                  let flightImage= Config.IMAGE_URL+legs.AirlineName+'.png';

                                  return (
                                      <div key={i}
                                           className="col-12 reviewItineryFlightList mt-3 pr-sm-0 pr-md-3">
                                          <div className="row">
                                              <div
                                                  className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-2 text-left px-s-0 px-sm-0">
                                                  <img alt={legs.FlightName} title={legs.FlightName}
                                                       src={flightImage} className="flightCompanyLogo rounded-circle" />
                                              </div>
                                              <div
                                                  className="col-xl-2 col-lg-2 col-md-3 col-sm-2 col-10 text-left px-sm-2 px-md-3">
                                                  <p className="travelTime mt-2 mb-0">{legs.Duration}, 0 stop<br/><span
                                                      className="travelTime mt-0 mb-2">{legs.AirlineName}-{legs.FlightNumber}</span>
                                                  </p>

                                              </div>
                                              <div
                                                  className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12 text-center mt-s-3 mt-sm-2 px-s-0 px-sm-0 px-md-3">
                                                  <div
                                                      className="col row align-items-top mb-1 px-s-0 px-sm-0">
                                                      <div
                                                          className="col text-left pl-s-3 pr-s-1 pl-sm-4 pr-sm-1 pl-md-5 pr-md-3">
                                                          <p className="journeyCity m-0">{legs.Origin} <span
                                                              className="journeyTime m-0">{legs.DepartureTime}</span>
                                                          </p>
                                                          <p className="journeyDate m-0 mt-2 mt-s-3">{legs.DepartureDate}</p>
                                                          <p className="airportName">Terminal {legs.DepartureTerminal}</p>
                                                      </div>
                                                      <div className="col text-center px-s-0 px-sm-0 px-md-2">
                                                          <div className="flightIconWrapper d-inline">
                                                              <p className="flighttravelTime mb-0 mt-1">{legs.Duration} | 0 stop</p>

                                                              {
                                                                  refund == 'Refundable'?
                                                                      <p className="flight-status mt-md-2 mt-lg-2 mt-xl-2 mt-s-3 mt-sm-3">{refund}</p>
                                                                      :
                                                                      <p className="flight-status mt-md-2 mt-lg-2 mt-xl-2 mt-s-3 mt-sm-3 text-danger">{refund}</p>
                                                              }


                                                          </div>
                                                      </div>
                                                      <div
                                                          className="col text-right pl-s-1 pr-s-0 pl-sm-1 pr-sm-0 pl-md-4 pr-md-3 pr-lg-4 pr-xl-4">
                                                          <p className="journeyCity m-0 text-right-xs">{legs.Destination}
                                                              <span
                                                                  className="journeyTime m-0 ml-1">{legs.ArrivalTime}</span>
                                                          </p>
                                                          <p className="journeyDate m-0 mt-2 mt-s-3 text-right-xs">{legs.ArrivalDate}</p>
                                                          <p className="airportName text-right-xs">Terminal {legs.ArrivalTerminal}</p>
                                                      </div>
                                                  </div>
                                                  <p className="two-dots col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12"></p>
                                                  <div className="col-12">
                                                      <p className="transitTime text-center">Transit time
                                                          : {legs.Duration}</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  );
                                  return(
                                      <div key={li} className="reviewItineryFlightList">
                                          <div className="col-12 text-center">
                                              <img alt={this.props.FlightName} title={this.props.FlightName} src={flightImage} className="flightCompanyLogo" />
                                          </div>
                                          <div className="col-12 text-center">
                                              <p className="travelTime mt-2">{legs.Duration}, {bonds.Legs.length - 1} stop</p>
                                              <p className="travelTime mt-2">{legs.AirlineName}-{legs.FlightNumber}</p>

                                          </div>
                                          <div className="row align-items-center mb-1">
                                              <div className="col text-left">
                                                  <p className="journeyCity m-0">{legs.Origin}</p>
                                                  <p className="journeyTime m-0">{legs.DepartureTime}</p>
                                              </div>
                                              <div className="col text-center">
                                                  <div className="flightIconWrapper d-inline">
                                                      <ArrowRightAltRounded className="flight" />
                                                  </div>
                                              </div>
                                              <div className="col text-right">
                                                  <p className="journeyCity m-0">{legs.Destination}</p>
                                                  <p className="journeyTime m-0">{legs.ArrivalTime}</p>
                                              </div>
                                          </div>
                                          <div className="row align-items-center">
                                              <div className="col text-left">
                                                  <p className="journeyDate m-0">{legs.DepartureDate}</p>
                                              </div>
                                              <div className="col text-right">
                                                  <p className="journeyDate m-0">{legs.ArrivalDate}</p>
                                              </div>
                                          </div>
                                          <div className="row justify-content-between">
                                              <div className="col-6 col-md-4 text-left">
                                                  <p className="airportName mt-2">Terminal {legs.DepartureTerminal}</p>
                                              </div>
                                              <div className="col-6 col-md-4 text-right">
                                                  <p className="airportName mt-2">Terminal {legs.ArrivalTerminal}</p>
                                              </div>

                                          </div>
                                          <div className="col-12">
                                              <p className="transitTime text-center">Transit time : {legs.Duration}</p>
                                          </div>
                                      </div>
                                  )
                              })
                            }
                          </div>
                        </React.Fragment>
                      )


                  })
                }
              </div>
            </div>
        );
    }
}

export default FlightInfo;
