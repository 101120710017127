import React,{ Component } from 'react';
import TicketsContainer from './container/TicketsContainer';

export default class Tickets extends Component{

    componentDidMount(){
        setTimeout(()=>{
            window.scrollTo(0,0)
        },3000)
    }

    render(){

        return(
            <TicketsContainer {...this.props} />
        )
    }
}
