import React,{Component} from 'react';

export default class FareDetails extends Component{

    constructor(props){
        super(props);
        this.state={
            amountDetails:{
                adult:{Amount:0,count:0},
                childs:{Amount:0,count:0},
                infrant:{Amount:0,count:0}
            },
            Fare:'',
        }

    }

    componentDidMount(){
        let amountDetails={
            adult:{Amount:0,count:0},
            childs:{Amount:0,count:0},
            infrant:{Amount:0,count:0}
        };

        let Fare={
            BasicFare:0,
            TotalTaxWithOutMarkUp:0,
            TotalFareWithOutMarkUp:0
        };

        Fare.BasicFare = parseFloat(this.props.dataSource.BasicFare);
        Fare.TotalTaxWithOutMarkUp = parseFloat(this.props.dataSource.TotalTaxWithOutMarkUp);
        Fare.TotalFareWithOutMarkUp = parseFloat(this.props.dataSource.TotalFareWithOutMarkUp);

        this.props.dataSource.PaxFares.map((fare,i)=>{
            if(fare.PaxType===0){
                let amount=(fare.BasicFare*this.props.searchDetails.adults) + amountDetails.adult.Amount;
                amountDetails.adult={Amount:amount,count:this.props.searchDetails.adults}
            }else if(fare.PaxType===1){
                let amount=(fare.BasicFare*this.props.searchDetails.childs) + amountDetails.childs.Amount;
                amountDetails.childs={Amount:amount,count:this.props.searchDetails.childs}
            }else if(fare.PaxType===2){
                let amount=(fare.BasicFare*this.props.searchDetails.infants) + amountDetails.infrant.Amount;
                amountDetails.infrant={Amount:amount,count:this.props.searchDetails.infants}
            }
            return fare;
        })

        this.setState({
            Fare:Fare,
            amountDetails:amountDetails
        })
    }

    componentWillReceiveProps(nextProps){

        let amountDetails={
            adult:{Amount:0,count:0},
            childs:{Amount:0,count:0},
            infrant:{Amount:0,count:0}
        };

        let Fare={
            BasicFare:0,
            TotalTaxWithOutMarkUp:0,
            TotalFareWithOutMarkUp:0
        };

        Fare.BasicFare = parseFloat(nextProps.dataSource.BasicFare);
        Fare.TotalTaxWithOutMarkUp = parseFloat(nextProps.dataSource.TotalTaxWithOutMarkUp);
        Fare.TotalFareWithOutMarkUp = parseFloat(nextProps.dataSource.TotalFareWithOutMarkUp);

        this.props.dataSource.PaxFares.map((fare,i)=>{
            if(fare.PaxType===0){
                let amount=(fare.BasicFare*nextProps.searchDetails.adults) + amountDetails.adult.Amount;
                amountDetails.adult={Amount:amount,count:nextProps.searchDetails.adults}
            }else if(fare.PaxType===1){
                let amount=(fare.BasicFare*nextProps.searchDetails.childs) + amountDetails.childs.Amount;
                amountDetails.childs={Amount:amount,count:nextProps.searchDetails.childs}
            }else if(fare.PaxType===2){
                let amount=(fare.BasicFare*nextProps.searchDetails.infants) + amountDetails.infrant.Amount;
                amountDetails.infrant={Amount:amount,count:nextProps.searchDetails.infants}
            }
        })

        this.setState({
            Fare:Fare,
            amountDetails:amountDetails
        })
    }

    render(){

        return(
            <div className="card">
                <div className="card-body">
                    <div className="row justify-content-center">

                        <div className="col-12 ">
                            <div className="border-bottom-1 mb-3">
                                <div className="row">
                                    <div className="col-6 totalpaymentColumn text-left">
                                        <p className="farelable"> {this.state.amountDetails.adult.count} x Adult</p>
                                    </div>
                                    <div className="col-6 totalpaymentColumn text-right">
                                        <p className="farePrice">
                                            + {parseFloat(this.state.amountDetails.adult.Amount).toLocaleString('en-IN', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'INR'
                                        })}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom-1 mb-3">
                                <div className="row">
                                    <div className="col-6 totalpaymentColumn text-left">
                                        <p className="farelable"> {this.state.amountDetails.childs.count} x Childs</p>
                                    </div>
                                    <div className="col-6 totalpaymentColumn text-right">
                                        <p className="farePrice">
                                            + {parseFloat(this.state.amountDetails.childs.Amount).toLocaleString('en-IN', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'INR'
                                        })}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom-1 mb-3">
                                <div className="row">
                                    <div className="col-6 totalpaymentColumn text-left">
                                        <p className="farelable"> {this.state.amountDetails.infrant.count} x Infants</p>
                                    </div>
                                    <div className="col-6 totalpaymentColumn text-right">
                                        <p className="farePrice">
                                            + {parseFloat(this.state.amountDetails.infrant.Amount).toLocaleString('en-IN', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'INR'
                                        })}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom-1 mb-3">
                                <div className="row">
                                    <div className="col-6 totalpaymentColumn text-left">
                                        <p className="farelable">Total (Base fare)</p>
                                    </div>
                                    <div className="col-6 totalpaymentColumn text-right">
                                        <p className="farePrice">
                                            + {parseFloat(this.state.Fare.BasicFare).toLocaleString('en-IN', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'INR'
                                        })}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom-1 mb-3">
                                <div className="row">
                                    <div className="col-6 totalpaymentColumn text-left">
                                        <p className="farelable">Total Tax</p>
                                    </div>
                                    <div className="col-6 totalpaymentColumn text-right">
                                        <p className="farePrice">
                                            + {parseFloat(this.state.Fare.TotalTaxWithOutMarkUp).toLocaleString('en-IN', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'INR'
                                        })}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="border-bottom-1 mb-3">
                                <div className="row">
                                    <div className="col-6 totalpaymentColumn text-left">
                                        <p className="farelable">Grand Total </p>
                                    </div>
                                    <div className="col-6 totalpaymentColumn text-right">
                                        <p className="farePrice">
                                            + {parseFloat(this.state.Fare.TotalFareWithOutMarkUp).toLocaleString('en-IN', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'INR'
                                        })}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
