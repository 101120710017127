import React from 'react';
import {Classes, Dialog} from "@blueprintjs/core";
import {Auth, Loader, NoLogin} from 'gg-react-utilities';
import FlightRight2LeftIcons from '../../../images/flight-right2left.svg';
import FlightLeft2RightIcons from '../../../images/plane-left2right.svg';
import CancelImage from '../../../images/cancel.svg';
import TopBar from "../../../components/topBar";
import Config from '../../../Config';

export default class TicketsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            loading: true,
            isError: false,
            bookingDetails: null
        }
    }

    async componentDidMount() {

        let search = this.props.history.location.search.substring(1);
        let data = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
            return key === "" ? value : decodeURIComponent(value)
        })

        if (data.booking_id && data.traceId) {
            if (await Auth.isLoggedInAsync()) {
                this.props.bookedTicketDetail(parseInt(data.booking_id), data.traceId)
                    .then(async (response) => {
                        this.setState({
                            isLoggedIn: await Auth.isLoggedInAsync(),
                            loading: false,
                            isError: false,
                            errorMessage: ''
                        })

                    })
                    .catch(async (error) => {
                        this.setState({
                            isLoggedIn: await Auth.isLoggedInAsync(),
                            loading: false,
                            isError: true,
                            errorMessage: error.message
                        })
                    });
            } else {
                this.setState({
                    isLoggedIn: await Auth.isLoggedInAsync(),
                    loading: false,
                    isError: false,
                    errorMessage: ''
                })
            }
        }
    }

    onCancel() {

        let search = this.props.history.location.search;
        this.props.history.push({
            pathname: '/cancel-ticket-details',
            search: search
        });
    }

    render() {


        if (this.state.loading === true) {

            return (
                <React.Fragment>
                    <div className="container">
                        <TopBar title={"Ticket Details"} {...this.props}/>
                        <Loader/>
                    </div>
                </React.Fragment>
            )
        }

        if (!this.state.isLoggedIn) {

            return(
                <div className="container">
                    <TopBar {...this.props} title={"Ticket Details"}/>
                    <div className="row justify-content-center mb-5 mt-3">
                        <NoLogin/>
                    </div>
                </div>
            )
        }

        if (this.state.isError === true) {

            return (
                <div className="container">
                    <TopBar title={"Ticket Details"} {...this.props}/>
                    <div className={"mh-100"}>
                        <Dialog
                            isOpen={true}
                            lazy={false}
                            className="bp3-dialog-large payment-status"
                            canOutsideClickClose={false}
                        >
                            <div className="mg-brand-payments">
                                <div className="paymentWrapper mb-lg-3">
                                    <div className="upperPaymentWrapper"></div>
                                    <div className="middlePaymentWrapper">
                                        <div className="circleWrapper">
                                            <div className="iconWrapper">
                                                <img src={CancelImage} alt="cancel"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8 lowerPaymentWrapper m-auto">
                                        <p>{this.state.errorMessage}</p>
                                        <a className="bp3-button bp3-intent-primary mt-2" href={'#allBooking'}
                                           onClick={() => {
                                               this.props.history.push('/booking-history')
                                           }}>Go To History</a>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            )
        }

        return (
            <div className="container">
                <TopBar title={"Ticket Details"} {...this.props}/>
                <div className={"mh-100"}>
                    <Dialog
                        isCloseButtonShown={true}
                        isOpen={true}
                        className="bp3-dialog bp3-dialog-large ticket-reviewItinery"
                        lazy={true}
                    >
                        <div
                            className={Classes.DIALOG_BODY + ' flight-ticket-bookingdetails px-4 py-1 m-0 d-flex px-s-3'}>
                            <div className="col-12 mt-3 px-4 px-s-0">
                                <div className="">
                                    <div className="row mb-1 pt-3">
                                        {
                                            this.props.bookingDetails !== null ?
                                                this.props.bookingDetails.trip.map((flight, key) => {

                                                    return (
                                                        <div
                                                            className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3"}
                                                            key={key}>
                                                            <div className={"row"}>
                                                                <div className={"col-xl-12 col-lg-12 col-sm-6"}>
                                                                    <h6 className={"onward-details mb-3 text-left"}>{key === 0 ? 'Onward Details' : 'Return Details'}</h6>
                                                                </div>
                                                            </div>
                                                            <div className={"row no-gutters flight-stationname"}>
                                                                <div
                                                                    className={"city-name col-xl-4 col-lg-4 col-sm-5 col-5"}>
                                                                    <p className={"mb-2 m-s-0 flight-station"}>{flight.origin}</p>
                                                                    <p className={"place-names"}>{flight.origin_city}</p>
                                                                    <p className={"date-time"}>{flight.origin_time} {flight.origin_date}</p>
                                                                </div>
                                                                <div
                                                                    className={" city-name col-xl-4 col-lg-4 col-sm-2 col-2 px-s-2"}>
                                                                    <p className={"m-2 m-s-0 px-s-0 text-center"}>
                                                                        {
                                                                            key === 0 ?
                                                                                <img src={FlightLeft2RightIcons}
                                                                                     className={"flightIcon"} alt='flight'/>
                                                                                :
                                                                                <img src={FlightRight2LeftIcons}
                                                                                     className={"flightIcon"} alt='flight'/>
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className={" city-name col-xl-4 col-lg-4 col-sm-5 col-5 text-right"}>
                                                                    <p className={"mb-2 m-s-0 flight-station"}> {flight.destination}  </p>
                                                                    <p className={"place-names"}>{flight.destination_city}</p>
                                                                    <p className={"date-time"}>{flight.destination_time} {flight.destination_date}</p>
                                                                </div>
                                                            </div>
                                                            <div className={"flight-times row"}>
                                                                <div className={"flight-times col-xl-12 col-lg-12"}>
                                                                    <p className={"text-center flight-times"}>Flight
                                                                        time: {flight.journeyTime}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <span className="bp3-icon bp3-icon-cross mt-2 pull-right"
							  onClick={() => {
								  this.props.history.push('/booking-history')
							  }}
                            >
                          </span>
                        </div>
                        <div className={' flight-ticket-passangerdetails px-5 pt-4 pb-1 m-0 px-s-3'}>
                            <div className=" mt-3 mb-5">
                                <div className="">
                                    <div className="row mb-1">
                                        <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pull-left"}>
                                            <div className={"row"}>
                                                <div className={"pass-name col-xl-6 col-lg-6  col-sm-6 col-8"}>
													<h6 className="pass-title bp3-text-medium">Passenger Name</h6>
                                                    {
                                                        this.props.bookingDetails.passenger.map((passenger, key) => {

                                                            return (
                                                                <p key={key}>{passenger.title + ' ' + passenger.firstName + ' ' + passenger.lastName}</p>)
                                                        })
                                                    }
                                                </div>
                                                <div
                                                    className={"jet-logo col-xl-6 col-lg-6  col-sm-6 col-4 text-center"}>
                                                    <img
                                                        src={Config.IMAGE_URL + this.props.bookingDetails.airlineLogo + '.png'} alt='flight logo'/>
                                                    <h6>{this.props.bookingDetails.flightName}</h6>
                                                </div>
                                            </div>
                                            {
                                                this.props.bookingDetails.flydata.map((flydata, key) => {

                                                    return (
                                                        <React.Fragment key={key}>
                                                            <div className={"owner-title mt-2"}>
                                                                <h5>{key === 0 ? 'Onwards Details' : 'Returns Details'}</h5>
                                                            </div>
                                                            <div className={"row"}>
                                                                <div
                                                                    className={"seat col-xl-4 col-lg-4  col-sm-4 col-4 border-right"}>
                                                                    <h6>PNR No</h6>
                                                                    <h5>{flydata.pnr ? flydata.pnr : '-'}</h5>
                                                                    <p className={"trip-id mt-4"}>Trip ID</p>
                                                                    <p className={"trip-number"}> {flydata.ticketNumber ? flydata.ticketNumber : '-'} </p>
                                                                </div>
                                                                <div
                                                                    className={"seat col-xl-4 col-lg-4 col-sm-4 col-4 border-right gate-class"}>
                                                                    <h6>Gate</h6>
                                                                    <h5>{flydata.departure_terminal ? flydata.departure_terminal : '-'}</h5>
                                                                    <p className={"trip-id mt-4"}>Class</p>
                                                                    <p className={"trip-number"}> {flydata.class} </p>
                                                                </div>
                                                                <div
                                                                    className={"seat col-xl-4 col-lg-4 col-sm-4 col-4 "}>
                                                                    <h6>Flight No</h6>
                                                                    <h5>{flydata.flight_number}</h5>
                                                                    <p className={"trip-id mt-4"}>Journey Date</p>
                                                                    <p className={"trip-number"}> {flydata.flight_date} </p>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS + ' mt-3'}>
                                <div className={"awaiting col-xl-6 col-sm-5 col-12"}>
                                    <p className="flightConfirmedText mt-2 mb-0 text-uppercase"
                                       style={{color: this.props.bookingDetails.order_status_color}}>{this.props.bookingDetails.status_text}</p>
                                </div>
                                <div className={"col-xl-6 col-sm-7 col-12 text-right payment"}>
                                    {
                                        this.props.bookingDetails.invoice_download_link !== null ?
                                            <a className="bp3-button bp3-intent-primary mr-3 rounded-0" title="Download" href={this.props.bookingDetails.invoice_download_link}>Download</a>
                                            :
                                            ''
                                    }
                                    {
                                        parseInt(this.props.bookingDetails.status) === 2 || parseInt(this.props.bookingDetails.status) === 4 ?
                                            <button type="button" className=" btn2-primary" onClick={() => {
                                                this.onCancel()
                                            }}>Cancel Ticket</button>
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        )

    }
}
