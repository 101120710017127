import React, {Component} from 'react';
import { Classes, Dialog } from "@blueprintjs/core";

export default class FlightDetailModal extends Component{

    constructor(props){
        super(props);

        this.state={
          autoFocus: true,
          canEscapeKeyClose: true,
          canOutsideClickClose: true,
          enforceFocus: true,
          isOpen: false,
          usePortal: true,
        }
    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      },()=>{
          if(this.props.onClose){
              this.props.onClose()
          }
      })
    }

    handleOpen=()=>{
      this.setState({
        isOpen: true
      })
    }

    componentWillReceiveProps(nextprops){
        if(nextprops.isOpen!==undefined){
            this.setState({
                isOpen: nextprops.isOpen
            });
        }

    }

    render(){
        return(
            <React.Fragment>
				{
					this.props.toggle?
					this.props.toggle:
						<a className="flight-amenitiesdec" onClick={() => this.handleOpen()}>Flight Details</a>
				}
				<Dialog
					isOpen={this.state.isOpen}
					title={this.props.title}
					className={this.props.className?this.props.className:''}
					onClose={this.handleClose}
					{...this.props}
					>
					<div className={Classes.DIALOG_BODY+' p-1 m-0'} >
						{this.props.children}
					</div>
					{
						this.props.footer?
							<div className={Classes.DIALOG_FOOTER}>
								<div className={Classes.DIALOG_FOOTER_ACTIONS}>
									{this.props.footer}
								</div>
							</div>
						:
						''
					}
             </Dialog>
            </React.Fragment>
        );
    }

}
