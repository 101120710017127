import React, {Component} from 'react';
import Config from '../../../Config';
import {
    ArrowRightAltRounded,
    ArrowBackRounded
} from '@material-ui/icons';
import FlightDetails from "./MobileFlightDetails";

export default class MobileRoundTripFlightListComponent extends Component {

    /* START HERE -  CLASS CONSTRUCTOR TO GER PROPERTITY FROM PARENT CLASS AND SET STATE OF THIS CLASS */
    constructor(props) {
        super(props);
        this.state = {
            dataSource:[],
            onwardsList:[],
            returnsList:[],
        };
    }

    componentDidMount() {
        this.setState({
            dataSource:this.props.dataSource,
            onwards:this.props.onwards,
            returns:this.props.returns,
        });
    }

    componentWillReceiveProps(nexrProps){
        if(parseInt(nexrProps.modelId,10) !== parseInt(this.props.modelId,10)){

            this.contentLoaded =0;
        }
    }

    flightOnClick=(segment,key)=>{
        this.props.onClick(segment,key);
    }

    render(){
        if (!this.props.dataSource) {

            return (
                <div>
                    Loading....
                </div>

            );

        }else {

            if(this.props.dataSource.length>0){
                return this.props.dataSource.map((flight,key)=>{

                    let flightImage = Config.IMAGE_URL+flight.AirlineName+'.png';

                    return <div className="flight-searchlist320px mt-2"  key={key} >
                        <div className={this.props.activeOnwardFlightIndex===key?'selectedFlightCard py-s-2 px-s-1 py-sm-2 px-sm-1':'py-s-2 px-s-1 py-sm-2 px-sm-1'} onClick={()=> this.flightOnClick(flight.segment,key) }>
                            <div className="row align-items-center mt-xl-3 mt-lg-3 mt-md-2">
                                <div className="col-3 text-left pl-s-3 pr-s-1 pl-sm-3 pr-sm-1 mb-1">
                                    <img alt="" src={flightImage} title={flight.AirlineName} className="flightCompanyLogo m-auto pr-3" />
                                    <div className="flight-name">
                                        <p className="fnumber">({flight.FlightNumber})</p>
                                    </div>
                                </div>
                                <div className="col-9 text-left">
                                    <div className={'row float-left pl-s-2 pr-s-3 pl-sm-2 pr-sm-3'}>
                                        <div className={'mr-s-1 mr-sm-1'}>
                                            <p className="journeyTime m-0">{flight.DepartureTime}</p>
                                            <p className="journeyCity m-0">{flight.Origin}</p>
                                        </div>
                                        <div className={'mr-s-1 mr-sm-1'}>
                                            <ArrowRightAltRounded className="flight" />
                                        </div>
                                        <div className={''}>
                                            <p className="journeyTime m-0">{flight.ArrivalTime}</p>
                                            <p className="journeyCity m-0">{flight.Destination}</p>
                                        </div>
                                        <p className="travelTime">{ flight.TotalDuration} | {flight.stop} Stop(s)</p>
                                    </div>
                                </div>
                                <div className="col-1 text-right mb-2 pl-0 mb-md-0 flight-ticketchoice">

                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-12 text-left">
                                    <p className="price m-0 text-center mb-2">{parseFloat(flight.TotalFare).toLocaleString('en-IN', {
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'INR'
                                    })}
                                        {
                                            flight.refundable === true?
                                                <span className="refundableRules text-success">| Refundable</span>
                                                :
                                                <span className="refundableRules text-danger">| Non-Refundable</span>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                         <div className="py-s-2 px-s-1 py-sm-2 px-sm-1 border-top">
                            <div className="row align-items-center">
                                <div className="col-5 text-left pr-s-0 pr-sm-0">
                                    <FlightDetails
                                        searchDetails={this.props.searchDetails}
                                        from={flight.Origin}
                                        to={flight.Destination}
                                        dataSource={flight.segment.Fare}
                                        segment={flight.segment}
                                        flightImage={flightImage}
                                        FlightName={flight.FlightName}
                                    />
                                </div>
                                <div className="col-7 text-center pl-s-0 pl-sm-1 pr-sm-2">
                                    <p className="freeMeal">{flight.segment.SSDetails}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                });

            }else{
                return (
                    <div className="text-center">

                        <div className="row">
                            <div className="col-md-12 mt-5">
                                <p>Oops! There is no data for this Flight still you can check other Search Flight.</p>
                            </div>

                            <div className="col-md-12 mt-5">
                                <ArrowBackRounded />
                            </div>

                            <div className="col-md-12 mt-5">
                                <a href="/" className="btn btn-primary">Back</a>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}
