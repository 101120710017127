import {combineReducers} from 'redux';
import reducerGetCity from './reducerGetCity';
import searchFlight from './searchFlightData';
import flightPayment from './flightPayment';
import booking from './booking';

const rootReducers = combineReducers({
  reducerGetCity,
  searchFlight,
  flightPayment,
  booking
});

// export default rootReducers;
export default rootReducers;
