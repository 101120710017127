import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import { Auth, NoLogin, Loader } from 'gg-react-utilities';
import {
    SwapHorizRounded
} from '@material-ui/icons';
import _ from "lodash";
import { Button, Collapse, Intent, Card } from "@blueprintjs/core";
import { toaster as toast } from '../../../components/toast';
import Config from '../../../Config';
import RedeemVoucher from "./RedeemVoucher";
import moment from "moment";
import TopBar from '../../../components/topBar';
import { DateInput } from "@blueprintjs/datetime";
import { bookingBeforePayment, getSignature, bookingAfterPayment } from '../../../actions/action';

export default class FlightFare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isContactCollapse: false,
            person: null,
            isLoggedIn: null,
            mobile: '',
            email: '',
            isGstCollapse: true,
            isProcessedPayNowLoader: false,
            amountDetails: {
                adult: { Amount: 0, count: 0 },
                childs: { Amount: 0, count: 0 },
                infrant: { Amount: 0, count: 0 }
            },
            Fare: {
                BasicFare: 0,
                TotalTaxWithOutMarkUp: 0,
                TotalFareWithOutMarkUp: 0
            },
            onWards: null,
            returns: null,
            dataSource: [],
            ChangePolicyTime: '4 hours',
            CancelPolicyTime: '4 hours',
            OnWardsBeggageInfo: [],
            returnsBeggageInfo: [],
            returnsStations: [],
            FareRulesStatus: false,
            grandTotal: 0,
            tax: 0,
            baseFair: 0,
            convCharge: 0,
            pay: 0,
            totalPassengers: 0,
            passangerInfo: [],
            adultsOptions: [
                { value: 'Mr.', label: 'Mr.' },
                { value: 'Mrs.', label: 'Mrs.' },
                { value: 'Miss.', label: 'Miss.' },
            ],
            childsOptions: [
                { value: 'Mr.', label: 'Mr.' },
                { value: 'Miss.', label: 'Miss.' },
            ],
            infantsOptions: [
                { value: 'Mr.', label: 'Mr.' },
                { value: 'Miss.', label: 'Miss.' },
            ],
            gstDetails: {
                Email: localStorage.getItem('gstemail'),
                Phone: localStorage.getItem('gstphone'),
                GSTNumber: localStorage.getItem('gst_no'),
                CompanyName: localStorage.getItem('gst_company_name'),
                Address: localStorage.getItem('gst_Address'),
                error: {
                    error_status: false
                }
            },
            error: {
                email: false,
                mobile: false
            },
            error_status: true
        }
    }

    async checkAuth() {
        setTimeout(() => {
            Auth.isLoggedInAsync().then((res) => {
                this.setState({ isLoggedIn: res })
            });
        }, 1)
    }

    async componentDidMount() {

        this.checkAuth();
        window.scrollTo(0, 0);

        if (this.props.location.state !== undefined) {
            let amount = 0;

            amount = this.props.location.state.flightDetails.onWards.Fare.TotalFareWithOutMarkUp;
            if (this.props.location.state.flightDetails.return && this.props.location.state.airpot_type === false) {
                amount = amount + this.props.location.state.flightDetails.return.Fare.TotalFareWithOutMarkUp;
            }

            this.setState({
                airpot_type: this.props.location.state.airpot_type
            }, () => {
                this.priceRecheck()
            })

            this.setPassangerDetails();

            if (await Auth.isLoggedInAsync()) {
                let users = await Auth.user();
                this.setState({
                    email: users.email ? users.email.substring(users.email.indexOf("#") + 1) : '',
                    mobile: users.mobile,
                    isLoggedIn: await Auth.isLoggedInAsync()
                })
            }


        } else {
            setTimeout(() => {

                this.props.history.push('/')
            }, 100)
        }
    }

    componentWillReceiveProps(nextProps) {

        let onWards = [];
        let OnWardsBeggageInfo = {};
        let returns = [];
        let onWardsStations = [];
        let returnsStations = [];

        let amountDetails = {
            adult: { Amount: 0, count: 0 },
            childs: { Amount: 0, count: 0 },
            infrant: { Amount: 0, count: 0 }
        };

        let Fare = {
            BasicFare: 0,
            TotalTaxWithOutMarkUp: 0,
            TotalFareWithOutMarkUp: 0
        };

        if (nextProps.allTaxes !== null) {
            this.setState({
                grandTotal: parseFloat(nextProps.allTaxes.totalAmount),
                tax: parseFloat(nextProps.allTaxes.convenienceCharge),
                baseFair: parseFloat(nextProps.allTaxes.baseFare),
                convCharge: parseFloat(nextProps.allTaxes.convenienceCharge),
            })
        }

        if (nextProps.searchFlightFare) {
            let searchFlightFare = _.filter(nextProps.searchFlightFare.Segment, (sub) => {
                return !_.isEmpty(sub);
            });

            searchFlightFare.map((segment, i) => {

                if (segment !== undefined && segment.Fare !== undefined) {

                    segment.Bonds.map((bonds) => {

                        if (bonds.BoundType === "OutBound" || bonds.BoundType === null) {
                            onWards = bonds.Legs;
                            onWardsStations = _.map(bonds.Legs, (legs, i) => {

                                return legs.Origin;
                            });
                            onWardsStations.push(bonds.Legs[bonds.Legs.length - 1].Destination)
                        } else {
                            returns = bonds.Legs;
                            returnsStations = _.map(bonds.Legs, (legs, i) => {

                                return legs.Origin;
                            });
                            returnsStations.push(bonds.Legs[bonds.Legs.length - 1].Destination)
                        }
                    });

                    Fare.BasicFare = parseFloat(Fare.BasicFare) + parseFloat(segment.Fare.BasicFare);
                    Fare.TotalTaxWithOutMarkUp = parseFloat(Fare.TotalTaxWithOutMarkUp) + parseFloat(segment.Fare.TotalTaxWithOutMarkUp);
                    Fare.TotalFareWithOutMarkUp = parseFloat(Fare.TotalFareWithOutMarkUp) + parseFloat(segment.Fare.TotalFareWithOutMarkUp);
                    segment.Fare.PaxFares.map((fare) => {

                        if (fare.PaxType === 0) {
                            let amount = (fare.BasicFare * nextProps.searchFlightFare.adults) + amountDetails.adult.Amount;
                            amountDetails.adult = { Amount: amount, count: nextProps.searchFlightFare.adults }
                        } else if (fare.PaxType === 1) {
                            let amount = (fare.BasicFare * nextProps.searchFlightFare.childs) + amountDetails.childs.Amount;
                            amountDetails.childs = { Amount: amount, count: nextProps.searchFlightFare.childs }
                        } else if (fare.PaxType === 2) {
                            let amount = (fare.BasicFare * nextProps.searchFlightFare.infants) + amountDetails.infrant.Amount;
                            amountDetails.infrant = { Amount: amount, count: nextProps.searchFlightFare.infants }
                        }
                    });
                    OnWardsBeggageInfo = segment.Fare.PaxFares[0];
                }
            })
            if (returns.length === 0) {
                returns = null;
            }

            this.setState({
                Fare: Fare,
                amountDetails: amountDetails,
                onWards: onWards,
                onWardsStations: onWardsStations,
                returns: returns,
                returnsStations: returnsStations,
                isProcessedRecheckLoader: false,
                isProcessedToPay: true
            });
        }
    }

    priceRecheck = () => {
        let bookingData = {};
        let searchDetails = this.props.location.state.searchDetails;

        bookingData.adults = searchDetails.adults;
        bookingData.childs = searchDetails.childs;
        bookingData.infants = searchDetails.infants;
        bookingData.cabin = searchDetails.cabin;
        bookingData.tripType = searchDetails.tripType;
        bookingData.beginDate = searchDetails.beginDate;
        bookingData.returnDate = searchDetails.returnDate;
        bookingData.to = searchDetails.to;
        bookingData.from = searchDetails.from;
        bookingData.traceId = this.props.location.state.flightDetails.TraceId;

        let flightData = bookingData;
        bookingData.onword = this.props.location.state.flightDetails.onWards;

        if (this.state.airpot_type === false && bookingData.tripType === '1') {
            bookingData.return = this.props.location.state.flightDetails.return;
        }
        this.props.reCheckPrice(bookingData, flightData);
    }

    setPassangerDetails = () => {
        if (this.props.location.state.searchDetails) {

            let is_passport = true;

            if (this.props.location.state.searchDetails.fromCountry === 'IN' && this.props.location.state.searchDetails.toCountry === 'IN') {
                is_passport = false;
            }

            let passangerInfo = [];
            for (let i = 1; i <= this.props.location.state.searchDetails.adults; i++) {
                let obj = {};
                obj.firstname = "";
                obj.lastname = "";
                obj.title = "Mr.";
                obj.titles = "Mr.";
                obj.is_passport = is_passport;
                obj.passport_no = "";
                obj.paxType = 0;
                obj.error = {
                    firstname: false,
                    lastname: false,
                    title: false,
                    passport_no: false
                };
                passangerInfo.push(obj);
            }
            for (let i = 1; i <= this.props.location.state.searchDetails.childs; i++) {
                let obj = {};
                obj.firstname = "";
                obj.lastname = "";
                obj.title = "Miss.";
                obj.titles = "Miss.";
                obj.is_passport = is_passport;
                obj.passport_no = "";
                obj.paxType = 1;
                obj.dob = null;
                obj.minDate = moment(new Date().setFullYear(new Date().getFullYear() - 12));
                obj.maxDate = moment(new Date().setFullYear(new Date().getFullYear() - 2));
                obj.error = {
                    firstname: false,
                    lastname: false,
                    title: false,
                    passport_no: false
                };
                passangerInfo.push(obj);
            }
            for (let i = 1; i <= this.props.location.state.searchDetails.infants; i++) {
                let obj = {};
                obj.firstname = "";
                obj.lastname = "";
                obj.title = "Mr.";
                obj.titles = "Mr.";
                obj.is_passport = is_passport;
                obj.passport_no = "";
                obj.dob = null;
                obj.minDate = moment(new Date().setFullYear(new Date().getFullYear() - 2));
                obj.maxDate = moment(new Date().setFullYear(new Date().getFullYear()));
                obj.paxType = 2;
                obj.error = {
                    firstname: false,
                    lastname: false,
                    title: false,
                    passport_no: false
                };
                passangerInfo.push(obj);
            }

            this.setState({
                passangerInfo: passangerInfo,
                totalPassengers: passangerInfo.length
            });
        } else {
            toast.error('Session Expired please re-search flight!')
        }
    }

    fillUpGstDetails = (event) => {

        let gstDetails = this.state.gstDetails;
        if (event.target.name === 'email') {
            gstDetails.Email = event.target.value;
            localStorage.setItem('gstemail', event.target.value);
        }
        if (event.target.name === 'mobile') {
            gstDetails.Phone = event.target.value;
            localStorage.setItem('gstphone', event.target.value);
        }
        if (event.target.name === 'gst_no') {

            gstDetails.GSTNumber = event.target.value;
            localStorage.setItem('gst_no', event.target.value);
        }
        if (event.target.name === 'company_name') {
            gstDetails.CompanyName = event.target.value;
            localStorage.setItem('gst_company_name', event.target.value);
        }
        if (event.target.name === 'address') {
            gstDetails.Address = event.target.value;
            localStorage.setItem('gst_Address', event.target.value);
        }


        this.setState({
            gstDetails: gstDetails
        }, () => {
            this.validateGstDetails()
        })
    }

    handleChange = (value, index, fieldname) => {
        this.state.passangerInfo.map((obj, i) => {
            if (index === i && fieldname === 'title') {
                obj.title = value.value;
                obj.titles = value;
            }
            if (index === i && fieldname === 'firstname') {

                obj.firstname = value;
            }
            if (index === i && fieldname === 'lastname') {
                obj.lastname = value;
            }
            if (index === i && fieldname === 'dob') {
                if (value !== null) {
                    obj.dob = moment(value).format('MM-DD-YYYY');
                }
            }
            if (index === i && fieldname === 'passport_no') {
                obj.passport_no = value;
            }
            return obj;
        });
        this.setState({
            passangerInfo: this.state.passangerInfo
        });
        this.validate();
    }

    validate = () => {
        let error_status = false;
        this.state.passangerInfo.map((obj, i) => {
            if (obj.firstname === '' || (!/^[a-z A-Z]+$/.test(obj.firstname))) {
                error_status = true;
                obj.error.firstname = true;
            } else {
                obj.error.firstname = false;
            }
            if (obj.lastname === '' || (!/^[a-z A-Z]+$/.test(obj.lastname))) {
                error_status = true;
                obj.error.lastname = true;
            } else {
                obj.error.lastname = false;
            }
            if (obj.dob === '' || obj.dob === null || obj.dob === 'Invalid date') {
                error_status = true;
                obj.error.dob = true;
            } else {
                obj.error.dob = false;
            }
            if (obj.is_passport === true) {
                // var regsaid = /[a-zA-Z]{1}[a-zA-Z0-9]{1}[0-9]{7}/;
                if (obj.passport_no === '') {
                    error_status = true;
                    obj.error.passport_no = true;
                } else {
                    obj.error.passport_no = false;
                }
            }
            return obj;
        });

        this.setState({
            error_status: error_status,
            passangerInfo: this.state.passangerInfo
        });
        return error_status;
    }

    handleEmail = (event) => {

        if (event.target.value.length > 0) {

            if (!/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(event.target.value)) {

                this.setState({
                    error_status: true,
                    email: event.target.value,
                    error: {
                        email: 'You have entered an invalid email address!'
                    }
                })
                return false;
            }
        }

        this.setState({
            error_status: false,
            error: {
                email: false,
                mobile: false
            },
            email: event.target.value
        })
    }

    handleMobile = (event) => {
        if (event.target.value.length > 0) {

            if (!/^\d{10}$/.test(event.target.value)) {

                this.setState({
                    error_status: true,
                    mobile: event.target.value,
                    error: {
                        mobile: 'You have entered an invalid Mobile Number!'
                    }
                })
                return false;
            }
        }
        this.setState({
            error_status: false,
            error: {
                email: false,
                mobile: false
            },
            mobile: event.target.value
        })
    }

    validateGstDetails = () => {
        let gstDetails = this.state.gstDetails;
        let error_status = false;


        if (gstDetails.Email !== null && gstDetails.Email !== '') {
            if (gstDetails.Email.length > 0) {
                if (!/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(gstDetails.Email)) {
                    gstDetails.error.Email = 'You have entered an invalid email address!';
                    error_status = true;
                } else {
                    gstDetails.error.Email = '';
                    error_status = false;
                }
            } else {
                gstDetails.error.Email = '';
                gstDetails.error.error_status = false;
            }
        } else {
            gstDetails.error.Email = '';
            gstDetails.error.error_status = false;
        }

        if (gstDetails.CompanyName !== null && gstDetails.CompanyName !== '') {
            if (gstDetails.CompanyName.length > 0 && gstDetails.CompanyName !== null) {
                if (!/^[A-Za-z0-9 ]{4,80}$/.test(gstDetails.CompanyName)) {
                    gstDetails.error.CompanyName = 'You have entered an invalid Company Name! Character between 4 to 80 and A to Z,0 to 9';
                    error_status = true;
                } else {
                    gstDetails.error.CompanyName = '';
                    gstDetails.error.error_status = false;
                }
            } else {
                gstDetails.error.CompanyName = '';
                gstDetails.error.error_status = false;
            }
        } else {
            gstDetails.error.CompanyName = '';

        }

        if (gstDetails.Phone !== null && gstDetails.Phone !== '') {
            if (gstDetails.Phone.length > 0 && gstDetails.Phone !== null) {
                if (!/^\d{10}$/.test(gstDetails.Phone)) {

                    gstDetails.error.Phone = 'You have entered an invalid Mobile Number!';
                    error_status = true;
                } else {
                    gstDetails.error.Phone = '';
                    gstDetails.error.error_status = false;
                }
            } else {
                gstDetails.error.Phone = '';
                gstDetails.error.error_status = false;
            }
        } else {
            gstDetails.error.Phone = '';
            gstDetails.error.error_status = false;
        }

        if (gstDetails.Address !== null && gstDetails.Address !== '') {
            if (gstDetails.Address.length > 0 && gstDetails.Address !== null) {
                // var regex = /^[-@,./#&+\w\s]{4,255}$/;
                if (gstDetails.Address.length < 5 && gstDetails.Address.length > 255) {

                    gstDetails.error.Address = 'You have entered an invalid Address! Character between 5 to 255';
                    error_status = true;
                } else {
                    gstDetails.error.Address = '';
                    gstDetails.error.error_status = false;
                }
            } else {
                gstDetails.error.Address = '';
                gstDetails.error.error_status = false;
            }
        } else {
            gstDetails.error.Address = '';
            gstDetails.error.error_status = false;
        }

        if (gstDetails.GSTNumber !== null && gstDetails.GSTNumber !== '') {
            if (gstDetails.GSTNumber.length > 0 && gstDetails.GSTNumber !== null) {
                if (!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gstDetails.GSTNumber)) {

                    gstDetails.error.GSTNumber = 'Invalid Gst no';
                    error_status = true;

                } else {
                    gstDetails.error.GSTNumber = '';
                    gstDetails.error.error_status = false;
                }
            } else {
                gstDetails.error.GSTNumber = '';
                gstDetails.error.error_status = false;
            }
        } else {
            gstDetails.error.GSTNumber = '';
            gstDetails.error.error_status = false;
        }

        gstDetails.error.error_status = error_status;
        this.setState({
            isGstCollapse: true,
            gstDetails: gstDetails,
            error_status: error_status
        })
        return gstDetails.error.error_status;
    }

    nextPage = async () => {

        await this.validateGstDetails()

        if (!this.state.gstDetails.error.error_status) {
            this.setState({
                isProcessedRecheckLoader: true
            })
            this.priceRecheck();

        }
    }

    payNow = async () => {
        if(this.validateGstDetails()){
            toast.error('Please fill valid GSTIN details!');
            return false;
        }
        if(this.validate()){
            toast.error('Please fill valid passenger details!');
            return false;
        }
        if (this.state.error_status) {
            toast.error('Please fill booking detail')
            return false;
        }
        if (this.state.error_status === false) {
            let contact_error = false;
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {

                toast.error('You have entered an invalid email address!');
                contact_error = true;
                this.setState({
                    error_status: true,
                    error: {
                        email: 'You have entered an invalid email address!'
                    }
                })
            }

            if (!/^\d{10}$/.test(this.state.mobile)) {

                toast.error('You have entered an invalid Mobile Number!')
                contact_error = true;
                this.setState({
                    error_status: true,
                    error: {
                        mobile: 'You have entered an invalid Mobile Number!'
                    }
                })
            }

            let error_text = 'Please fill contact details !';
            if (this.state.email === '') {
                contact_error = true;
                toast.error(error_text);
                this.setState({
                    error_status: true,
                    error: {
                        email: 'You have entered an invalid email address!'
                    }
                })
            }

            if (this.state.mobile === '') {
                contact_error = true;
                toast.error(error_text);
                this.setState({
                    error_status: true,
                    error: {
                        mobile: 'You have entered an invalid Mobile Number!'
                    }
                })
            }

            if (contact_error === false) {
                let travellers = [];
                this.state.passangerInfo.map((traveller) => {
                    let obj = {};
                    obj.firstName = traveller.firstname;
                    obj.lastName = traveller.lastname;
                    obj.title = traveller.titles;
                    obj.paxType = traveller.paxType;
                    obj.is_passport = traveller.is_passport;
                    obj.passport_no = traveller.passport_no;
                    if (traveller.paxType === 1 || traveller.paxType === 2) {
                        obj.dob = moment(traveller.dob).format('MM-DD-YYYY');
                    }
                    travellers.push(obj);
                });
                let bookingDetails = this.props.searchFlightFare;
                bookingDetails.email = this.state.email;
                bookingDetails.mobile = this.state.mobile;
                bookingDetails.grandTotal = this.props.allTaxes.totalAmount;
                bookingDetails.currencyCode = "INR";
                bookingDetails.taxData = this.props.allTaxes;
                bookingDetails.traveller = travellers;
                this.setState({
                    isProcessedPayNowLoader: true
                }, () => {
                    console.log("jdsadh", this.props.allTaxes.paybleTotal)
                    this.payNowBooking(bookingDetails)
                })
            }
        }
    }

    payNowBooking = (bookingDetails) => {
        return bookingBeforePayment(bookingDetails)
            .then((res) => {
                const sources = res.data;

                let paymentSignature = null;
                if (sources.data.payableAmount != 0) {
                    if (sources.status === true) {
                        let orderId = sources.data.order_id;
                        let amount = this.props.allTaxes.paybleTotal;
                        return getSignature(amount, orderId)
                            .then((response) => {

                                const sources = response.data;

                                if (sources.status === true) {
                                    paymentSignature = sources.data;

                                    return this.citrusDialog(paymentSignature)

                                }
                            })
                    } else {
                        toast.error(sources.message)
                    }
                } else {
                    return bookingAfterPayment(sources.data)
                        .then((res) => {

                            this.props.history.push('/booking-history');
                        })
                        .catch((error) => {
                        })
                }
            })
            .catch((error) => {

                if (undefined !== error.response) {

                    const sources = error.response.data;
                    this.setState({
                        isProcessedPayNowLoader: false
                    })
                    if (sources.status === false) {
                        if (error.response.data.error.length > 0 && error.response.status !== 424) {
                            Object.keys(error.response.data.error).forEach((key) => {

                                error.response.data.error[key].forEach((error) => {
                                    toast.error(error);
                                });
                            });
                        } else {
                            toast.error(sources.message);
                        }

                    } else {
                        toast.error('server error!');
                    }
                } else {
                    toast.error('server error!');
                }
            })
    }

    citrusDialog = (paymentSignature, orderId) => {

        let form = document.createElement("form");
        form.setAttribute("method", 'POST');
        form.setAttribute("action", paymentSignature['url']);


        for (let key in paymentSignature) {

            let hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", paymentSignature[key]);

            form.appendChild(hiddenField);

        }

        //
        document.body.appendChild(form);
        form.submit();

    }

    render() {


        let adults = 0;
        let childs = 0;
        let infrants = 0;
        if (!this.state.isLoggedIn) {

            return (
                <Fragment>
                    <TopBar {...this.props} title={"Flight Fare Details"} />
                    <div className="container text-center mt-5">
                        <NoLogin />
                    </div>
                </Fragment>
            );
        }

        if (this.state.onWards === null) {
            return (
                <Fragment>
                    <TopBar {...this.props} title={"Flight Fare Details"} />
                    <div className="container text-center mt-5">
                        <Card className='bp3-elevation-1 border mt-5 mb-5'>
                            <Loader loaderType='GG loader' loaderImg='https://s3.ap-south-1.amazonaws.com/gg-statics/gg-statics-staging/loader2.gif' />
                        </Card>
                    </div>
                </Fragment>
            );
        }

        // let cabin = '7 KG';
        let { searchDetails } = this.props.location.state;

        return (
            <Fragment>
                <TopBar {...this.props} title={"Flight Fare Details"} />
                <div className="row">
                    <div className="col-12">
                        <div className="reviewItinery mt-3 mb-5">
                            <div className="flight-bookingdetails">
                                <div className="">

                                    <div className="row mb-1">
                                        <div className={"col-xl-8 col-lg-8 col-md-9 col-sm-8 col-12 pull-left"}>
                                            <div className={"row no-gutters flight-stationname"}>
                                                <p className={"m-2 m-s-0"}>{searchDetails.fromCity}</p>
                                                <p className={"m-2 m-s-0 px-s-3"}><SwapHorizRounded /></p>
                                                <p className={"m-2 m-s-0"}>
                                                    {searchDetails.toCity}
                                                    <span>({moment(searchDetails.beginDate).format('DD, MMM Y')})</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={"col-xl-4 col-lg-4 col-md-3 col-sm-4 col-12 pull-right flightname mt-s-2"}>{this.state.onWardsStations.join('-')}</div>
                                    </div>

                                    {
                                        this.state.onWards.map((flight, i) => {
                                            let flightImage = Config.IMAGE_URL + flight.AirlineName + '.png';
                                            return (
                                                <div key={i}
                                                    className="col-12 reviewItineryFlightList mt-3 pr-sm-0 pr-md-3">
                                                    <div className="row">
                                                        <div
                                                            className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-2 text-left px-s-0 px-sm-0">
                                                            <img alt={flight.FlightName} title={flight.FlightName}
                                                                src={flightImage} className="flightCompanyLogo" />
                                                        </div>
                                                        <div
                                                            className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-10 text-left px-sm-2 px-md-3">
                                                            <p className="travelTime mt-2 mb-0">{flight.Duration}, {this.state.onWards.length - 1} stop<br /><span
                                                                className="travelTime mt-0 mb-2">{flight.AirlineName}-{flight.FlightNumber}</span>
                                                            </p>

                                                        </div>
                                                        <div
                                                            className="col-xl-9 col-lg-9 col-md-8 col-sm-7 col-12 text-center mt-s-3 mt-sm-2 px-s-0 px-sm-0 px-md-3">
                                                            <div
                                                                className="col row align-items-top mb-1 px-s-0 px-sm-0">
                                                                <div
                                                                    className="col text-left pl-s-3 pr-s-1 pl-sm-4 pr-sm-1 pl-md-5 pr-md-3">
                                                                    <p className="journeyCity m-0">{flight.Origin} <span
                                                                        className="journeyTime m-0">{flight.DepartureTime}</span>
                                                                    </p>
                                                                    <p className="journeyDate m-0 mt-2 mt-s-3">{flight.DepartureDate}</p>
                                                                    <p className="airportName">Terminal {flight.DepartureTerminal}</p>
                                                                </div>
                                                                <div className="col text-center px-s-0 px-sm-0 px-md-2">
                                                                    <div className="flightIconWrapper d-inline">
                                                                        <p className="flighttravelTime mb-0 mt-1">{flight.Duration} | {this.state.onWards.length - 1} stop</p>
                                                                        <p className="flight-status mt-md-2 mt-lg-2 mt-xl-2 mt-s-3 mt-sm-3">Refundable</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="col text-right pl-s-1 pr-s-0 pl-sm-1 pr-sm-0 pl-md-4 pr-md-3 pr-lg-5 pr-xl-5">
                                                                    <p className="journeyCity m-0 text-right-xs">{flight.Destination}
                                                                        <span
                                                                            className="journeyTime ml-2">{flight.ArrivalTime}</span>
                                                                    </p>
                                                                    <p className="journeyDate m-0 mt-2 mt-s-3 text-right-xs">{flight.ArrivalDate}</p>
                                                                    <p className="airportName text-right-xs">Terminal {flight.ArrivalTerminal}</p>
                                                                </div>
                                                            </div>
                                                            <p className="two-dots col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12"></p>
                                                            <div className="col-12">
                                                                <p className="transitTime text-center">Transit time
                                                                    : {flight.Duration}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }

                                    {
                                        this.state.returns ?
                                            <Fragment>
                                                <div className="row mb-1">
                                                    <div className={"col-xl-8 col-lg-8 col-md-9 col-sm-8 col-12 pull-left"}>
                                                        <div className={"row no-gutters flight-stationname"}>
                                                            <p className={"m-2 m-s-0"}>{searchDetails.toCity}</p>
                                                            <p className={"m-2 m-s-0 px-s-3"}><SwapHorizRounded /></p>
                                                            <p className={"m-2 m-s-0"}>
                                                                {searchDetails.fromCity}
                                                                <span>({moment(searchDetails.returnDate).format('DD, MMM Y')})</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={"col-xl-4 col-lg-4 col-md-3 col-sm-4 col-12 pull-right flightname mt-s-2"}>{this.state.returnsStations.join('-')}</div>
                                                </div>
                                                {
                                                    this.state.returns.map((flight, i) => {
                                                        let flightImage = Config.IMAGE_URL + flight.AirlineName + '.png';
                                                        return (
                                                            <div key={i}
                                                                className="col-12 reviewItineryFlightList mt-3 pr-sm-0 pr-md-3">
                                                                <div className="row">
                                                                    <div
                                                                        className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-2 text-left px-s-0 px-sm-0">
                                                                        <img alt={flight.FlightName} title={flight.FlightName}
                                                                            src={flightImage} className="flightCompanyLogo" />
                                                                    </div>
                                                                    <div
                                                                        className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-10 text-left px-sm-2 px-md-3">
                                                                        <p className="travelTime mt-2 mb-0">{flight.Duration}, {this.state.returns.length - 1} stop<br /><span
                                                                            className="travelTime mt-0 mb-2">{flight.AirlineName}-{flight.FlightNumber}</span>
                                                                        </p>

                                                                    </div>
                                                                    <div
                                                                        className="col-xl-9 col-lg-9 col-md-8 col-sm-7 col-12 text-center mt-s-3 mt-sm-2 px-s-0 px-sm-0 px-md-3">
                                                                        <div
                                                                            className="col row align-items-top mb-1 px-s-0 px-sm-0">
                                                                            <div
                                                                                className="col text-left pl-s-3 pr-s-1 pl-sm-4 pr-sm-1 pl-md-5 pr-md-3">
                                                                                <p className="journeyCity m-0">{flight.Origin}
                                                                                    <span
                                                                                        className="journeyTime m-0">{flight.DepartureTime}</span>
                                                                                </p>
                                                                                <p className="journeyDate m-0 mt-2 mt-s-3">{flight.DepartureDate}</p>
                                                                                <p className="airportName">Terminal {flight.DepartureTerminal}</p>
                                                                            </div>
                                                                            <div
                                                                                className="col text-center px-s-0 px-sm-0 px-md-2">
                                                                                <div className="flightIconWrapper d-inline">
                                                                                    <p className="flighttravelTime mb-0 mt-1">{flight.Duration} | {this.state.returns.length - 1} stop</p>
                                                                                    <p className="flight-status mt-md-2 mt-lg-2 mt-xl-2 mt-s-3 mt-sm-3">Refundable</p>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="col text-right pl-s-1 pr-s-0 pl-sm-1 pr-sm-0 pl-md-4 pr-md-3 pr-lg-5 pr-xl-5">
                                                                                <p className="journeyCity m-0 text-right-xs">{flight.Destination}
                                                                                    <span
                                                                                        className="journeyTime m-0">{flight.ArrivalTime}</span>
                                                                                </p>
                                                                                <p className="journeyDate m-0 mt-2 mt-s-3 text-right-xs">{flight.ArrivalDate}</p>
                                                                                <p className="airportName text-right-xs">Terminal {flight.ArrivalTerminal}</p>
                                                                            </div>
                                                                        </div>
                                                                        <p className="two-dots col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12"></p>
                                                                        <div className="col-12">
                                                                            <p className="transitTime text-center">Transit time
                                                                                : {flight.Duration}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </Fragment>
                                            :
                                            ''
                                    }

                                </div>
                            </div>

                            <div className="mt-4 row">
                                <div className="col-md-8 col-sm-7 col-12">
                                    <div className="flight-passdetails">
                                        <div className="baggageInfo canclePolicy mt-2">
                                            <h4 className="title mb-3 plus d-flex align-items-center" onClick={() => {
                                                this.setState({
                                                    isGstCollapse: !this.state.isGstCollapse
                                                })
                                            }} style={{ 'cursor': 'pointer' }}><span className="bp3-icon bp3-icon-add mr-2 text-dark"></span> Use GSTIN for this booking (OPTIONAL)</h4>
                                            <Collapse isOpen={this.state.isGstCollapse}>
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mt-3">
                                                        <div className="bp3-form-group">
                                                            <div className="bp3-input-group">
                                                                <input type='text'
                                                                    className={this.state.gstDetails.error.Email ? 'bp3-input input-error-border' : 'bp3-input'}
                                                                    placeholder="Email Id" name='email'
                                                                    onChange={this.fillUpGstDetails}
                                                                    value={this.state.gstDetails.Email ? this.state.gstDetails.Email : ''} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mt-3">
                                                        <div className="bp3-form-group">
                                                            <div className="bp3-input-group">
                                                                <input type='text'
                                                                    className={this.state.gstDetails.error.Phone ? 'bp3-input input-error-border' : 'bp3-input'}
                                                                    name='mobile'
                                                                    placeholder="Mobile Number"
                                                                    onChange={this.fillUpGstDetails}
                                                                    value={this.state.gstDetails.Phone ? this.state.gstDetails.Phone : ''} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mt-3 mt-s-2">
                                                        <div className="bp3-form-group">
                                                            <div className="bp3-input-group">
                                                                <input type='text'
                                                                    className={this.state.gstDetails.error.GSTNumber ? 'bp3-input input-error-border' : 'bp3-input'}
                                                                    name='gst_no'
                                                                    placeholder="GST No"
                                                                    onChange={this.fillUpGstDetails}
                                                                    value={this.state.gstDetails.GSTNumber ? this.state.gstDetails.GSTNumber : ''} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mt-3 mt-s-2">
                                                        <div className="bp3-form-group">
                                                            <div className="bp3-input-group">
                                                                <input type='text'
                                                                    className={this.state.gstDetails.error.CompanyName ? 'bp3-input input-error-border' : 'bp3-input'}
                                                                    name='company_name'
                                                                    placeholder="Company Name"
                                                                    onChange={this.fillUpGstDetails}
                                                                    value={this.state.gstDetails.CompanyName ? this.state.gstDetails.CompanyName : ''} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mt-s-2">
                                                        <div className="bp3-form-group">
                                                            <div className="bp3-input-group">
                                                                <textarea type='text'
                                                                    className={this.state.gstDetails.error.Address ? 'bp3-input input-error-border' : 'bp3-input'}
                                                                    rows="3" name='address' placeholder="Address"
                                                                    onChange={this.fillUpGstDetails}
                                                                    value={this.state.gstDetails.Address ? this.state.gstDetails.Address : ''} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>

                                        </div>
                                    </div>

                                    <div className="flight-primatypass mt-2">
                                        <div className="row mb-4 pb-2">
                                            <div className="col-12 mb-2" >
                                                <h4 className="title">Contact Details</h4>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mt-3 mt-s-2">
                                                <div className="bp3-form-group">
                                                    <div className="bp3-input-group">
                                                        <input type='text'
                                                            className={this.state.error.mobile ? 'bp3-input input-error-border' : 'bp3-input'}
                                                            name='mobile'
                                                            placeholder="Mobile Number*"
                                                            value={this.state.mobile}
                                                            onChange={this.handleMobile}
                                                        />
                                                        <span
                                                            className={"text-danger mt-2"}>{this.state.error.mobile}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mt-3 mt-s-2">
                                                <div className="bp3-form-group">
                                                    <div className="bp3-input-group">
                                                        <input type='text'
                                                            className={this.state.error.email ? 'bp3-input input-error-border' : 'bp3-input'}
                                                            name='email'
                                                            placeholder="Email ID*"
                                                            value={this.state.email}
                                                            onChange={this.handleEmail}
                                                        />
                                                        <span
                                                            className={"text-danger mt-2"}>{this.state.error.email}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row border-top"></div>

                                        {
                                            this.state.passangerInfo.map((passanger, index) => {
                                                {
                                                    passanger.paxType === 0 ? adults++ : (passanger.paxType === 1 ? childs++ : infrants++)
                                                }

                                                return (
                                                    <Fragment key={index}>
                                                        <div className="row my-4 py-2">
                                                            <div className="col-12">
                                                                <h4 className="title">
                                                                    {
                                                                        passanger.paxType === 0 ?
                                                                            'Adult ' + adults
                                                                            :
                                                                            (passanger.paxType === 1 ?
                                                                                'Childs ' + childs
                                                                                :
                                                                                'Infants ' + infrants
                                                                            )
                                                                    }

                                                                </h4>
                                                            </div>
                                                            <div
                                                                className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 mt-3">
                                                                <div className="bp3-form-group">
                                                                    <div className="bp3-input-group">
                                                                        <Select
                                                                            name="title"
                                                                            clearable={false}
                                                                            value={passanger.title}
                                                                            onChange={(e) => {
                                                                                this.setState({ person: e.value }, () => {
                                                                                    this.handleChange(this.state.person, index, 'title')
                                                                                })
                                                                            }}
                                                                            placeholder="Select title"
                                                                            options={passanger.paxType === 0 ? this.state.adultsOptions : (passanger.paxType === 1 ? this.state.childsOptions : this.state.infantsOptions)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6 mt-3 mt-s-2">
                                                                <div className="bp3-form-group">
                                                                    <div className="bp3-input-group">
                                                                        <input type='text'
                                                                            className={passanger.error.firstname === true ? 'bp3-input input-error-border' : 'bp3-input'}
                                                                            name='firstname'
                                                                            placeholder="First Name*"
                                                                            value={passanger.firstname}
                                                                            onChange={(event) => {
                                                                                this.handleChange(event.target.value, index, 'firstname')
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6 mt-3 mt-s-2">
                                                                <div className="bp3-form-group">
                                                                    <div className="bp3-input-group">
                                                                        <input type='text'
                                                                            className={passanger.error.lastname === true ? 'bp3-input input-error-border' : 'bp3-input'}
                                                                            name='lastname'
                                                                            placeholder="Last Name*"
                                                                            value={passanger.lastname}
                                                                            onChange={(event) => {
                                                                                this.handleChange(event.target.value, index, 'lastname')
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                passanger.paxType === 1 || passanger.paxType === 2 ?
                                                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6 mt-3 mt-s-2">
                                                                        <div className="bp3-form-group">
                                                                            <div className="bp3-input-group">
                                                                                <DateInput
                                                                                    required={true}
                                                                                    className={passanger.error.dob === true ? "input-error-border" : ""}
                                                                                    name="dob"
                                                                                    closeOnSelection={true}
                                                                                    formatDate={date => date.toLocaleDateString()}
                                                                                    parseDate={str => new Date(str)}
                                                                                    onChange={(date) => {
                                                                                        this.handleChange(new Date(moment(date)), index, 'dob')
                                                                                    }}
                                                                                    maxDate={new Date(passanger.maxDate)}
                                                                                    minDate={new Date(passanger.minDate)}
                                                                                    value={passanger.dob === null ? passanger.dob : new Date(passanger.dob)}
                                                                                    onError={(error) => {

                                                                                    }}
                                                                                    outOfRangeMessage={"Between Date :" + moment(passanger.minDate).format('DD-MM-YYYY') + "-" + moment(passanger.maxDate).format('DD-MM-YYYY')}
                                                                                    placeholder={"Date Of Birth"}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    : ''
                                                            }
                                                            {
                                                                passanger.is_passport === true ?
                                                                    <div
                                                                        className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6 mt-3 mt-s-2">
                                                                        <div className="bp3-form-group">
                                                                            <div className="bp3-input-group">
                                                                                <input type='text'
                                                                                    className={passanger.error.passport_no === true ? 'bp3-input input-error-border' : 'bp3-input'}
                                                                                    name='passport_no'
                                                                                    placeholder="Enter Passport Number"
                                                                                    value={passanger.passport_no ? passanger.passport_no : ''}
                                                                                    onChange={(event) => {
                                                                                        this.handleChange(event.target.value, index, 'passport_no')
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : ''
                                                            }
                                                        </div>
                                                        <div className="row border-top"></div>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-5 col-12">
                                    <div className="">
                                        <div className="totalpaymentWrapper">
                                            <div className="row justify-content-center">
                                                {
                                                    this.state.isLoggedIn ?
                                                        (this.props.allTaxes !== null ?
                                                            <Fragment>
                                                                <div className='bg-basic mb-cart-total'>
                                                                    <RedeemVoucher
                                                                        userGGVouchers={this.props.allTaxes.active_voucher}
                                                                        amount={this.props.allTaxes.baseFare}
                                                                        coin={this.props.allTaxes.ggCoin}
                                                                        {...this.props}
                                                                    />
                                                                    <div className="col-xl-12 mb-5">
                                                                        <p className="totalpayment mt-4 mt-sm-1">Total payable amount</p>
                                                                        <h2 className="totalpaymentprice">
                                                                            {parseFloat(this.props.allTaxes.paybleTotal).toLocaleString('en-IN', {
                                                                                maximumFractionDigits: 2,
                                                                                style: 'currency',
                                                                                currency: 'INR'
                                                                            })}
                                                                        </h2>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 mt-4">

                                                                    <div className="row mb-2">
                                                                        <div className="col-md-8 col-8 totalpaymentColumn text-left">
                                                                            <p className="farelable">Adult {this.state.amountDetails.adult.count}</p>
                                                                        </div>
                                                                        <div className="col-md-4 col-4 totalpaymentColumn text-right">
                                                                            <p className="farePrice">
                                                                                {parseFloat(this.state.amountDetails.adult.Amount).toLocaleString('en-IN', {
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'INR'
                                                                                })}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-2">
                                                                        <div className="col-md-8 col-8 totalpaymentColumn text-left">
                                                                            <p className="farelable">Childs {this.state.amountDetails.childs.count}</p>
                                                                        </div>
                                                                        <div className="col-md-4 col-4 totalpaymentColumn text-right">
                                                                            <p className="farePrice">
                                                                                +{parseFloat(this.state.amountDetails.childs.Amount)
                                                                                    .toLocaleString('en-IN', {
                                                                                        maximumFractionDigits: 2,
                                                                                        style: 'currency',
                                                                                        currency: 'INR'
                                                                                    })
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-2">
                                                                        <div className="col-md-8 col-8 totalpaymentColumn text-left">
                                                                            <p className="farelable">Infants {this.state.amountDetails.infrant.count}</p>
                                                                        </div>
                                                                        <div className="col-md-4 col-4 totalpaymentColumn text-right">
                                                                            <p className="farePrice">
                                                                                +{parseFloat(this.state.amountDetails.infrant.Amount).toLocaleString('en-IN', {
                                                                                maximumFractionDigits: 2,
                                                                                style: 'currency',
                                                                                currency: 'INR'
                                                                            })}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-2">
                                                                        <div className="col-md-8 col-8 totalpaymentColumn text-left">
                                                                            <p className="farelable">Fees & Surcharge </p>
                                                                        </div>
                                                                        <div className="col-md-4 col-4 totalpaymentColumn text-right">
                                                                            <p className="farePrice">
                                                                                +{parseFloat(this.state.Fare.TotalTaxWithOutMarkUp).toLocaleString('en-IN', {
                                                                                maximumFractionDigits: 2,
                                                                                style: 'currency',
                                                                                currency: 'INR'
                                                                            })}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-2">
                                                                        <div className="col-md-8 col-8 totalpaymentColumn text-left">
                                                                            <p className="farelable">Convenience Charge (7.99%)</p>
                                                                        </div>
                                                                        <div className="col-md-4 col-4 totalpaymentColumn text-right">
                                                                            <p className="farePrice">
                                                                                +{parseFloat(this.props.allTaxes.tax).toLocaleString('en-IN', {
                                                                                maximumFractionDigits: 2,
                                                                                style: 'currency',
                                                                                currency: 'INR'
                                                                            })}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-2">
                                                                        <div className="col-md-8 col-8 totalpaymentColumn text-left">
                                                                            <p className="farelable">Total</p>
                                                                        </div>
                                                                        <div className="col-md-4 col-4 totalpaymentColumn text-right">
                                                                            <p className="farePrice">
                                                                                {parseFloat(this.props.allTaxes.totalAmount).toLocaleString('en-IN', {
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'INR'
                                                                                })}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-2">
                                                                        <div className="col-md-8 col-8 totalpaymentColumn text-left">
                                                                            <p className="farelable">Applied Voucher Amount</p>
                                                                        </div>
                                                                        <div className="col-md-4 col-4 totalpaymentColumn text-right">
                                                                            {this.props.allTaxes.totalAppliedVoucherAmount ?
                                                                                <p className="farePrice">
                                                                                    -{parseFloat(this.props.allTaxes.totalAppliedVoucherAmount).toLocaleString('en-IN', {
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'INR'
                                                                                })}
                                                                                </p> : <p className="farePrice">
                                                                                    -₹0.00
                                                                                </p>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-2 border-top">
                                                                        <div className="col-12 border-top"></div>
                                                                    </div>
                                                                    <div className="row mb-2 mt-3">
                                                                        <div className="col-md-8 col-sm-7 col-8">
                                                                            <p className="flight-grandtotal">Grand Total</p>
                                                                        </div>
                                                                        <div className="col-md-4 col-sm-5 col-4 pl-sm-0">
                                                                            <p className="farePrice bold font-14">
                                                                                {parseFloat(this.props.allTaxes.paybleTotal).toLocaleString('en-IN', {
                                                                                    maximumFractionDigits: 2,
                                                                                    style: 'currency',
                                                                                    currency: 'INR'
                                                                                })}
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-md-8 col-sm-12 col-12">
                                                                            <p className="font-12">
                                                                                <small>Transit Visa for India may be required for
                                                                                traveling on this itinerary.
                                                                            </small>
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="text-right mt-4 visible-xl visible-lg visible-md hidden-sm hidden-xs">
                                                                        {
                                                                            !this.state.isProcessedToPay ?
                                                                                <Button
                                                                                    className="bp3-button flight-btnproceed"
                                                                                    disabled={this.state.gstDetails.error.error_status}
                                                                                    intent={Intent.PRIMARY}
                                                                                    onClick={this.nextPage}
                                                                                    loading={this.state.isProcessedRecheckLoader}
                                                                                >
                                                                                    Confirming Fare
                                                                            </Button>
                                                                                :
                                                                                <Button className="bp3-button flight-btnproceed"
                                                                                    intent={Intent.PRIMARY}
                                                                                    loading={this.state.isProcessedPayNowLoader}
                                                                                    onClick={this.payNow}
                                                                                >
                                                                                    Proceed to pay
                                                                            </Button>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                            :
                                                            <Loader />)
                                                        :
                                                        <NoLogin />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.isLoggedIn ?
                                        <div className="selectedFlightWrapper hidden-xl hidden-lg hidden-md visible-sm visible-xs">
                                            <div className="selectedFlight p-0 px-s-3 p-0">
                                                <div className="container">
                                                    <div className="row justify-content-center px-md-0">
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                                                            <div className="row">
                                                                <div className="col-xl-9 col-lg-9 col-md-8 col-sm-6 col-6 text-right mb-2 mb-md-0 mt-md-0 mt-md-1">
                                                                    <div className="col text-center mb-3">
                                                                        <p className="totalFareText m-0">Total Fare </p>
                                                                        <p className="totalFare m-0">
                                                                            {
                                                                                this.props.allTaxes !== null ?
                                                                                    parseFloat(this.props.allTaxes.paybleTotal).toLocaleString('en-IN', {
                                                                                        maximumFractionDigits: 2,
                                                                                        style: 'currency',
                                                                                        currency: 'INR'
                                                                                    })
                                                                                    :
                                                                                    ''
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 text-left mb-2 mb-md-0 mt-md-0 mt-md-1">
                                                                    <div className="text-left selectedFlightButton">
                                                                        {
                                                                            !this.state.isProcessedToPay ?
                                                                                <Fragment>
                                                                                    {
                                                                                        this.state.isProcessedRecheckLoader ?
                                                                                            <Loader />
                                                                                            :
                                                                                            <Fragment>
                                                                                                <button
                                                                                                    disabled={this.state.gstDetails.error.error_status}
                                                                                                    loading={this.state.isProcessedRecheckLoader}
                                                                                                    className="bp3-button btn-flightbooking"
                                                                                                    intent={Intent.PRIMARY}
                                                                                                    onClick={this.nextPage}>
                                                                                                    Confirming Fare
                                                                                                </button>
                                                                                                <p className="flightcancelpolicy">Cancellation available</p>
                                                                                            </Fragment>
                                                                                    }

                                                                                </Fragment>
                                                                                :
                                                                                <Fragment>
                                                                                    {
                                                                                        this.state.isProcessedPayNowLoader ?
                                                                                            <Loader />
                                                                                            :
                                                                                            <Fragment>
                                                                                                <Button
                                                                                                    className="bp3-button btn-flightbooking"
                                                                                                    intent={Intent.PRIMARY}
                                                                                                    loading={this.state.isProcessedPayNowLoader}
                                                                                                    onClick={this.payNow}
                                                                                                >
                                                                                                    Proceed to pay
                                                                                                </Button>
                                                                                                <p className="flightcancelpolicy">Cancellation available</p>
                                                                                            </Fragment>
                                                                                    }
                                                                                </Fragment>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ''
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
