import React from 'react';
import {Tab, Tabs} from "@blueprintjs/core";
import {Auth, NoLogin} from 'gg-react-utilities';
import AllBooking from "./AllBooking";
import UpcomingBooking from "./UpcomingBooking";
import CancellationBooking from "./CancellationBooking";
import Topbar from '../../../components/topBar';
import LoaderImg from '../../../images/100x100-Loader.gif';

export default class BookingHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            max_count: 10,
            searchText: '',
            selectedTabId: 'allBooking',
            isLoggedIn:false
        }
    }

    async componentDidMount() {
        this.checkAuth();
        let selectedTabId = this.props.history.location.hash.substring(1);
        if (selectedTabId === 'allBooking' || selectedTabId === 'cancellationBooking' || selectedTabId === 'upComingBooking') {
            this.setState({
                selectedTabId: selectedTabId,
                isLoggedIn:await Auth.isLoggedInAsync()
            })
        }else {
            this.setState({
                isLoggedIn:await Auth.isLoggedInAsync()
            })
        }

        this.props.getBookingHistory();
    }

    async checkAuth(){
      setTimeout(()=>{
        Auth.isLoggedInAsync().then((res) => {
          this.setState({isLoggedIn:res})
        });
      },1)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.allBookings !== null) {

        }
    }

    handleTabChange = (value) => {
        this.setState({
            selectedTabId: value
        })
    }

    viewAll = () => {
        this.setState({
            max_count: 5000000
        })
    }

    viewLess = () => {
        this.setState({
            max_count: 6
        })
    }

    render() {

        if (!this.state.isLoggedIn) {

            return(
                <React.Fragment>
                    <Topbar {...this.props} title={"Booking history"}/>
                    <div className="row cancelTripWrapper justify-content-center mb-5 mt-3">
                        <NoLogin/>
                    </div>
                </React.Fragment>
            )
        }

        if (this.props.allBookings === null) {

            return (
                <React.Fragment>
                    <div className="container" style={{minHeight: '600px'}}>
                        <Topbar {...this.props} title={"Booking history"}/>
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src={LoaderImg} className="img-fluid mt-5" alt="" />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <Topbar {...this.props} title={"Booking history"}/>
                <div className="card">
                    <div className="card-body">
                        <div className="row p-3">
                            <div className="col-xl-12 col-12">
                                <div className="row mb-2">
                                    <div className="col-xl-12 px-s-0">
                                        <div className="row justify-content-center">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-s-0">
                                                <Tabs id="TabsExample"
                                                      defaultSelectedTabId={1}
                                                      onChange={this.handleTabChange}
                                                      selectedTabId={this.state.selectedTabId}
                                                >
                                                    <Tab id={"allBooking"}
                                                         title="All Booking"
                                                         panel={
                                                             <AllBooking
                                                                 {...this.props}
                                                                 bookingHistory={this.props.allBookings}
                                                                 onLoadMore={() => {

                                                                     if (this.props.allBookings.total !== this.props.allBookings.data.length) {
                                                                         this.props.allBookingLoadMore(1, this.props.allBookings)
                                                                     }

                                                                 }}
                                                             />
                                                         }
                                                    />
                                                    <Tab id={"upComingBooking"}
                                                         title="Upcoming Booking"
                                                         panel={
                                                             <UpcomingBooking
                                                                 {...this.props}
                                                                 bookingHistory={this.props.upcomingBookings}
                                                                 onLoadMore={() => {
                                                                     if (this.props.upcomingBookings.total !== this.props.upcomingBookings.data.length) {

                                                                         this.props.upcomingBookingsLoadMore(2, this.props.upcomingBookings)
                                                                     }
                                                                 }}
                                                             />}/>
                                                    <Tab id={"cancellationBooking"}
                                                         title="Cancellation Booking"
                                                         panel={
                                                             <CancellationBooking
                                                                 {...this.props}
                                                                 bookingHistory={this.props.cancelBookings}
                                                                 onLoadMore={() => {
                                                                     if (this.props.cancelBookings.total !== this.props.cancelBookings.data.length) {

                                                                         this.props.cancelBookingsLoadMore(3, this.props.cancelBookings)
                                                                     }
                                                                 }}
                                                             />}
                                                    />
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>


        );
    }
}
