import {connect} from 'react-redux';
import * as actions from '../actions';
import {searchFlightsList, searchHint} from '../actions/action';
import TripSearchForm from '../components/TripSearchForm';
import {toaster as toast} from '../components/toast';

const mapStateToProps = state => {

    return {searchFlightData: state.searchFlight.SEARCH_FLIGHT, searchDetails: state.searchFlight.SEARCH_DETAILS}

}

const mapDispatchToProps = dispatch => {

    return {

        getAirport: (input, user_id) => {

            return searchHint(input);
        },
        filterSearchFlight: (searchFlight, user_id) => {
            dispatch(actions.searchDetails(searchFlight));
            dispatch(actions.searchFlight([]));
            searchFlightsList(searchFlight).then(res => {
                const sources = res.data;

                if (sources.status === true) {
                    dispatch(actions.searchFlight(sources.data));
                } else {
                    toast.error(sources.message);
                    dispatch(actions.searchFlight(null));
                }
            }).catch((error) => {
                dispatch(actions.searchFlight(null));
                if (error.response !== undefined) {
                    if (401 === error.response.status) {

                    } else {
                        if (422 === error.response.status) {
                            Object
                                .keys(error.response.data.errors)
                                .forEach((key) => {

                                    error
                                        .response
                                        .data
                                        .errors[key]
                                        .forEach((error) => {

                                            toast.error(error);

                                        });
                                });
                        } else {
                            toast.error(error.response.message);
                        }
                    }
                } else {
                    toast.error("Something went wrong.");
                }
            });
        },
        searchFlight: (searchFlight, user_id) => {

            searchFlightsList(searchFlight).then(res => {
                const sources = res.data;

                if (sources.status === true) {
                    dispatch(actions.searchFlight(sources.data));
                } else {
                    toast.error(sources.message);
                    // window.history.back();
                }

            }).catch((error) => {
                dispatch(actions.searchFlight(null));
                if (error.response !== undefined) {
                    if (401 === error.response.status) {

                    } else {
                        if (422 === error.response.status) {
                            Object
                                .keys(error.response.data.errors)
                                .forEach((key) => {

                                    error
                                        .response
                                        .data
                                        .errors[key]
                                        .forEach((error) => {

                                            toast.error(error);

                                        });
                                });
                        } else {
                            toast.error(error.response.message);
                        }
                    }
                } else {
                    toast.error("Something went wrong.");
                }
            });
        }
    }

}

const TripSearchContainer = connect(mapStateToProps, mapDispatchToProps)(TripSearchForm);

export default TripSearchContainer;
