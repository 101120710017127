import React, {Component} from 'react';
import {Button} from "@blueprintjs/core";
import Config from '../../../Config';

class AllBooking extends Component {
    handleScroll = () => {
        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight - allBooking.offsetHeight) - allBooking.scrollTop;

        if (parseInt(allBookingHeight) === 0) {
            this.loadMore()
        }
    }
    loadMore = () => {
        this.props.onLoadMore();
    }
    onView = (flights) => {
        let ticketFlightData = {};
        ticketFlightData.booking_id = flights.bookingId;
        ticketFlightData.traceId = flights.traceId;
        var str = Object.keys(ticketFlightData).map((key) => {
            return key + '=' + ticketFlightData[key];
        }).join('&');

        this.props.history.push({
            pathname: '/ticket-details',
            search: '?' + str
        });
    }
    onCancel = (flights) => {
        let ticketFlightData = {};
        ticketFlightData.booking_id = flights.bookingId;
        ticketFlightData.traceId = flights.traceId;
        var str = Object.keys(ticketFlightData).map((key) => {
            return key + '=' + ticketFlightData[key];
        }).join('&');
        this.props.history.push({
            pathname: '/cancel-ticket-details',
            search: '?' + str
        });
    }
    onDownload = (flights) => {

        window.open(Config.FLIGHT_API_URL + '/downloadticket/' + flights.bookingId, '_blank')

    }
    render() {
        if ((this.props.bookingHistory === null || this.props.bookingHistory === undefined) || this.props.bookingHistory.data.length === 0) {

            return (
                <div className="no-record">No booking found</div>
            );
        }
        return (
            <React.Fragment>
                <div id={"allBooking"} className={"booking-container"} onScroll={this.handleScroll}>
                    <table className="bp3-html-table table-borderless">
                        <tbody>
                        {
                            this.props.bookingHistory.data.map((flights, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        {
                                            flights.segment.map((flight, key) => {
                                                let AirlineName = flight.originFlightLogo;
                                                let FlightName = flight.originFlightName;
                                                let FlightNumber = flight.originFlightNumber;
                                                let filghtImage = 'https://s3.ap-south-1.amazonaws.com/gg-flight/logo/' + AirlineName + '.png';
                                                return (
                                                    <tr key={key}>
                                                        <td>
															#{flights.bookingId}
                                                        </td>
                                                        <td className="hidden-xs">
                                                            <div className="border-rounded">
                                                                <img
                                                                    src={filghtImage}
                                                                    alt="" className="rounded-circle" />
                                                            </div>
                                                        </td>
                                                        <td className="hidden-xs">
															{FlightName}<br/>
															<span className="oh-sub"> {FlightNumber}</span>
                                                        </td>
                                                        <td>
															{flight.origin} to {flight.destination}
															<span
																className="oh-sub"> ( {flights.trip_type === '0' ? 'Oneway' : 'Rounway'} trip)</span>
                                                        </td>
                                                        <td>
                                                            {flights.created_at}
                                                        </td>
                                                        <td>
                                                            <div className="oh-bold">
                                                                {parseFloat(flights.amount)
                                                                    .toLocaleString('en-IN', {
                                                                        maximumFractionDigits: 2,
                                                                        style: 'currency',
                                                                        currency: 'INR'
                                                                    })
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="oh-bold">
                                                                <p className="flightConfirmedText mt-2 mb-0"
                                                                   style={{color: flights.order_status_color}}>{flights.order_status_text}</p>
                                                            </div>
                                                            <div className="oh-status"></div>
                                                        </td>
                                                        <td>
                                                            <div className="row d-flex align-items-center flex-nowrap">
                                                                <a className="btn-view mr-2"
                                                                   href="#!"
                                                                   title="view ticket"
                                                                   onClick={() => {
                                                                       this.onView(flights)
                                                                   }}
                                                                ><span className="bp3-icon bp3-icon-eye-open"></span> </a>
                                                                {
                                                                    flights.order_status === '7' || flights.order_status === '10' || flights.order_status === '11' || flights.order_status === '15' || flights.order_status === '18' ?
                                                                        <a className="btn-danger mr-2"
                                                                           href="#!"
                                                                           title="Cancel Ticket" onClick={() => {
                                                                            this.onCancel(flights)
                                                                        }}><span className="bp3-icon bp3-icon-cross"></span> </a>
                                                                        :
                                                                        ''
                                                                }
                                                                {
                                                                    parseInt(flights.order_status) >= 7 && parseInt(flights.order_status) <= 20 ?
                                                                        <a className="btn-view mr-2" title="Download"
                                                                           href={Config.FLIGHT_API_URL + "/downloadticket/" + flights.bookingId}><span className="bp3-icon bp3-icon-import"></span> </a>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                )

                            })
                        }
                        </tbody>
                    </table>
                </div>
                {
                    this.props.bookingHistory.total !== this.props.bookingHistory.data.length ?
                        <Button onClick={this.loadMore}
                                className={"bp3-intent-primary hidden-xl hidden-lg visible-sm visible-xs loadmore"}>Load
                            More</Button>
                        :
                        ''
                }
            </React.Fragment>
        );
    }
}

export default AllBooking;
