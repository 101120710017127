import {connect} from 'react-redux';
import {toaster as toast} from '../../../components/toast';
import * as actions from '../../../actions';
import {searchFlightsList} from '../../../actions/action';

import TripSearch from '../component/TripSearch';

const mapStateToProps = state => {

    return {searchDetails: state.searchFlight.SEARCH_DETAILS, searchFlightData: state.searchFlight.SEARCH_FLIGHT}
}

const mapDispatchToProps = dispatch => {

    return {
        filterSearchFlight: (searchFlight, user_id) => {
            dispatch(actions.searchDetails(searchFlight));
            dispatch(actions.searchFlight([]));
            searchFlightsList(searchFlight).then(res => {
                const sources = res.data;

                if (sources.status === true) {
                    dispatch(actions.searchFlight(sources.data));
                } else {
                    toast.error(sources.message);
                    dispatch(actions.searchFlight(null));
                }
            }).catch((error) => {
                dispatch(actions.searchFlight(null));
                if (error.response !== undefined) {
					if (401 === error.response.status) {

					} else {
                        if (422 === error.response.status) {
                            Object
                                .keys(error.response.data.error)
                                .forEach((key) => {

                                    error
                                        .response
                                        .data
                                        .error[key]
                                        .forEach((error) => {

                                            toast.error(error);

                                        });
                                });
                        } else {
                            toast.error(error.response.data.message);
                        }
                    }
                } else {
                    toast.error("Something went wrong.");
                }
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TripSearch);
