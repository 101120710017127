import React,{ Component } from 'react';
import HomeContainer from './container/HomeContainer';

export default class Home extends Component{

    render(){

        return(
            <React.Fragment>

                <HomeContainer {...this.props} />
            </React.Fragment>

        )
    }
}
