import {connect} from 'react-redux';
import * as actions from '../../../actions';
import {allTaxes, reCheckPrice} from '../../../actions/action';
import {toaster as toast} from '../../../components/toast';
import FlightFare from '../component/FlightFare';
import _ from 'lodash';

const mapStateToProps = state => {

    return {
        searchFlightFare: state.searchFlight.FARE_DETAILS,
        allTaxes: state.searchFlight.ALL_TAXES
    }
}

const mapDispatchToProps = dispatch => {

    return {
        searchFlightFareDetails: (flightData) => {
          dispatch(actions.allTaxes(null));
            dispatch(actions.searchFlightFareDetails(flightData))
        },
        getAllTaxes: (amount, coin = 0, voucher_id='') => {
           return  allTaxes(amount, coin, voucher_id)
                .then(res => {
                    const sources = res.data;
                    
                    if (sources.status === true) {

                        dispatch(actions.allTaxes(sources.data));
                    } else {
                        dispatch(actions.allTaxes([]));
                    }
                    return Promise.resolve(res)
                });
        },
        reCheckPrice:(postdata,bookingData)=>{

            let response=[];
            let bookingDatails = bookingData;

            reCheckPrice(postdata)
                .then(res =>{
                    const sources = res.data;

                    if(sources.status===true){
                        response=sources.data.Journeys;
                        if(response.length>0){
                            response.map((data,key)=>{

                                if(!_.isEmpty(data.Segments)){

                                    if(key===0){
                                        postdata.onword=data.Segments[0];
                                    }else{
                                        postdata.return=data.Segments[0];
                                    }
                                }
                                return data;
                            });
                            bookingDatails.Segment=[postdata.onword,postdata.return];
                        }
                    }else{
                        bookingDatails.Segment=[postdata.onword,postdata.return];
                    }
                    let amount=0;

                    bookingDatails.Segment.map((segment)=>{
                        if(segment!==undefined && segment.Fare!==undefined){
                            amount = amount + segment.Fare.TotalFareWithOutMarkUp;
                        }
                        return segment;
                    });

                    let taxes=[];
                    allTaxes(amount,0)
                    .then(res =>{
                        const sources = res.data;
                        if(sources.status===true){
                            taxes=sources.data;
                        }
                        dispatch(actions.allTaxes(taxes))
                        dispatch(actions.searchFlightFareDetails(bookingDatails));
                    }).catch((error)=>{
                        dispatch(actions.allTaxes(taxes))
                        dispatch(actions.searchFlightFareDetails(bookingDatails));
                    });
                }).catch((error)=>{
                    if(error){
                        const sources = error.response.data;
                        toast.error(sources.message);
                    }
                });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightFare);
