import React, {Component} from 'react';
import moment from 'moment';

export default class ActivityList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recentSearch: [],
            max_count: 6
        }
    }

    componentDidMount() {
        let recentSearch = [];

        recentSearch = JSON.parse(localStorage.getItem('flightRecentSearch'));

        if (recentSearch != undefined || recentSearch != null) {
            recentSearch.sort(function (a, b) {
                return b.id - a.id
            });

            this.setState({
                recentSearch: recentSearch
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        //console.log(nextProps.activityData);
    }

    recentSearch(res) {

        var str = Object.keys(res).map(function (key) {
            return key + '=' + res[key];
        }).join('&');
        const rand = 1 + Math.random() * (100 - 1);

        str+='&tripId='+rand;


        this.props.history.push({
            pathname: '/search-flights',
            search: '?' + str
        });

    }

    viewAll = () => {
        this.setState({
            max_count: 500
        })
    }

    viewLess = () => {
        this.setState({
            max_count: 6
        })
    }

    render() {

        let count = 1;
        let classincrement = 1;

        return (
            <div className="recent-search mb-4">
                <div className="col-xl-12 col-12">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <div className="row">
                                <h3 className="bp3-text-xlarge heading mb-5 m-2 text-dark">Recent Search</h3>
                            </div>

                            {
                                this.state.recentSearch.length > 0?
                                    <React.Fragment>
                                        <div className="row ">
                                            {
                                                this.state.recentSearch.map((res, key) => {

                                                    let beginDate = moment(new Date(res.beginDate)).format('YYYY-MM-DD');
                                                    if (count <= this.state.max_count && beginDate >= moment(new Date()).format('YYYY-MM-DD')) {
                                                        count++;
                                                        let bg_class = "bg" + classincrement;

                                                        if (classincrement <= 5) {
                                                            classincrement++;
                                                        } else {
                                                            classincrement = 1;
                                                        }

                                                        return (
                                                            <div
                                                                className={"col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 recent-search-block card-bg mb-4 mt-2"}
                                                                key={key} onClick={() => this.recentSearch(res)}>
                                                                <div className={"d-flex pull-right ticket-column " + bg_class}>
                                                                    <div className="col-xl-10 col-10 p-3 p-s-3">
                                                                        <p>{res.from} - {res.to} ({res.tripType !== 1 ? 'One Way Trip' : 'Round Trip'})</p>
                                                                        <p>{moment(new Date(res.beginDate)).format('DD MMMM, Y')} {res.tripType === 1 ? '-' : ''} {res.tripType === 1 ? moment(new Date(res.returnDate)).format('DD MMMM, Y') : ''}</p>
                                                                    </div>
                                                                    <div
                                                                        className="col-xl-2 col-2 p-0 ticket-border pull-right side-border">
                                                                        <h5 className="ticket-book">Book</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                })
                                            }

                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-12 text-center">
                                                {
                                                    this.state.max_count <= 6 ?
                                                        <a className="" onClick={this.viewAll} tabIndex={0}>View All</a>
                                                        :
                                                        <a className="" onClick={this.viewLess} tabIndex={0}>Less</a>
                                                }

                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <div className="row">
                                        <div className="no-record">No recent search found</div>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
